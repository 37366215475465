import React, { useState, useEffect } from "react";
import AdminLayout from "../adminlayout";
import { AddNewBlogService, updateBlogService } from '../../../config/blog';
import { ToastContainer, toast } from "react-toastify";
// import LoaderSvg from '../../../images/loader.svg'
import { useNavigate, Link } from "react-router-dom";
import { validateField } from '../../../helpers';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProfileHeader from "../../../components/ProfileHeader";
const AddBlog = ({ edit = false, activeItem = false, setCloseModal = () => { }, onGetAllBlogs = () => { } }) => {
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState("");
    const [imageUrl, setImageUrl] = useState(null)
    const [image, setImage] = useState({});
    const [desc, setDesc] = useState(EditorState.createEmpty())
    const [descText, setDescText] = useState("")
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setImage(img)
            setImageUrl(URL.createObjectURL(img)

            );
        }
    };

    useEffect(() => {
        if (edit) {

            setTitle(activeItem.title)
            setCategory(activeItem.category)
            setImageUrl(activeItem.image)
            if (activeItem.desc) {
                let descTe = EditorState.createWithContent(
                    ContentState.createFromBlockArray(

                        convertFromHTML(activeItem.desc)
                    )
                )

                setDesc(descTe)
                const rawContentState = convertToRaw(descTe.getCurrentContent());
                const markup = draftToHtml(rawContentState)
                setDescText(markup)
            }
        }
    }, [])


    const onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState)
        setDesc(editorState)
        setDescText(markup)
    }


    const onSubmit = async (e) => {

        e.preventDefault();
        let validate = true

        if (title.trim() === "") {
            validateField("title", "Title field is required")
            validate = false
        }
        // if (category.trim() === "") {
        //     validateField("category", "Category field is required")
        //     validate = false
        // }

        if (descText.trim() === "") {
            validateField("desc", "Description field is required")
            validate = false
        }

        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            let fd = new FormData()
            let data = {
                title,
                desc: descText,
                image,
                category
            }

            for (let key in data) {
                fd.append(key, data[key])
            }
            const res = await AddNewBlogService(fd)

            if (res.data.status) {

                setLoader(false)
                setTitle("")
                setDesc("")
                setCategory("")
                toast.success("Blog Added Successfully")
                setLoader(false)
                navigate("/blogs")

            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }
    const onUpdate = async (e) => {

        e.preventDefault();
        let validate = true

        if (title.trim() === "") {
            validateField("title", "Title field is required")
            validate = false
        }


        if (!validate) {
            toast("Please fill required fields")
            return
        }

        try {
            setLoader(true)
            let fd = new FormData()
            let data = {
                title,
                category,
                desc: descText,
                _id: activeItem._id
            }
            if (image) {
                data = {
                    ...data, image: image
                }
            }

            for (let key in data) {
                fd.append(key, data[key])
            }

            const res = await updateBlogService(fd)
            if (res.data.status) {

                setLoader(false)
                toast("Blog Updated Successfully")
                setCloseModal(false)
                onGetAllBlogs()

            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)

        }
    }


    const render = () => {
        return (
            <>
                {/* <ProfileHeader /> */}
                {/* <NavBar className="fixed" /> */}
                <ToastContainer />
                <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">


                                <form class="forms-sample" onSubmit={(e) => { edit ? onUpdate(e) : onSubmit(e) }}>
                                    <div class="form-group">
                                        {/* <label for="exampleInputUsername1">Category</label> */}
                                        <select class="form-select" onChange={(e) => { setCategory(e.target.value); }} name="category" value={category}>
                                            <option selected>Select Category</option>
                                            <option value="Real Estate">Real Estate</option>
                                            <option value="Home Improvements">Home Improvements</option>
                                            <option value="Architecture">Architecture</option>
                                            <option value="New Home Ideas">New Home Ideas</option>
                                            {/* <option value="Laundry Detergent How To's"></option> */}
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        {/* <label for="exampleInputUsername1">Title</label> */}
                                        <input style={{ height: '40px', marginTop: '15px' }} type="text" class="form-control" id="exampleInputUsername1" placeholder="Title" value={title} name="title" onChange={(e) => { setTitle(e.target.value); }} />
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputUsername1">Upload Image</label>
                                        <div class="image-input">
                                            <input type="file" accept="image/*" id="imageInput" onChange={onImageChange} />
                                            <label for="imageInput" class="image-button"><i class="mdi mdi-image"></i> <span>Choose image</span></label>
                                            {imageUrl &&
                                                <img src={imageUrl} class="image-preview" />
                                            }
                                            <span class="change-image">Choose different image</span>
                                        </div>

                                    </div>

                                    <div class="form-group">
                                        <label>Description</label>
                                        <Editor
                                            wrapperClassName="wrapper-class wrapperClassName"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            editorState={desc}
                                            onEditorStateChange={onEditorStateChange}

                                        />
                                    </div>

                                    <button type="submit" class="btn btn-primary btn-dashboard-add me-2">
                                        Submit
                                        {/* {loader ? <img src={LoaderSvg} style={{ padding: "0 7px" }} /> : "Submit"} */}
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </>

        )
    }
    return !edit ? (
        <div sideBtn={<Link to="/blogs-list/admin" class="add btn btn-primary todo-list-add-btn" > Go Back</Link>}>

            {render()}
        </div >
    ) :
        render()

}

export default AddBlog;