import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_USER } from "../redux/actions/types";
import UserPng from "../assets/img/icon/user.png";

const NavBar = ({ checkLoggedInUser, location }) => {
  let token = localStorage.getItem("token");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onLogOut = () => {
    localStorage.removeItem("token");
    token = null;
    dispatch({
      type: SET_CURRENT_USER,
      payload: null,
    });
    checkLoggedInUser();
  };

  return (
    <div className={`${location && location == "home" ? "home-navbar" : ""}`}>
      <nav
        class="navbar navbar-default header-transparent"
        id="navbar-home-text-color"
      >
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navigation"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="index.html">
              <img src="assets/img/dsdsds33-Copy.png" alt="" />
            </a>
          </div>

          <div class="button navbar-right">
            {token && token != null ? (
              <>
                {/* <Link onClick={onLogOut} to="/" style={{ paddingLeft: 20, color: '#fff' }}>
                                    <button class="navbar-btn nav-button wow bounceInRight login" data-wow-delay="0.4s">Logout</button></Link> */}

                <ul class="nav navbar-nav">
                  <li>
                    <Link className="post-property" to="/add-post">
                      Post Property
                    </Link>
                  </li>
                  <li class="dropdown">
                    <a
                      href="#"
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src={UserPng} /> <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        {" "}
                        <Link
                          onClick={onLogOut}
                          to="/"
                          style={{ paddingLeft: 20, color: "red" }}
                        >
                          Logout
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={user?.role == "admin" ? "/admin" : "/profile"}
                        >
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <Link to="/login">
                  <button
                    class="navbar-btn nav-button wow bounceInRight login"
                    data-wow-delay="0.4s"
                  >
                    Login
                  </button>
                </Link>
              </>
            )}
          </div>

          <ul class="main-nav nav navbar-nav navbar-right">
            <li class="ymm-sw " data-wow-delay="0.1s">
              <Link to="/" class="wow fadeInDown" data-delay="200">
                Home
              </Link>
            </li>

            <li class="wow fadeInDown" data-wow-delay="0.1s">
              <Link class="" to="/properties-list">
                Explore
              </Link>
            </li>
            {/* <li class="wow fadeInDown" data-wow-delay="0.1s"><a class="" href="property.html">Property</a></li> */}
            <li class="wow fadeInDown" data-wow-delay="0.1s">
              <Link to="/about" class="wow fadeInDown">
                About Us
              </Link>
            </li>

            <li class="wow fadeInDown" data-wow-delay="0.4s">
              <Link to="/contact">Contact</Link>
            </li>
            <li class="wow fadeInDown" data-wow-delay="0.1s">
              <Link to="/faq" class="wow fadeInDown">
                Faq
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
