import axios from "axios";
import { RefreshTokenService } from "./user";

export const API_KEY = "AIzaSyCrQ-UOtWmJBbJK7xFBF5K6XB_JB5jGOS4";

const development = false;
// !process.env.NODE_ENV || process.env.NODE_ENV === "development";
let baseURL = development
  ? "http://localhost:8082/api"
  : "https://www.near2property.com/api";

const publicReq = axios.create({
  baseURL,
});

const privateReq = axios.create({
  baseURL,
});

const imageUpload = axios.create({
  baseURL,
});

imageUpload.defaults.headers.common["Content-Type"] = "multipart/form-data";

// withcsrf.defaults.headers.common['X-Csrf-Token'] = 'sSBaqKXESjTV6GNLCBCgGn57rlS21WFj4iw_7qCMSu0';

imageUpload.interceptors.request.use(async (config) => {
  const tokenValue = localStorage.getItem("token");

  //   let token = JSON.parse(tokenValue);
  const error = {};
  // if (!token) {
  // 	Keychain.resetGenericPassword();
  // 	AsyncStorage.removeItem('userDetails');

  // 	error.message = 'Invalid Login, Please Login again.';
  // 	return Promise.reject(error);
  // }

  config.headers.Authorization = `Bearer ${tokenValue}`;

  return config;
});
privateReq.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  // if (!token) {
  // 	Keychain.resetGenericPassword();
  // 	AsyncStorage.removeItem('userDetails');

  // 	error.message = 'Invalid Login, Please Login again.';
  // 	return Promise.reject(error);
  // }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const refreshAccessToken = async () => {
  let refresh_token = localStorage.getItem("refresh_token");
  let account_id = localStorage.getItem("account_id");

  if (refresh_token) {
    let queryParams = `?rtoken=${refresh_token}&account_id=${account_id}`;
    try {
      const res = await RefreshTokenService(queryParams);
      return res.data;
    } catch (e) {
      console.log(e);
    }
  }
};
privateReq.interceptors.response.use(
  async (response) => {
    const originalRequest = response.config;
    if (
      response.data.response_code == 1001 &&
      (response.data.errors[0].code === "CT.0.0.1.10" ||
        response.data.errors[0].code === "CT.0.0.1.3") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const token = await refreshAccessToken();

      if (token.token != undefined) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + token.token;
        localStorage.setItem("token", token.token);
        localStorage.setItem("refresh_token", token.refresh_token);
        localStorage.setItem("account_id", token.id);
        return privateReq(originalRequest);
      }
    }
    return response;
  },
  async function (error) {
    // const originalRequest = error.config;
    // if (error.response.status === 403 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const access_token = await refreshAccessToken();
    //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //   return axiosApiInstance(originalRequest);
    // }
    // return Promise.reject(error);
  }
);
export { imageUpload, privateReq };
export default publicReq;
