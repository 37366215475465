import React from "react";
import Footer from "../../new-components/global-components/footer";
// import logo3 from '../../assets/logo3.PNG'
import Header from "../../new-components/global-components/page-header";
import NavBar from "../../new-components/global-components/navbar";

class TermOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleInput = this.handleInput.bind(this);
    this.navigation = this.navigation.bind(this);
    // this.onSubmit = this.onSubmit.bind(this)
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // logout = (e) => {

  //     logOutUser()

  //     this.props.history.push('/admin')

  // }

  navigation = (e, route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <div className="container-fluid bg-light">
        <div>
          {/* <Header /> */}
          <NavBar />
        </div>
        <div className="d-flex justify-content-center">
          {/* <img src={logo3} height="400" width="400" /> */}
        </div>
        <div class="row">
          <div className="container">
            <div className="d-flex justify-content-center">
              <h2>Near2Property</h2>
            </div>
            <br></br>
            <div className="d-flex justify-content-center"></div>
            <br></br>

            <div className="d-flex justify-content-center">
              <h2>Term Of Use</h2>
            </div>

            <div className="col-9"></div>
            <br></br>

            <div>
              <br></br>
              <br></br>
              <p>
                PLEASE CAREFULLY READ THESE TERMS OF USE. BY USING THIS PLATFORM
                YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS.
                IF YOU DO NOT AGREE TO THESE TERMS YOU MAY NOT USE THE PLATFORM.
                <br></br>
                <br></br>
              </p>
              <p>
                i. ACCEPTANCE<br></br>
                <br></br>
              </p>
              <p>
                {/* is a Paras Infotech company that is registered at the
                        Register with the Registrar of Firms in the India under number(Applied) and
                        located at SCO:32,Mega Market,Sunny Enclave,Sec-125,Mohali.  */}
                Near2Property App India is a product of Vritika Homes, Mohali and its affiliates and/or branches (hereinafter together "Near2Property App") provide a collection
                of online resources which include classified advertisements
                (collectively, the "Service") on the website Near2Property App
                and corresponding and the mobile application of the platform
                (collectively, the "Platform").<br></br>
                <br></br>
              </p>
              <p>
                The Platform and the Service are provided to you subject to
                these Near2Property App Terms of Use (these " Terms"). For the
                purpose of the Terms and wherever the conp so requires, the
                terms "you" and "your" shall mean any person who uses or
                accesses, whether through manual or automated means, the
                Platform or the Service in any manner whatsoever including
                persons browsing the Platform and its content, posting comments
                or any content or responding to any advertisements or content on
                the Platform. By using the Service, you agree to comply with
                these Terms. Additionally, when using a portion of the Service,
                you agree to conform to any applicable posted guidelines for
                such Service, which may change or be updated from time to time
                at Near2Property App’s sole discretion. You understand and agree
                that you are solely responsible for reviewing these Terms from
                time to time. Should you object to any term or condition of
                these Terms, any guideline, or any subsequent changes thereto or
                become unhappy with Near2Property App or the Service in any way,
                your only choice is to immediately discontinue use of
                Near2Property App. These Terms may be updated by Near2Property
                App at any time at its sole discretion. Near2Property App may
                send you notices of changes to the Platform herein.
                Near2Property App may provide a translation of the English
                version of the Terms into other languages. You understand and
                agree that any translation of the Terms into other languages is
                for your convenience only and that the English version governs
                the terms of your relationship with Near2Property App. We may
                also suspend the operation of Near2Property App for support or
                technical up gradation, maintenance work, in order to update the
                content or for any other reason.Furthermore, if there are any
                inconsistencies between the English version of the Terms and any
                translation, the English version of the Terms shall govern.
                <br></br>
                <br></br>
              </p>

              <p>
                ii. DESCRIPTION OF SERVICE AND CONTENT POLICY<br></br>
                <br></br>
              </p>

              <p>
                A.Near2Property App is the next generation of online
                classifieds. We act as a venue to allow our users who comply
                with these Terms to offer, sell, and buy products and services
                listed on the Platform. Although you may be able to conduct
                payment and other transactions through the Platform, using
                third-party vendors such Near2Property App is not in any way in
                involved in such transactions. As a result, and as discussed in
                more detail in these Terms, you hereby acknowledge and agree
                that Near2Property App is not a party to such transactions, has
                no control over any element of such transactions, and shall have
                no liability to any party in connection with such transactions.
                You use the Service and the Platform at your own risk.<br></br>
                <br></br>
              </p>

              <p>
                B. You understand that Near2Property App does not control, and
                is not responsible for ads, directory information, business
                listings/information, messages between users, including without
                limitation e-mails sent from outside Near2Property App’s domain
                or other means of electronic communication, whether through the
                Platform or another Third Party Website (defined below) or
                offerings, comments, user postings, files, images, photos,
                video, sounds, business listings/information and directory
                information or any other material made available through the
                Platform and the Service ("Content"), and that by using the
                Platform and the Service, you may be exposed to Content that is
                offensive, indecent, inaccurate, misleading, or otherwise
                objectionable. You acknowledge and agree that you are
                responsible for and must evaluate, and bear all risks associated
                with, the use of any Content, that you may not rely on said
                Content, and that under no circumstances will Near2Property App
                be liable in any way for the Content or for any loss or damage
                of any kind incurred as a result of the browsing, using or
                reading any Content listed, emailed or otherwise made available
                via the Service. You acknowledge and agree that Near2Property
                App cannot and does not pre-screen or approve any Content, but
                that Near2Property App has the right, in its sole and absolute
                discretion, to refuse, delete or move any Content that is or may
                be available through the Service, for violating these Terms and
                such violation being brought to Near2Property App’s knowledge or
                for any other reason or no reason at all. Furthermore, the
                Platform and Content available through the Platform may contain
                links to other third party websites ("Third Party Websites"),
                which are completely unrelated to Near2Property App. If you link
                to Third Party Websites, you may be subject to those Third Party
                Websites’ terms and conditions and other policies. Near2Property
                App makes no representation or guarantee as to the accuracy or
                authenticity of the information contained in any such Third
                Party Website, and your linking to any other websites is
                completely at your own risk and Near2Property App disclaims all
                liability thereto.<br></br>
                <br></br>
              </p>
              <p>
                C. You acknowledge and agree that you are solely responsible for
                your own Content posted on, transmitted through, or linked from
                the Service and the consequences of posting, transmitting,
                linking or publishing it. More specifically, you are solely
                responsible for all Content that you upload, email or otherwise
                make available via the Service. In connection with such Content
                posted on, transmitted through, or linked from the Service by
                you, you affirm, acknowledge, represent, warrant and covenant
                that: <br></br>
                <br></br>(i) you own or have and shall continue to, for such
                time the Content is available on the Platform, have the
                necessary licenses, rights, consents, and permissions to use
                such Content on the Service and Platform (including without
                limitation all patent, trademark, trade secret, copyright or
                other proprietary rights in and to any and all such Content) and
                authorize Near2Property App to use such Content to enable
                inclusion and use of the Content in the manner contemplated by
                the Service, the Platform and these Terms and <br></br>
                <br></br>(ii) you have the written consent, release, and/or
                permission of each and every identifiable individual person or
                business in the Content to use the name or likeness of each and
                every such identifiable individual person or business to enable
                inclusion and use of the Content in the manner contemplated by
                the Service, the Platform and these Terms. For clarity, you
                retain all of your ownership rights in your Content; however, by
                submitting any Content on the Platform, you hereby grant to
                Near2Property App an irrevocable, non-cancellable, perpetual,
                worldwide, non-exclusive, royalty-free, sub-licensable,
                transferable license to use, reproduce, distribute, prepare
                derivative works of, display, and perform the Content in
                connection with the Platform and Near2Property App 's (and its
                successors') business, including without limitation for the
                purpose of promoting and redistributing part or all of the
                Platform and Content therein (and derivative works thereof) in
                any media formats and through any media channels now or
                hereafter known. These rights are required by Near2Property App
                in order to host and display your Content. Furthermore, by you
                posting Content to any public area of the Service, you agree to
                and do hereby grant to Near2Property App all rights necessary to
                prohibit or allow any subsequent aggregation, display, copying,
                duplication, reproduction, or exploitation of the Content on the
                Service or Platform by any party for any purpose. As a part of
                the Service, Near2Property App may offer the facility of
                automatically capturing of the "Description" of your ad based on
                the images uploaded by you. Near2Property App makes no warranty
                the veracity or the accuracy of the generated Description. The
                Description may be edited by you at any time while your ad is
                live. You also hereby grant each user of the Platform<br></br>
                <br></br>
                (a) a non-exclusive license to access your Content through the
                Platform, and<br></br>
                <br></br>
                (b) the right to contact you with regard to the Content posted
                by you through private chat or any other means. The foregoing
                license to each user granted by you terminates once you or
                Near2Property App remove or delete such Content from the
                Platform. Further, you grant Near2Property App the right to make
                available your Content to any third party in connection with the
                transaction contemplated in your classified advertisement.
                <br></br>
                <br></br>
              </p>
              <p>
                D.Near2Property App does not endorse any Content or any opinion,
                statement, recommendation, or advice expressed therein, and
                Near2Property App expressly disclaims any and all liability in
                connection with User Content. Near2Property App does not permit
                copyright infringing activities and infringement of intellectual
                property rights on the Platform, and Near2Property App may, at
                its sole discretion, remove any infringing Content if properly
                notified in accordance with applicable law that such Content
                infringes on another's intellectual property rights.
                Near2Property App reserves the right to remove any Content
                without prior notice. Near2Property App may also terminate a
                user's access to the Platform if they are determined to be a
                repeat infringer or found to be indulging in any act contrary to
                these Terms. A repeat infringer is a user who has been notified
                of infringing activity more than twice and/or has had a user
                submission removed from the Platform more than twice. Further,
                at its sole discretion, Near2Property App reserves the right to
                decide whether any Content is appropriate and complies with
                these Terms.<br></br>
                <br></br>
              </p>
              <p>
                iii. CONDUCT<br></br>
                <br></br>
              </p>
              <p>
                You agree not to post, email, host, display, upload, modify,
                publish, transmit, update or share any information on the Site,
                or otherwise make available Content:<br></br>
                <br></br>
              </p>
              <p>
                i.that violates any law or regulation<br></br>
                <br></br>
              </p>
              <p>
                ii. that is copyrighted or patented, protected by trade secret
                or trademark, or otherwise subject to third party proprietary
                rights, including privacy and publicity rights, unless you are
                the owner of such rights or have permission or a license from
                their rightful owner to post the material and to grant
                Near2Property App all of the license rights granted herein
                <br></br>
                <br></br>
              </p>
              <p>
                iii. that infringes any of the foregoing intellectual property
                rights of any party, or is Content that you do not have a right
                to make available under any law, regulation, contractual or
                fiduciary relationship(s)<br></br>
                <br></br>
              </p>
              <p>
                iv. that is harmful, abusive, unlawful, threatening, harassing,
                blasphemous, defamatory, obscene, pornographic, paedophilic,
                libellous, invasive of another's privacy or other rights,
                hateful, or racially, ethnically objectionable, disparaging,
                relating or encouraging money laundering or illegal gambling or
                harms or could harm minors in any way or otherwise unlawful in
                any manner whatsoever<br></br>
                <br></br>
              </p>
              <p>
                v. that harasses, degrades, intimidates or is hateful towards
                any individual or group of individuals on the basis of religion,
                gender, sexual orientation, race, ethnicity, age, or disability;
                <br></br>
                <br></br>
              </p>
              <p>
                vi. that violates any (local) equal employment laws, including
                but not limited to those prohibiting the stating, in any
                advertisement for employment, a preference or requirement based
                on race, color, religion, sex, national origin, age, or
                disability of the applicant.<br></br>
                <br></br>
              </p>
              <p>
                vii. that threatens the unity, integrity, defense, security or
                sovereignty of India, friendly relations with foreign states, or
                public order or causes incitement to the commission of any
                cognizable offense or prevents investigation of any offense or
                is insulting any other nation<br></br>
                <br></br>
              </p>
              <p>
                viii. that includes personal or identifying information about
                another person without that person's explicit consent;<br></br>
                <br></br>
              </p>
              <p>
                ix. that impersonates any person or entity, including, but not
                limited to, an Near2Property App employee, or falsely states or
                otherwise misrepresents an affiliation with a person or entity
                <br></br>
                <br></br>
              </p>
              <p>
                x. deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature<br></br>
                <br></br>
              </p>
              <p>
                xi. that is false, deceptive, misleading, deceitful,
                misinformative, or constitutes "bait and switch" offer<br></br>
                <br></br>
              </p>
              <p>
                xii. that constitutes or contains pyramid schemes, affiliate
                marketing, link referral code,junk mail, spam,chain letters," or
                unsolicited advertisements of a commercial nature<br></br>
                <br></br>
              </p>
              <p>
                xiii. that constitutes or contains any form of advertising or
                solicitation if (1) posted in areas or categories of the
                Platform which are not designated for such purposes; or (2)
                e-mailed to Near2Property App users who have requested not to be
                contacted about other services, products or commercial interests
                <br></br>
                <br></br>
              </p>
              <p>
                xiv. that includes links to commercial services or Third Party
                Websites, except as specifically allowed by Near2Property App
                <br></br>
                <br></br>
              </p>
              <p>
                xv. that advertises any illegal services or the sale of any
                items the sale of which is prohibited or restricted by
                applicable law, including without limitation items the sale of
                which is prohibited or regulated by applicable law<br></br>
                <br></br>
              </p>
              <p>
                xvi. that contains software viruses or any other computer code,
                files or programs designed to interrupt, destroy or limit the
                functionality of any computer software or hardware or
                telecommunications equipment or any other computer resource
                <br></br>
                <br></br>
              </p>
              <p>
                xvii. that disrupts the normal flow of dialogue with an
                excessive number of messages (flooding attack) to the Service,
                or that otherwise negatively affects other users' ability to use
                the Service; or<br></br>
                <br></br>
              </p>
              <p>
                xviii.that employs misleading email addresses, or forged headers
                or otherwise manipulated identifiers in order to disguise the
                origin of Content transmitted through the Service.<br></br>
                <br></br>
              </p>
              <p>
                Additionally, you agree not to:<br></br>
                <br></br>
              </p>
              <p>
                xix.contact anyone who has asked not to be contacted, or makes
                unsolicited contact with anyone for any commercial purpose,
                specifically, contact any user to post an advertisement on a
                third party website or post any advertisement on behalf of such
                user; or to "stalk" or otherwise harass anyone<br></br>
                <br></br>
              </p>
              <p>
                xx. make any libelous or defamatory comments or postings to or
                against anyone<br></br>
                <br></br>
              </p>
              <p>
                xxi. collect personal data about other users or entities for
                commercial or unlawful purposes<br></br>
                <br></br>
              </p>
              <p>
                xxii. use automated means, including spiders, robots, crawlers,
                data mining tools, or the like to download or scrape data from
                the Service, except for internet search engines (e.g, Google)
                and non-commercial public archives (e.g. archive.org) that
                comply with our robots.txt file<br></br>
                <br></br>
              </p>
              <p>
                xxiii. post Content that is outside the local area or not
                relevant to the local area, repeatedly post the same or similar
                Content, or otherwise impose unreasonable or disproportionately
                large loads on our servers and other infrastructure<br></br>
                <br></br>
              </p>
              <p>
                xxiv. post the same item or service in multiple classified
                categories or forums, or in multiple metropolitan areas<br></br>
                <br></br>
              </p>
              <p>
                xxv. attempt to gain unauthorized access to computer systems
                owned or controlled by Near2Property App or engage in any
                activity that disrupts, diminishes the quality of, interferes
                with the performance of, or impairs the functionality of, the
                Service or the Platform.<br></br>
                <br></br>
              </p>
              <p>
                xxvi. use any form of automated device or computer program
                (sometimes referred to as "flagging tools") that enables the use
                of Near2Property App’s "flagging system" or other community
                control systems without each flag being manually entered by a
                human that initiates the flag (an "automated flagging device"),
                or use any such flagging tool to remove posts of competitors,
                other third parties or to remove posts without a reasonable good
                faith belief that the post being flagged violates these Terms or
                any applicable law or regulation.<br></br>
                <br></br>
              </p>
              <p>
                xxvii. use any automated device or software that enables the
                submission of automatic postings on Near2Property App without
                human intervention or authorship (an "automated posting
                device"), including without limitation, the use of any such
                automated posting device in connection with bulk postings, or
                for automatic submission of postings at certain times or
                intervals; or<br></br>
                <br></br>
              </p>
              <p>
                xxviii. Any Content uploaded by you shall be subject to relevant
                laws and may disabled, or and may be subject to investigation
                under appropriate laws. Furthermore, if you are found to be in
                non-compliance with the laws and regulations, these terms, or
                the privacy policy of the Site, we may terminate your
                account/block your access to the Site and we reserve the right
                to remove any non-compliant Content uploaded by you.<br></br>
                <br></br>
              </p>
              <p>
                iv. PAID SERVICES<br></br>
                <br></br>
              </p>
              <p>
                We may charge a fee to post content in or utilize some areas of
                the Service (“PaidServices”). has authorised Near2Property
                Classified Ads App, an Indian Individual App to sell such Paid
                Services to its users. By posting Paid Services on the Website
                you enter into an agreement with Near2Property App India, the
                terms of which are set out herein. Provisions of the Terms
                relating to the Content shall also be applicable to Paid
                Services. Further, you continue to be bound by the Terms in
                connection with your use of the Website and the Services.
                Currently, we offer the following kinds of features for Paid
                Services. Only some of these features may be made available to
                you by us, depending on your usage, category, geographical
                location and medium of purchase. The terms of Paid Services and
                the relationship between you and Near2Property App India shall
                be governed by the laws of India. Any claim you may have against
                Near2Property App India must be submitted to the exclusive
                jurisdiction the courts of Mohali. We reserve the right to
                modify the price at which these features are made available to
                you:<br></br>
                <br></br>
              </p>
              <p>
                Featured Ads<br></br>
                <br></br>
              </p>
              <p>
                Featured Ads is a feature that enables the display of your
                advertisement in a designated area of the Website and/or in a
                highlighted manner (depending on the platform the content is
                being viewed), so as to distinguish it from other content posted
                on the Website. In the designated area of the Website pertaining
                to Featured Ads, Features Ads are listed in a sequential manner.
                Therefore, it is possible that a particular Featured Ad is not
                visible to you each time you browse through the Website.
                <br></br>
                <br></br>
              </p>
              <p>
                This feature has been provided to enable users to draw attention
                to their advertisements and increase the likelihood of receiving
                responses from potential buyers. We do not guarantee an increase
                of a specified quantum in the responses received by you and any
                representation on the Website in this regard is merely
                indicative.<br></br>
                <br></br>
              </p>
              <p>
                The duration for which your Ad will be displayed as a Featured
                Ad shall be the number of days specified at the time of
                purchasing the feature or 30 days from the original date of
                posting the Content on the Website, whichever is earlier. There
                is no limit on the volume of Featured Ads you may post on the
                Website. You can purchase a single Featured ad or can avail bulk
                packages (“Featured ads package”) based on your requirement and
                such packages may be provided to you on a discounted price as
                mentioned on the Website. Near2Property App reserves the right
                to limit the extent and manner in which Featured Ads may be
                edited by you
                <br></br>
                <br></br>
              </p>
              <p>
                Boost to Top<br></br>
                <br></br>
              </p>
              <p>
                Boost to Top is a feature that bumps your advertisement to the
                top of the listing space in the city-specific category in which
                the advertisement has been posted. Your ad will remain in that
                spot until new advertisements are posted in that category, at
                which point it will move down accordingly in the listing space.
                <br></br>
                <br></br>
              </p>
              <p>
                This feature has been provided to enable users to draw attention
                to their live advertisements and increase the likelihood of
                receiving responses from potential buyers. We do not guarantee
                an increase of a specified quantum in the responses received by
                you and any representation on the Website in this regard is
                merely indicative.<br></br>
                <br></br>
              </p>
              <p>
                Boost to Top can be availed for an already posted ad, however,
                it does not increase the validity of an ad which currently in 30
                days from the posting date.<br></br>
                <br></br>
              </p>
              <p>
                Auto Boost<br></br>
                <br></br>
              </p>
              <p>
                Auto Boost is a feature similar to our “Boost to Top” feature
                (refer above) that bumps your advertisement to the top of the
                listing space in the city-specific category in which the
                advertisement has been posted, just that Auto Boost does this
                automatically at defined intervals. Near2Property App India
                shall have the sole discretion to determine the frequency of
                such intervals for bumping of the advertisement that may differ
                across categories, usage, and geographical location.<br></br>
                <br></br>
              </p>
              <p>
                This feature has been provided to enable users to draw attention
                to their live advertisements and increase the likelihood of
                receiving responses from potential buyers – all on a continuing
                basis with the convenience of our systems boosting your
                advertisement on top, during its validity, at a defined
                periodicity. We do not guarantee an increase of a specified
                quantum in the responses received by you and any representation
                on the Website in this regard is merely indicative.<br></br>
                <br></br>
              </p>
              <p>
                Auto Boost can be availed for an already posted ad, however, it
                does not increase the validity of an ad which currently is 30
                days from the posting date.<br></br>
                <br></br>
              </p>
              <p>
                Paid Ads<br></br>
                <br></br>
              </p>
              <p>
                Paid ad is a feature, which enables you to post ads even after
                you have exhausted your free limits in a category. Free limit
                defines the number of advertisements that you can post for free
                in each category within a defined periodicity and this limit may
                differ across categories, usage, and geographical location. For
                instance, if you have posted 3 advertisements in a category in
                the last 30 days and the free limit is 3 advertisements per
                month for such category, you will not be allowed to post a free
                advertisement after you have posted your third advertisement.
                <br></br>
                <br></br>
              </p>
              <p>
                All advertisements posted by you through your account will be
                counted towards free limits. Any subsequent change of phone
                number after posting an ad, will not affect the available
                limits.<br></br>
                <br></br>
              </p>
              {/* <View> */}
              <p>
                Validity of Paid ads:<br></br>
                <br></br>
              </p>
              <p>
                Limited Paid ads package – In Limited Paid ads package, you can
                post a limited number of ads during the validity of the
                package.Paid ads in this package shall each be valid for as per
                mentioned in package from the date of posting regardless of when
                it is posted by you during the life of the Limited Paid ads
                package. Unlimited Paid ads package – In Unlimited Paid ads
                package, you can post unlimited ads during the validity of the
                package. Regardless of when you post an advertisement during the
                life of the package, the advertisement will only remain valid
                during the validity of the package.<br></br>
                <br></br>
              </p>
              <p>
                Payment for Paid Services<br></br>
                <br></br>
              </p>
              <p>
                Upon selecting any feature of Paid Services, you may be
                redirected to a third-party payment processing site such as PayU
                etc. offering payment through various channels such as net
                banking, debit card and credit card (“Payment Gateway”).
                Near2Property App may offer you a right to pay through cash or
                other channels such as cash collection. You agree that Paid
                Services will be made available to you only upon receipt of
                payment confirmation from the Payment Gateway or collection
                agencies. Upon making payment to us, through any medium
                whatsoever, you warrant to us that you are entitled to use the
                medium or otherwise make the payment to us under applicable law.
                <br></br>
                <br></br>
              </p>
              <p>
                Unless otherwise mentioned on the Website, prices indicated are
                inclusive of applicable taxes.<br></br>
                <br></br>
              </p>
              <p>
                Refunds/Cancellation<br></br>
                <br></br>
              </p>
              <p>
                If you choose to delete or deactivate any Paid Services prior to
                the period for which it is active, we will not be liable to
                refund you for any unutilized time. In the event you are not
                able to avail the Paid Services on the Website on account of any
                technical reasons, on receipt of payment confirmation,
                equivalent units of the paid feature against the ad units you
                were not able to post shall be credited to your account. For
                example, if you buy a package of 3 Featured Ad units out of
                which you have consumed only 2 units and were unable to post the
                remaining 1 ad unit as a Featured Ad on account of a technical
                glitch during the lifetime of the package validity, you will be
                credited only 1 unit of Featured Ad to your account.{"/n"}
              </p>
              <p>
                The above refund policy shall not be applicable in the event any
                content in your advertisement is found to be violating the Terms
                or there has been any abuse reported in relation to your
                advertisement or your advertisement is deleted or not re-posted
                on the Website on account of any technical reasons due to any
                subsequent editing done by you.<br></br>
                <br></br>
              </p>
              <p>
                v. POSTING AGENTS<br></br>
                <br></br>
              </p>
              <p>
                As used herein, the term "Posting Agent" refers to a third-party
                agent, service or intermediary that offers to post Content to
                the Service on behalf of others. Near2Property App prohibits the
                use of Posting Agents, directly or indirectly, without the
                express writtenpermission of Near2Property App. In addition,
                Posting Agents are not permitted to post Content on behalf of
                others, directly or indirectly, or otherwise access the Service
                in order to post Content on behalf of others, except with
                express written permission or license from Near2Property App.
                <br></br>
                <br></br>
              </p>
              <p>
                vi. ACCESS TO THE SERVICE<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App grants you a limited, revocable, non-exclusive
                license to access and use the Service for personal use. This
                license granted herein does not include any of the following:
                (a) access to or use of the Service by Posting Agents; or (b)
                any collection, aggregation, copying, duplication, display or
                derivative use of the Service nor any use of data mining,
                robots, spiders, or similar data gathering and extraction tools
                for any purpose unless expressly permitted by Near2Property App
                or as otherwise set forth in these Terms. Notwithstanding the
                foregoing, general purpose internet search engines and
                non-commercial public archives that gather information for the
                sole purpose of displaying hyperlinks to the Service, provided
                they each do so from a stable IP address or range of IP
                addresses using an easily identifiable agent and comply with our
                robots.txt file, may engage in the activities set forth in (b).
                For purposes of this exception, a "general purpose internet
                search engine" does not include a website or search engine or
                other services that specializes in classified listings including
                any subset of classifieds listings such as housing, for sale,
                jobs, services, or personals, or which otherwise provides
                classified ad listing services. The license set forth in this
                Section permits you to display on your website, or create a
                hyperlink thereto, individual postings on the Service so long as
                such use is for non-commercial and/or news reporting purposes
                only (e.g., for use in personal blogs or other personal online
                media). If the total number of such postings displayed on or
                linked to your website exceeds limits set by Near2Property App,
                your use will be considered to be in violation of these Terms,
                unless Near2Property App expressly grants you permission
                otherwise. You are also permitted to create a hyperlink to the
                home page of the Platform so long as the link does not portray
                Near2Property App, its employees, affiliates or agents in a
                false, confusing, misleading, derogatory, or otherwise offensive
                matter. Use of the Service beyond the scope of authorized access
                as set forth in these Terms immediately terminates any
                permission or license granted herein. In order to collect,
                aggregate, copy, duplicate, display or make derivative use of
                the Service or any Content made available via the Service for
                other purposes (including commercial purposes) not stated
                herein, you must first obtain a license from Near2Property App.
                <br></br>
                <br></br>
              </p>
              <p>
                vii. NOTIFICATION OF CLAIMS OF INFRINGEMENTS<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App is not liable for any infringement of
                intellectual property rights arising out of materials posted on
                or transmitted through the site, or items advertised on the
                site, by end users or any other third parties.<br></br>
                <br></br>
              </p>
              <p>
                If you are an owner of intellectual property rights or an agent
                who is fully authorized to act on behalf of the owner of
                intellectual property rights and believe that any Content or
                other content infringes upon your intellectual property right or
                intellectual property right of the owner on whose behalf you are
                authorized to act, you may submit a notification to
                Near2Property App together with a request to Near2Property App
                to delete the relevant Content in good faith in the claims of
                infringement form AVAILABLEhere.<br></br>
                <br></br>
              </p>
              <p>
                viii. INTELLECTUAL PROPERTY RIGHTS<br></br>
                <br></br>
              </p>
              <p>
                You acknowledge and agree that the materials on the Platform,
                other than the User Content that you licensed under Section
                ii(C) of the Terms, including without limitation, the p,
                software, scripts, graphics, photos, sounds, music, videos,
                interactive features and the like ("Materials") and the
                trademarks, service marks and logos contained therein ("Marks"),
                are owned by or licensed to Near2Property App, and are subject
                to copyright and other intellectual property rights under Indian
                laws, foreign laws and international treaties and/or
                conventions. In connection with the Services, the Platform may
                display certain trademarks belonging to third parties. Use of
                these trademarks may be subject to the license granted by third
                parties to Near2Property App. You shall, in no event, reverse
                engineer, decompile, or disassemble such trademarks and nothing
                herein shall be construed to grant you any right in relation to
                such trademarks. Materials on the Platform are provided to you
                AS IS for your information and personal use only and may not be
                used, copied, reproduced, distributed, transmitted, broadcast,
                displayed, sold, licensed, or otherwise exploited for any other
                purposes whatsoever without the prior written consent of the
                respective owners. Near2Property App reserves all rights not
                expressly granted herein to the Platform and the Materials. You
                agree to not engage in the use, copying, or distribution of any
                of the Materials other than as expressly permitted herein,
                including any use, copying, or distribution of Materials of
                third parties obtained through the Platform for any commercial
                purposes. If you download or print a copy of the Materials for
                personal use, you must retain all copyright and other
                proprietary notices contained therein. You agree not to
                circumvent, disable or otherwise interfere with security-related
                features of the Platform or features that prevent or restrict
                use or copying of any Materials or enforce limitations on use of
                the Platform or the Materials therein. The Service is protected
                to the maximum extent permitted by copyright laws, other laws,
                and international treaties and/or conventions. The content
                displayed on or through the Service is protected by copyright as
                a collective work and/or compilation, pursuant to copyrights
                laws, other laws, and international conventions. Any
                reproduction, modification, creation of derivative works from or
                redistribution of the Platform, the Materials, or the collective
                work or compilation is expressly prohibited. Copying or
                reproducing the Platform, the Materials, or any portion thereof
                to any other server or location for further reproduction or
                redistribution is expressly prohibited. You further agree not to
                reproduce, duplicate or copy Content or Materials from the
                Service, and agree to abide by any and all copyright notices and
                other notices displayed on the Service. You may not decompile or
                disassemble, reverse engineer or otherwise attempt to discover
                any source code contained in the Service. Without limiting the
                foregoing, you agree not to reproduce, duplicate, copy, sell,
                resell or exploit for any commercial purposes, any aspect of the
                Service.<br></br>
                <br></br>
              </p>
              <p>
                ix. USER SUBMISSIONES<br></br>
                <br></br>
              </p>
              <p>
                You understand that when using the Platform, you will be exposed
                to Content from a variety of sources and that Near2Property App
                is not responsible for the accuracy, usefulness, safety, or
                intellectual property rights of or relating to such Content, and
                you agree and assume all liability for your use. You further
                understand and acknowledge that you may be exposed to Content
                that is inaccurate, offensive, indecent, or objectionable,
                defamatory or libelous and you agree to waive, and hereby do
                waive, any legal or equitable rights or remedies you have or may
                have against Near2Property App with respect thereto.<br></br>
                <br></br>
              </p>
              <p>
                x. INDEMNITY<br></br>
                <br></br>
              </p>
              <p>
                You agree to defend, indemnify and hold harmless Near2Property
                App, its officers, subsidiaries, affiliates, successors,
                assigns, directors, officers, agents, service providers,
                suppliers and employees, from and against any and all claims,
                damages, obligations, losses, liabilities, costs or debt, and
                expenses (including but not limited to attorneys' fees) arising
                from: (i) your use of and access to the Platform and/or the
                Service; (ii) your violation of any term of these Terms; (iii)
                your violation of any third party right, including without
                limitation any copyright, trademark, trade secret or other
                property, or privacy right; or (iv) any claim that your Content
                caused damage to a third party. This defense and indemnification
                obligation will survive termination, modification or expiration
                of these Terms and your use of the Service and the Platform.
                <br></br>
                <br></br>
              </p>
              <p>
                xi. NO SPAM POLICY<br></br>
                <br></br>
              </p>
              <p>
                You understand and agree that sending unsolicited email
                advertisements or other unsolicited communications to
                Near2Property App email addresses or through Near2Property App
                computer systems are expressly prohibited by these Terms. You
                acknowledge and agree that from time to time Near2Property App
                may monitor email usage using human monitors or automated
                software to flag certain words associated with spam or scams in
                emails that are sent between one user to another in the
                Near2Property App email system. Any communication between
                yourself and any other user utilizing the communication features
                available on the Service and the Platform may be used only in
                accordance with the Terms. Any unauthorized use of Near2Property
                App computer systems is a violation of these Terms and certain
                applicable laws. Such violations may subject the sender and his
                or her agents to civil and criminal penalties.
                <br></br>
                <br></br>
              </p>
              <p>
                xii. DEALINGS WITH ORGANIZATIONS AND INDIVIDUALS<br></br>
                <br></br>
              </p>
              <p>
                You acknowledge and agree that Near2Property App shall not be
                liable for your interactions with any organizations and/or
                individuals on the Platform or through the Service. This
                includes, but is not limited to, payment and delivery of goods
                and services, and any other terms, conditions, warranties or
                representations associated with any interaction you may have
                with other organizations and/or individuals. These dealings are
                solely between you and such organizations and/or individuals.
                You agree and acknowledge that Near2Property App shall not be
                responsible or liable for any loss or damage of any sort
                incurred as the result of any such dealings or interactions. If
                there is a dispute between participants on the Platform, or
                between users and any third party, you understand and agree that
                Near2Property App is under no obligation to become involved in
                such dispute. In the event that you have a dispute with one or
                more other users, you hereby release Near2Property App, its
                officers, employees, agents and successors from any and all
                claims, demands and damages (actual and consequential) of every
                kind or nature, known or unknown, suspected and unsuspected,
                disclosed and undisclosed, arising out of or in any way related
                to such disputes and/or our Service.<br></br>
                <br></br>
              </p>
              <p>
                xiii. LIMITATION AND TERMINATION OF SERVICE<br></br>
                <br></br>
              </p>
              <p>
                You acknowledge and agree that Near2Property App may establish
                limits from time to time concerning use of the Service,
                including among others, the maximum number of days that Content
                will be maintained or retained by the Service, the maximum
                number and size of postings, e-mail messages, or other Content
                that may be transmitted or stored by the Service, and the
                frequency and the manner in which you may access the Service or
                the Platform. You acknowledge that your account is identified
                and linked through your mobile number, Facebook account or email
                address through which you have registered. In the event you have
                more than one account linked through your mobile number,
                Facebook account or email address, Near2Property App reserves
                the right to remove or restrict the usage of such duplicate
                accounts. You acknowledge and agree that Near2Property App has
                no responsibility or liability for the deletion or failure to
                store any Content maintained or transmitted by the Platform or
                the Service. You acknowledge and agree that Near2Property App
                reserves the right at any time to modify, limit or discontinue
                the Service (or any part thereof) with or without notice and
                that Near2Property App shall not be liable to you or to any
                third party for any such modification, suspension or
                discontinuance of the Service. You acknowledge and agree that
                Near2Property App, in its sole and absolute discretion, has the
                right (but not the obligation) to delete or deactivate your
                account, block your e-mail or IP address, or otherwise terminate
                your access to or use of the Service (or any part thereof),
                immediately and without notice, and remove and discard any
                Content within the Service, for any reason or no reason at all,
                including, without limitation, if Near2Property App believes
                that you have violated these Terms. Further, you agree that
                Near2Property App shall not be liable to you or any third-party
                for any termination of your access to the Platform or the
                Service. Further, you agree not to attempt to use the Service
                after any such termination.<br></br>
                <br></br>
              </p>
              <p>
                xiv. DISCLAIMER OF WARRANTIES<br></br>
                <br></br>
              </p>
              <p>
                YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE PLATFORM AND
                THE SERVICE IS ENTIRELY AT YOUR OWN RISK AND THAT THE PLATFORM
                AND THE SERVICE ARE PROVIDED ON AN "AS IS" OR "AS AVAILABLE"
                BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL EXPRESS AND
                IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY
                DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE
                FULLEST EXTENT PERMITTED BY LAW Near2Property App, ITS OFFICERS,
                DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
                EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND YOUR USE
                THEREOF. Near2Property App MAKES NO WARRANTIES OR
                REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                PLATFORM'S CONTENT OR THE CONTENT OF ANY THIRD PARTY WEBSITES
                LINKED TO THE PLATFORM AND ASSUMES NO LIABILITY OR
                RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
                CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                PLATFORM AND SERVICE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF
                OUR SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
                FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR
                CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM, (IV) ANY
                BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY,
                AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
                LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                ANY CONTENT POSTED, EMAILED, COMMUNICATED, TRANSMITTED, OR
                OTHERWISE MADE AVAILABLE VIA THE PLATFORM OR THE SERVICE.
                Near2Property App DOES NOT WARRANT, ENDORSE, GUARANTEE, OR
                ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED
                WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
                Near2Property App WILL NOT BE A PARTY TO OR IN ANY WAY BE
                RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND/OR
                OTHER USERS AND/OR THIRD-PARTY PROVIDERS OF PRODUCTS OR
                SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH
                ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.<br></br>
                <br></br>
              </p>
              <p>
                xv. LIMITATIONS OF LIABILITY<br></br>
                <br></br>
              </p>
              <p>
                IN NO EVENT SHALL Near2Property App, ITS OFFICERS, DIRECTORS,
                EMPLOYEES, OR AGENTS, BE LIABLE FOR DIRECT, INDIRECT,
                INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF
                Near2Property App HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES), RESULTING FROM ANY ASPECT OF YOUR USE OF THE PLATFORM
                OR THE SERVICE, INCLUDING WITHOUT LIMITATION WHETHER THE DAMAGES
                ARISE FROM USE OR MISUSE OF THE PLATFORM OR THE SERVICE, FROM
                INABILITY TO USE THE PLATFORM OR THE SERVICE, OR THE
                INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION, OR
                TERMINATION OF THE PLATFORM OR THE SERVICE. SUCH LIMITATION OF
                LIABILITY SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY
                REASON OF OTHER SERVICES OR PRODUCTS RECEIVED THROUGH OR
                ADVERTISED IN CONNECTION WITH THE PLATFORM OR THE SERVICE OR ANY
                LINKS ON THE PLATFORM, AS WELL AS BY REASON OF ANY INFORMATION,
                OPINIONS OR ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION
                WITH THE PLATFORM OR THE SERVICE OR ANY LINKS ON THE
                Near2Property App SITE. THESE LIMITATIONS SHALL APPLY TO THE
                FULLEST EXTENT PERMITTED BY LAW. YOU SPECIFICALLY ACKNOWLEDGE
                AND AGREE THAT Near2Property App SHALL NOT BE LIABLE FOR USER
                SUBMISSIONS OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF
                ANY USER OR THIRD PARTY AND THAT THE RISK OF HARM OR DAMAGE FROM
                THE FOREGOING RESTS ENTIRELY WITH YOU.THE PLATFORM IS CONTROLLED
                AND OFFERED BY Near2Property App. Near2Property App MAKES NO
                REPRESENTATIONS OR WARRANTIES THAT THE PLATFORM IS APPROPRIATE
                FOR USE IN OTHER LOCATIONS. THOSE WHO ACCESS OR USE THE PLATFORM
                FROM OTHER JURISDICTIONS DO SO AT THEIR OWN VOLITION AND RISK
                AND ARE RESPONSIBLE FOR COMPIANCE WITH LOCAL LAW.<br></br>
                <br></br>
              </p>
              <p>
                xvi. ASSIGNMENT<br></br>
                <br></br>
              </p>
              <p>
                These Terms, and any rights and licenses granted hereunder, may
                not be transferred or assigned by you, but may be assigned by
                Near2Property App without restriction. Any assignment or
                transfer by you shall be null and void.<br></br>
                <br></br>
              </p>
              <p>
                xvii. ABILITY TO ACCEPT TERMS OF SERVICE<br></br>
                <br></br>
              </p>
              <p>
                This Platform is intended only for adults and that you are
                eligible to contract as per applicable laws. If you are
                using/accessing this Platform as a representative of any
                person/entity, you acknowledge that you are legally authorized
                to represent that person/entity. Minors, i.e. users of under 18
                years of age, are only allowed to access the Platform and use
                the Service, in the event of approval of their legal
                representatives or in the event that it concerns an act or a
                transaction that is usual and acceptable standard in civil life
                and practice. You affirm that you are either at least 18 years
                of age, or an emancipated minor, or possess legal parental or
                guardian consent, and are fully able and competent to enter into
                the terms, conditions, obligations, affirmations,
                representations, and warranties set forth in these Terms, and to
                abide by and comply with these Terms. In any case, you affirm
                that you are over the age of 13, as the Platform is not intended
                for children under 13.<br></br>
                <br></br>
              </p>
              <p>
                NOTICE TO CHILDREN UNDER THE AGE OF 13 AND THEIR PARENTS OR
                GUARDIANS If you are under the age of 13, YOU MUST NOT USE THIS
                PLATFORM. Please do not send us your personal information,
                including your email addresses, name, and/or contact
                information. If you want to contact us, you may only do so
                through your parent or legal guardian.<br></br>
                <br></br>
              </p>
              <p>
                xviii. GENERAL INFORMATION<br></br>
                <br></br>
              </p>
              <p>
                These Terms and the other policies posted on the Platform
                constitute the complete and exclusive understanding and
                agreement between you and Near2Property App and govern your use
                of the Service and the Platform superseding all prior
                understandings, proposals, agreements, negotiations, and
                discussions between the parties, whether written or oral. The
                Terms and the relationship between you and Near2Property App
                shall be governed by the laws of the Netherlands. Any claim you
                may have against Near2Property App must be submitted to the
                exclusive jurisdiction the courts of Mohali, Punjab,India.
                However, in the event that you are a consumer, it may be that
                consumer law requires that another law is applicable and that a
                claim may be submitted to another jurisdiction. The failure of
                Near2Property App to exercise or enforce any right or provision
                of the Terms shall not constitute a waiver of such right or
                provision. If any provision of the Terms is found by a court of
                competent jurisdiction to be invalid (including, without
                limitation, because such provision is inconsistent with the laws
                of another jurisdiction) or inapplicable, the parties
                nevertheless agree that the court should endeavor to give effect
                to the parties' intentions as reflected in the provision. If any
                provision or provisions of these Terms is held to be invalid,
                illegal or unenforceable, the validity, legality and
                enforceability of the remaining provisions of the Terms shall
                not in any way be affected or be impaired. YOU AGREE THAT ANY
                CAUSE OF ACTION BROUGHT BY YOU AND ARISING OUT OF OR RELATED TO
                YOUR USE OF THE SERVICE AND/OR THE PLATFORM MUST COMMENCE WITHIN
                A REASONABLE TIME AND IN ANY EVENT WITHIN ONE (1) YEAR AFTER THE
                CAUSE OF ACTION ACCRUES, EXCEPT THAT Near2Property App MAY
                COMMENCE ANY SUCH CAUSE OF ACTION IN ACCORDANCE WITH THE
                APPLICABLE STATUTE OF LIMITATIONS UNDER DUTCH LAW. OTHERWISE,
                SUCH CAUSE OF ACTION IS PERMANENTLY BARRED. These Terms shall
                inure to the benefit of and be binding upon each party's
                successors and assigns.
                <br></br>
                <br></br>
              </p>
              <p>
                xix. VIOLATION OF TERMS ANED DAMAGES<br></br>
                <br></br>
              </p>
              <p>
                Please report any violations of the Terms that you become aware
                of by contacting us using the link at the bottom of the
                Near2Property App homepage at www.Near2Property App. Any failure
                to act by Near2Property App with respect to a breach by you or
                others does not waive our right to act with respect to
                subsequent or similar breaches by you or others. You understand
                and agree that, because damages are often difficult or
                impossible to quantify, if actual damages cannot be reasonably
                calculated then you will be liable to pay Near2Property App the
                following as liquidated damages (and not a penalty):
                <br></br>
                <br></br>
              </p>
              <p>
                a. If Near2Property App establishes any limits on the frequency
                with which you may access the Service, or terminates your access
                to or use of the Service, you agree to pay Near2Property App
                Nine Hundred Ninety Nine INR (999) for (i) each message posted
                in excess of such limits or (ii) for each day on which you
                access Near2Property App in excess of such limits, whichever is
                higher.<br></br>
                <br></br>
              </p>
              <p>
                b. If you are a Posting Agent that uses the Service in violation
                of these Terms, in addition to any liquidated damages under
                Section xx(e) below you agree to pay Near2Property App nine
                hundred ninety-nine INR (999) for each and every item you post
                in violation of these Terms. A Posting Agent will also be deemed
                an agent of the party engaging the Posting Agent to access the
                Service, and such party (by engaging the Posting Agent in
                violation of these Terms) agrees to pay Near2Property App an
                additional nine hundred ninety-nine INR (999) for each item
                posted by the Posting Agent on behalf of such party in violation
                of these Terms.<br></br>
                <br></br>
              </p>
              <p>
                d. If you use Near2Property App e-mail addresses or computer
                systems to send unsolicited e-mail advertisements to
                Near2Property App e-mail addresses or any other party, you agree
                to pay Near2Property App Nine Hundered Ninety Nine INR (999) for
                each such e-mail.<br></br>
                <br></br>
              </p>
              <p>
                e. If you post messages in violation of these Terms, other than
                as described above, you agree to pay Near2Property App nine
                hundred ninety-nine INR (999) for each such message.
                Notwithstanding the foregoing, Near2Property App may, in its
                sole and absolute discretion, issue a warning before assessing
                damages pursuant to this Section xx(e).<br></br>
                <br></br>
              </p>
              <p>
                f. If you aggregate, copy, display, mirror, reproduce, or
                otherwise exploit for any purpose any Content (except for your
                own Content) in violation of these Terms without Near2Property
                App's express written permission, you agree to pay Near2Property
                App One Lakh INR(1,00000) for each day on which you engage in
                such conduct.
                <br></br>
                <br></br>
              </p>
              <p>
                If none of the foregoing clauses (a) – (f) are applicable, you
                agree to pay Near2Property App’s actual damages, to the extent
                such actual damages can be reasonably calculated.
                Notwithstanding any other provision of these Terms,
                Near2Property App retains the right to seek equitable remedies,
                including without limitation, specific performance of any term
                contained in these Terms, or a preliminary or permanent
                injunction against the breach or threatened breach of any such
                term or in aid of the exercise of any power granted in these
                Terms, or any combination thereof, without the necessity of
                posting a bond.<br></br>
                <br></br>
              </p>
              <p>
                xx. PRIVACY POLICY.<br></br>
                <br></br>
              </p>
              <p>
                Eligibility.<br></br>
                <br></br>
              </p>
              <p>
                To register as a member of Near2Property App or use this Site,
                you must be legally competent and of legal marriageable age as
                per the laws of India (currently, 18 years or over for females
                and 21 years or over for males).<br></br>
                <br></br>
              </p>
              <p>
                The Near2Property App site is only to facilitate personal
                advertisement for lawful marriage alliance between persons who
                are legally competent to enter into matrimony under the laws to
                which they are subject. Membership in the Service is void where
                prohibited.
                <br></br>
                <br></br>
              </p>
              <p>
                By using this Site, you represent and warrant that you have the
                right, consent, authority, and legal capacity to enter into this
                Agreement; submit relevant information to us; and that you are
                not prohibited or prevented by any applicable law for the time
                being in force or any order or decree or injunction from any
                court, tribunal or any such competent authority restraining you
                from entering into matrimony.<br></br>
                <br></br>
              </p>
              <p>
                You further confirm that you, intent to seek a life partner and
                that you shall not use this site for casual dating. You also
                agree to abide by all of the terms and conditions of this
                Agreement. If at any time Near2Property App is of the opinion
                (in its sole discretion) or has any reason to believe that you
                are not eligible to become a member or that you have made any
                misrepresentation, Near2Property App reserves the right to
                forthwith terminate your membership and / or your right to use
                the Service without any refund to you of any of your unutilized
                subscription fee.<br></br>
                <br></br>
              </p>
              <p>
                Account Security.<br></br>
                <br></br>
              </p>
              <p>
                You are responsible for maintaining the confidentiality of your
                login credentials you designate during the registration process,
                and you are solely responsible for all activities that occur
                under your account. You agree to immediately notify us of any
                disclosure or unauthorized use of your account or any other
                breach of security, and ensure that you log out from your
                account at the end of each session.<br></br>
                <br></br>
              </p>
              <p>
                Term and Termination.<br></br>
                <br></br>
              </p>
              <p>
                This Agreement will be effective, valid and subsisting as long
                as you use our Site.<br></br>
                <br></br>
              </p>
              <p>
                You may terminate your membership at any time, for any reason by
                writing to Near2Property App. In the event you terminate your
                membership, you will not be entitled to a refund of any
                unutilized subscription fees, if any, paid by you, except where
                otherwise guaranteed in writing.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App may terminate your access to the Site and/or
                your membership for any reason including but not limited to
                breach of the terms of use, using the service for commercial
                purpose, engaging in prohibited or inappropriate communication
                or activity, by sending notice to you at the email address as
                provided by you in your application for membership or such other
                email address as you may later provide to Near2Property App. If
                Near2Property App terminates your membership for breach of terms
                of this Agreement, you will not be entitled to any refund of any
                Subscription fees, if any, paid by you.<br></br>
                <br></br>
              </p>
              <p>
                Certain provisions of this Agreement including sections 4, 5, 6,
                7 and sections 9 to 16, of this Agreement shall remain effective
                even on termination.<br></br>
                <br></br>
              </p>
              <p>
                Term for the paid Membership would be in accordance with the
                type of membership undertaken.<br></br>
                <br></br>
              </p>
              <p>
                Non-Commercial Use by Members.<br></br>
                <br></br>
              </p>
              <p>
                The Site is for the personal use of individual members to
                advertise and promote their profiles for the purpose of
                generating accepts from relevant matches and cannot be used in
                connection with any commercial endeavors. This includes
                providing links to other web sites, whether deemed competitive
                to Near2Property App or otherwise. Organizations, companies,
                and/or businesses cannot become Members of Near2Property App and
                should not use the Near2Property App Service or Site for any
                purpose. Illegal and/or unauthorized uses of the Site, including
                unauthorized framing of or linking to the Site will be
                investigated, and appropriate legal action will be taken,
                including without limitation, civil, criminal, and injunctive
                redress.<br></br>
                <br></br>
              </p>
              <p>
                Other Terms of Use for Members.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App reserves the right in it’s sole discretion to
                review the activity and status of each account and block access
                to the member based on such review.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App reserves the right in its sole discretion to
                restrict/suspend the member’s free access to its website based
                on review of the member’s activity, status or any such criteria
                Near2Property App may deem fit and proper, with due intimation
                to the member.<br></br>
                <br></br>
              </p>
              <p>
                You hereby confirm that as on date of this registration, you do
                not have any objection to receive emails, SMS/WhatsApp messages
                and calls from Near2Property App and members of Near2Property
                App as long as you are a registered member of Near2Property App,
                including SMS permission for authenticating mobile transactions
                via OTP, sent by the Payment Gateway. This consent shall
                supersede any preferences set by you with or registration done
                with the Do Not Disturb (DND Register)/ National Customer
                Preference Register (NCPR). This consent extends to emails,
                messages or calls relating but not limited to phone number
                verification, the provision of matchmaking advertising service,
                matchmaking enquiries and promotions.<br></br>
                <br></br>
              </p>
              <p>
                Multiple profiles of the same person are not allowed on our
                Site. Near2Property App reserves the right to deactivate all
                multiple profiles without any refund of subscription fees.
                <br></br>
                <br></br>
              </p>
              <p>
                You confirm that the Content, information including the personal
                information provided by you is correct and accurate.<br></br>
                <br></br>
              </p>
              <p>
                You are strongly advised to submit copy of your Driving License,
                Passport or any other Government issued identity and add a trust
                badge to your profile.<br></br>
                <br></br>
              </p>
              <p>
                Except for the purpose of promoting/advertising your profile for
                matchmaking purposes, you cannot engage in advertising to, or
                solicitation of, other Members to buy or sell any products or
                services through the Service.<br></br>
                <br></br>
              </p>
              <p>
                You will not transmit any chain letters or junk email to other
                Near2Property App Members. Although Near2Property App cannot
                monitor the conduct of its Members off the Near2Property App
                Site, it is also a violation of these rules to use any
                information obtained from other members in order to harass,
                abuse, or harm another person, or in order to contact, advertise
                to, solicit, or sell to any Member without their prior explicit
                consent.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App reserves the right to take appropriate steps
                to protect Near2Property App and/or its Members from any
                abuse/misuse as it deems appropriate in its sole discretion.
                <br></br>
                <br></br>
              </p>
              <p>
                You cannot use any automated processes, including IRC Bots,
                EXE’s, CGI or any other programs/scripts to view content on or
                communicate / contact / respond / interact with Near2Property
                App and/or its members.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App reserves the right to screen
                communications/advertisements that you may send to other
                Member(s) and also regulate the same by deleting
                unwarranted/obscene communications/advertisements at any time at
                its sole discretion without prior notice to any Member.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App Members are expected to exercise simple
                precautions for their privacy and safety. A suggestive guideline
                of such precautions is enlisted under security tips “ By
                becoming a Member you hereby <br></br>
                <br></br>
              </p>
              <p>
                How to sell safely<br></br>
                <br></br>
              </p>
              <p>
                Try to take the full payment at the time of the delivery.
                Remember to use a safe location to meet Watch out for fake
                currency and money orders. Be aware that banks will not honor
                fake currency/money order and will hold you responsible for it.
                Verify the buyer’s credentials if there is a request for bulk
                order. Ask the buyer to provide a photo ID and proof of address.
                Ensure that your product meets the features you list in your ad
                to avoid any misunderstanding with the buyer.Do not share your
                financial information except the on e required for payment.
                <br></br>
                <br></br>
              </p>
              <p>
                How to buy safely?<br></br>
                <br></br>
              </p>
              <p>
                Always meet the seller at a safe location and inspect the item
                before purchasing. Following this one rule will help you avoid
                most scams. This is also true for house rentals as well.Watch
                out for unrealistic offers.If it appears too good to be true, it
                probably isn't. Unrealistic offers are used to motivate
                purchasers to make hasty decisionsThe best way to transact is to
                collect the item and pay at the same time.Never transfer funds
                or use a debit card to pay someone you do not know before
                collecting an item.Always insist for clear and correct
                information on the condition of the item, price, payment, and
                delivery method.Use only those payment methods that you trust
                and are familiar with.Never give out your financial
                information.It is recommended to purchase items directly from
                the seller In the case of electronic goods, cars, and bikes you
                should test the functioning of the item before purchase.Verify
                vehicle chassis number.3rd party services are available that can
                provide the history of a vehicle for theft, damage, and for
                identification purposes.Do your research on comparative prices
                for the product.<br></br>
                <br></br>
              </p>
              <p>
                How to take care of the safety of your account on Near2Property
                App
                <br></br>
                <br></br>
              </p>
              <p>
                Never reveal your login and password of your account. Think of a
                difficult password consisting of minimum 6 characters, including
                at least one digit or unique.For safety reasons, we do not
                recommend storing the password in the browser.If you feel that
                someone knows your password - immediately change to a new one.
                <br></br>
                <br></br>
              </p>
              <p>
                Call Near2Property App Trust Safety helpline- +91-91-5085-5085
                (10:00 am to 07:00 pm) or write to us at contact@Near2Property
                App to seek clarity and avoid chances of getting trapped.
                <br></br>
                <br></br>
              </p>
              <p>
                Tips. By becoming a Member you hereby agree to abide and adhere
                to the said Tips.<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App Members who subscribe to the Near2Property App
                Select Service hereby, unconditionally and irrevocably confirm
                that you have read terms and conditions and agree to abide by
                them.<br></br>
                <br></br>
              </p>
              <p>
                Proprietary Rights in the Content on Near2Property App<br></br>
                <br></br>
              </p>
              <p>
                Near2Property App owns and retains all proprietary rights in the
                Near2Property App Site and the Near2Property App Service. The
                Site contains the copyrighted material, trademarks, and other
                proprietary information of Near2Property App, and its licensors.
                Except for that information which is in the public domain such
                as member profile or for which permission has been obtained from
                the user, you cannot copy, modify, publish, transmit,
                distribute, perform, display, or sell any such proprietary
                information. Any such act or an attempted act on your part shall
                constitute a violation of this Agreement and your membership is
                liable to be terminated forthwith by Near2Property App without
                refund of any of your unused Subscription fees. Near2Property
                App reserves right to take legal action (civil and/or criminal)
                wherever applicable for any violations.<br></br>
                <br></br>
              </p>
              <p>
                Content Posted on the App/WebSite.<br></br>
                <br></br>
              </p>
              <p>
                You understand and agree that Near2Property App may delete any
                listing, content, communication, photos or profiles
                (collectively, “Content”) that in the sole judgment of
                Near2Property App violate this Agreement or which might be
                offensive, illegal, or that might violate the rights, harm, or
                threaten the safety of either Near2Property App and/or its
                Members.<br></br>
                <br></br>
              </p>
              <p>
                With respect to Content you submit or make available for
                inclusion on publicly accessible areas of the Site including but
                not limited to your contact details, you hereby unconditionally
                and irrevocably grant to Near2Property App the license to use,
                store, distribute, reproduce, prepare derivative works of,
                modify, adapt, publicly perform and publicly display such
                Content on the Site and to the members of Near2Property App
                community site(s) from time to time.<br></br>
                <br></br>
              </p>
              <p>
                You understand and hereby agree that all information, data, p,
                photographs, graphics, communications, tags, or other Content
                whether publicly posted or privately transmitted or otherwise
                made available to Near2Property App are the sole responsibility
                of the person from whom such Content originated and shall be at
                the member’s/person’s sole risks and consequences. This means
                that you (and not Near2Property App) are solely responsible for
                all Content that you upload, post, email, transmit or otherwise
                make available via the Service. Near2Property App does not
                control the Content posted via the Service and, as such, does
                not guarantee the accuracy, integrity or quality of such
                Content. Under no circumstances will Near2Property App be liable
                in any way for any Content, including, but not limited to, any
                errors or omissions in any Content, or any loss or damage of any
                kind incurred as a result of the use of any Content posted,
                emailed, transmitted or otherwise made available via the
                Service. Near2Property App reserves the right to verify the
                authenticity of Content posted on the Site. In exercising this
                right, Near2Property App may ask you to provide any documentary
                or other form of evidence supporting the Content you post on the
                Site. If you fail to produce such evidence to the satisfaction
                of Near2Property App, Near2Property App may, in its sole
                discretion, terminate your Membership without a refund.<br></br>
                <br></br>
              </p>
              <p>
                The following is an indicative list of the kind of Content that
                is illegal or prohibited on the Site. Near2Property App will
                investigate and take appropriate legal action in its sole
                discretion against anyone who violates this Agreement, including
                without limitation, removing the offending communication/Content
                from the Service and terminating the Membership of such
                violators without a refund.<br></br>
                <br></br>
              </p>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default TermOfUse;
