import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV4 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">

							<div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								<div className="ltn__animation-pulse1">
									<img src={"assets/img/property-1/property4.jpg"} alt="near2-property-about" />

								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">
							<div className="section-title-area ltn__section-title-2--- mb-20" style={{ marginTop: '50px' }}>
								<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
								<h1 className="section-title">The Leading Real Estate
									Rental Marketplace<span>.</span></h1>
								<p>Thank you for visiting Near2Property.com, your one-stop shop for everything related to real estate! We can help you find residential, commercial, or industrial properties in your area to purchase, rent, or sell. We will introduce ourselves to you on our "About Us" page, as well as discuss our goals, vision, and dedication to offering you the best real estate services. We at Near2Property.com are more than simply a listing service; we are your reliable advisors as you explore the real estate market and decide what to do.</p>
							</div>

						</div>
					</div>
				</div>

				<div className="row">

					<div className="col-lg-6 align-self-center">
						<div className="about-us-info-wrap">

							<div className="ltn__callout bg-overlay-theme-05  mt-30">
								<h2>Goals that we are working towards</h2>
								<h6>How to Conduct a Successful Real Estate Search</h6>
								<p>"We want our users' experience using Near2Property.com's property search to be swift and easy. Due to the large number of options and needs, we understand that it may be difficult to discover the appropriate property. Our aim is to provide customers and partners with a platform that is easy to use, has comprehensive listings, relevant information,and top-notch customer support."
								</p>
							</div>

						</div>
					</div>
					<div className="col-lg-6 align-self-center">
						<div className="about-us-img-wrap about-img-left">
							<img src={"assets/img/property-1/property1.jpg"} alt="About Us Image" />
							{/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								<div className="ltn__animation-pulse1">
									<img src={"assets/img/property-1/property1.jpg"} alt="near2-property-about" />

								</div>
							</div> */}
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-12  align-self-center justify-content-center' style={{
						margin: 'auto',
						verticalAlign: 'middle',
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row'
					}}>
						<ul className="ltn__list-item-half align-item-center clearfix">
							<li>
								<i className="flaticon-home-2" />
								Smart Home Design
							</li>
							<li>
								<i className="flaticon-mountain" />
								Beautiful Scene Around
							</li>
							<li>
								<i className="flaticon-heart" />
								Exceptional Lifestyle
							</li>
							<li>
								<i className="flaticon-secure" />
								Complete 24/7 Security
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	}
}

export default AboutV4