import { SET_CURRENT_USER, REMOVE_CURRENT_USER, SET_ALL_USERS } from "./types";
import { getProfile, updateUser, getAllUsers } from "../../config/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { setRefreshToken } from '../../helpers/token'

export const setCurrentUser = (data) => async (dispatch) => {
  try {
    const res = await getProfile();

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
  } catch (e) {
    console.log(e);
  }
};
export const setAllUsers = (data) => async (dispatch) => {
  try {
    const res = await getAllUsers(data);

    dispatch({
      type: SET_ALL_USERS,
      payload: { users: res.data.users, total: res.data.total },
    });
  } catch (e) {
    console.log(e);
  }
};
export const updateCurrentUser = (data) => async (dispatch) => {
  try {
    const res = await updateUser(data);

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
  } catch (e) {
    console.log(e);
  }
};

export const logOutUser = () => async (dispatch) => {
  await localStorage.removeItem("currentUser");

  dispatch({
    type: REMOVE_CURRENT_USER,
    payload: null,
  });
};
