import React from 'react';

import BlogGridPage from '../../new-components/blog-grid';


const BlogList = () => {
    return <div>

        <BlogGridPage />

    </div>
}

export default BlogList

