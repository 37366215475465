import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";
import LogoWhite from "../../../src/assets/img/logo.png";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "new-assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img src={LogoWhite} alt="Logo" height={43} width={170} />
                    </div>
                  </div>
                  <p>Premium platform for property search.</p>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <a href='https://www.google.com/maps/place/VRITIKA+HOMES/@30.7640274,76.6547399,18z/data=!4m6!3m5!1s0x390ff1c4f63018d3:0x1da69b29c125a637!8m2!3d30.7641334!4d76.6555778!16s%2Fg%2F11srrly1z3?entry=ttu' target='_blank' style={{ cursor: 'pointer' }}>
                            <p>Sec-124, Mohali</p>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+91-98887-56062">+91-98887-56062</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:near2property@gmail.com">
                              near2property@gmail.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <Social />
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      {/* <li>
                        <Link to="/blog-grid">Blog</Link>
                      </li> */}
                      <li>
                        <Link to="/properties-list">Explore</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                      {/* <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      {/* <li>
                        <Link to="/cart">Cart</Link>
                      </li>
                      <li>
                        <Link to="/wishlist">Wish List</Link>
                      </li> */}
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                      {/* <li>
                        <Link to="/checkout">Checkout</Link>
                      </li> */}
                      <li>
                        <Link to="/term-of-use">Terms &amp; Conditions</Link>
                      </li>
                      <li>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Quick Links</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      {/* <li>
                        <Link to="/blogs">Blogs</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/wishlist">Wish List</Link>
                      </li> */}
                      <li>
                        <Link to="/add-post">Add listing</Link>
                      </li>
                      {/* <li>
                        <Link to="/faq">FAQ</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/contact">Contact us</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <div className="footer-logo">

                    <div className="site-logo" style={{ display: 'flex', flexDirection: 'column' }}>
                      <h4 className="footer-title">Get It On</h4>
                      <a href="https://play.google.com/store/apps/details?id=com.near2property&hl=en-IN" target="_blank">
                        <img src={'assets/img/logo-google.svg'} alt="Logo-Google-Play-Store" height={100} width={200} />
                      </a>
                    </div>
                  </div>
                  {/* <h4 className="footer-title">Newsletter</h4>
                  <p>
                    Subscribe to our weekly Newsletter and receive updates via
                    email.
                  </p> */}
                  {/* <div className="footer-newsletter">
                    <form action="#">
                      <input type="email" name="email" placeholder="Email*" />
                      <div className="btn-wrapper">
                        <button className="theme-btn-1 btn" type="submit">
                          <i className="fas fa-location-arrow" />
                        </button>
                      </div>
                    </form>
                  </div> */}
                  {/* <h5 className="mt-30">We Accept</h5>
                  <img
                    src={publicUrl + "new-assets/img/icons/payment-4.png"}
                    alt="Payment Image"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Copyright />
      </footer>
    );
  }
}

export default Footer_v1;
