import React, { useState } from "react";

import {
  LoginService,
} from "../../config/user";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setCurrentUser } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import LoaderSvg from "../../assets/img/loader.svg";
import { validateField } from "../../../src/helpers";

function Login({ checkLoggedInUser, getUser }) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const location = useLocation();
  const [phone, setphone] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  // const onLogin = async (e) => {
  //   e.preventDefault();
  //   let validate = true;

  //   if (phone.trim() === "") {
  //     validateField("phone", "Phone field is required");
  //     validate = false;
  //   }

  //   if (phone.trim().length != 10) {
  //     validateField("phone", "Phone Number should be 10 numbers only");
  //     validate = false;
  //   }

  //   if (password.trim() === "") {
  //     validateField("password", "Password field is required");
  //     validate = false;
  //   }

  //   if (password.trim().length < 8) {
  //     validateField("password", "Password should be minimum 8 characters");
  //     validate = false;
  //   }

  //   if (!validate) {
  //     toast("Please fill required fields");
  //     return;
  //   }
  //   try {
  //     setLoader(true);
  //     const res = await LoginService({
  //       phone,
  //       password,
  //     });

  //     if (res.data.token) {
  //       dispatch(
  //         setCurrentUser(res.data.token, () => {
  //           toast("Login Succesfully");
  //           navigate("/", { replace: true });
  //           setLoader(false);
  //           setIsLogin(true);
  //         })
  //       );
  //     }
  //   } catch (err) {
  //     setLoader(false);
  //     if (err.response.data.message) {
  //       toast(err.response.data.message);
  //     }
  //   }
  // };

  const onLogin = async (e) => {
    e.preventDefault();
    let validate = true;
    if (phone.trim() === "") {
      validateField("phone", "Phone field is required");
      validate = false;
    }
    if (password.trim() === "") {
      validateField("password", "Password field is required");
      validate = false;
    }
    try {
      setLoader(true);
      const res = await LoginService({
        phone,
        password,
      });

      if (res.data.token) {
        toast("Login Succesfully");
        let user = {
          role: res.data.role,
          admin: res.data.admin,
        };
        setLoader(false);
        localStorage.setItem("token", res.data.token);
        let search = new URLSearchParams(location?.search);
        if (search.get("redirect")) {
          navigate(search.get("redirect"), {
            replace: true,
            state: {
              id: location.state?.id || "",
            },
          });
        } else {
          navigate("/", { replace: true });
        }

        dispatch(setCurrentUser(res.data.user));
        // checkLoggedInUser();
        getUser();
      }
    } catch (err) {
      console.log(err);
      toast.error(String(err.response?.data?.message).toUpperCase())
      setLoader(false);
    }
  };

  return (
    <div>
      <div className="ltn__login-area pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area text-center">
                <h1 className="section-title">
                  Sign In <br />
                  To Your Account
                </h1>
                <p>
                  And start selling, buying or renting
                  <br />
                  properties
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="account-login-inner">
                <form
                  method="GET"
                  className="ltn__form-box contact-form-box"
                  onSubmit={(e) => {
                    onLogin(e);
                  }}
                >
                  <div class="form-group">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone*"
                      className="form-control form-control-lg"
                      autoComplete="new-password"
                      onChange={(e) => {
                        setphone(e.target.value);
                      }}
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password*"
                      className="form-control form-control-lg"
                      autoComplete="new-password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="btn-wrapper mt-0">
                    <button
                      className="theme-btn-1 btn btn-block"
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                  <div className="go-to-btn mt-20">
                    <Link
                      to="/forget-password"
                    // data-bs-toggle="modal"
                    // data-bs-target="#"
                    >
                      <small>FORGOTTEN YOUR PASSWORD?</small>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="account-create text-center pt-50">
                <h4>DON'T HAVE AN ACCOUNT?</h4>
                <p>
                  Add items to your listing and <br />
                  check out more quickly response.
                </p>
                <div className="btn-wrapper go-top">
                  <Link to="/signup" className="theme-btn-1 btn black-btn">
                    CREATE ACCOUNT
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
        <div
          className="modal fade"
          id="ltn_forget_password_modal"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => showPassInputs && setShowPassInputs(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-info text-center">
                          <h4>FORGET PASSWORD?</h4>
                          {/* {showPassInputs ? (
                            <form action="#" className="ltn__form-box">
                              <input
                                type="password"
                                name="password"
                                placeholder="New Password"
                              />
                              <input
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm New Password"
                              />
                            </form>
                          ) : ( 
                          <>
                            <p className="added-cart">
                              {" "}
                              Enter you register phone no.
                            </p>
                            <form action="#" className="ltn__form-box">
                              <input
                                type="text"
                                name="phone"
                                onChange={(e) => setphone(e.target.value)}
                                placeholder="Type your register mobile*"
                              />
                              <div className="btn-wrapper mt-0">
                                <button
                                  className="theme-btn-1 btn btn-full-width-2"
                                  type="button"
                                  onClick={onForgotPass}
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </>
                          {/* )}  
                        </div>
                        {/* additional-info * 
                        <div className="additional-info d-none">
                          <p>
                            We want to give you <b>10% discount</b> for your
                            first order, <br /> Use discount code at checkout
                          </p>
                          <div className="payment-method">
                            <img
                              src={publicUrl + "assets/img/icons/payment.png"}
                              alt="#"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Login;
