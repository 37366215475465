import { combineReducers } from "redux";
import authReducer from "./auth";
import postReducer from "./post";
import businessReducer from "./business";

const rootReducer = combineReducers({
  auth: authReducer,
  post: postReducer,
  business: businessReducer,
});

export default rootReducer;
