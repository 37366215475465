import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import NavBar from "../../components/NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { updatePost, getPropertyDetails } from "../../redux/actions/post";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleComponent } from "react-google-location";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { API_KEY } from '../../config/axiosConfig';
import "react-tabs/style/react-tabs.css";
import "../addpost/style.css";
// "AIzaSyA9bI8tKR9gOt8-QHx02ZdhELpCujPKXvg";

const EditPost = () => {
  const [loader, setLoader] = useState(false);
  const [sell, setSell] = useState();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  const user = useSelector((state) => state.auth.user);
  const property = useSelector((state) => state.post.propertyDetails);
  const navigate = useNavigate();
  useEffect(() => {
    onGetPropertyDetail();
  }, []);
  useEffect(() => {
    if (property) {
      setData(property);
      setnewData(property);
    }
  }, [property]);
  const onGetPropertyDetail = () => {
    let id = location.state && location.state.id;
    dispatch(getPropertyDetails(id));
  };
  const [newData, setnewData] = useState({
    lookingFor: "sell",
    propertyKind: "residential",
    propertyType: "apartment",
    postedBy: "owner",
    city: "",
    locality: "",
    mobile: "",
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
    price: 0,
    builtUp: "",
    carpet: "",
    maitanence: "",
    floor: "",
    floorNo: "",
    facing: "",
    bathrooms: "",
    bedrooms: "",
    furnishing: "",
    constructionStatus: "",
    title: "",
    plotArea: "",
    plotWidth: "",
    plotLength: "",
    desc: "",
    facilities: [],
    nearby: [],
    propertyImages: [],
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
  });
  const [data, setData] = useState({
    lookingFor: "sell",
    propertyKind: "residential",
    propertyType: "apartment",
    postedBy: "owner",
    city: "",
    locality: "",
    mobile: "",
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
    price: 0,
    builtUp: "",
    carpet: "",
    maitanence: "",
    floor: "",
    floorNo: "",
    facing: "",
    bathrooms: "",
    bedrooms: "",
    furnishing: "",
    constructionStatus: "",
    title: "",
    plotArea: "",
    plotWidth: "",
    plotLength: "",
    desc: "",
    facilities: [],
    nearby: [],
    propertyImages: [],
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
  });
  const [detail, setDetail] = useState({});

  const dispatch = useDispatch();

  // const checkValidations = () => {

  //     if (!newData.title.length) {
  //         toast("Please enter title")
  //         return 1
  //     }
  //     if (!lookingFor.length) {
  //         toast("Please select what are you looking for?")
  //         return 1
  //     }
  //     if (!propertyKind.length) {
  //         toast("Please select kind of propertyt?")
  //         return 1
  //     }
  //     if (!propertyKind.length) {
  //         toast("Please select kind of propertyt?")
  //         return 1
  //     }
  //     if (!propertyType.length) {
  //         toast("Please select type of property")
  //         return 1
  //     }
  //     if (!price.length) {
  //         toast("Please enter price of property")
  //         return 1
  //     }
  //     if (!mobile.length) {
  //         toast("Please enter your phone no.")
  //         return 1
  //     }

  //     return 0
  // }
  const handleCheckBoxChange = (name, val) => {
    let dataArr = [...newData[name]];
    let indexOfItem = dataArr.indexOf(val);
    if (indexOfItem == -1) {
      dataArr.push(val);
    } else {
      dataArr.splice(indexOfItem, 1);
    }
    setnewData({ ...newData, [name]: dataArr });
  };
  const trySelectFiles = (e) => {
    if (e.target.files.length > 10) {
      alert("Only 10 files are allowed max.");
      setnewData({ ...newData, propertyImages: [] });
      return;
    }

    setnewData({ ...newData, propertyImages: e.target.files });
  };
  const handleTextChange = (name, val) => {
    setnewData({ ...newData, [name]: val });
  };

  function getLat(e) {
    if (e) {
      return e.lat;
    }
    return false;
  }

  function getLng(e) {
    if (e) {
      return e.lng;
    }
    return false;
  }

  function checkChecked(name, val) {
    return newData[name].indexOf(val) != -1;
  }
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      let dataObj = {
        id: location.state.id,
      };

      for (let key in data) {
        if (newData[key] != data[key]) {
          dataObj[key] = newData[key];
        }
      }
      dispatch(
        updatePost(dataObj, (res) => {
          if (res) {
            toast("Property Updated Successfully!");
            navigate("/profile");
          }
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header />
      <NavBar />
      <ToastContainer />
      <div class="page-head">
        <div class="container">
          <div class="row">
            <div class="page-head-content">
              <h1 class="page-title">Submit new property</h1>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content-area submit-property"
        style={{ backgroundColor: "#fff" }}
      >
        &nbsp;
        <div class="container">
          <div class="clearfix">
            <div class="wizard-container">
              <div class="wizard-card ct-wizard-orange" id="wizardProperty">
                <form action="" method="Post" onSubmit={(e) => onSubmit(e)}>
                  <div class="wizard-header">
                    <h3>
                      <b>Submit</b> YOUR PROPERTY <br />
                      {/* <small></small> */}
                    </h3>
                  </div>
                  <Tabs
                    style={{ width: "100%" }}
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                  >
                    <TabList>
                      <Tab style={{ borderRight: 1, borderColor: "red" }}>
                        Step 1
                      </Tab>
                      <Tab style={{ borderRight: 1, borderColor: "red" }}>
                        Step 2
                      </Tab>
                      <Tab>Finished</Tab>
                    </TabList>

                    {/* <TabPanel>
                                            <h2>Any content 1</h2>
                                        </TabPanel>
                                        <TabPanel>
                                            <h2>Any content 2</h2>
                                        </TabPanel> */}

                    {/* <ul class="nav nav-pills nav-justified">
                                        <li className='active'><a href="#step1" data-toggle="tab">Step 1 </a></li>
                                        <li><a href="#step2" data-toggle="tab">Step 2 </a></li>
                                        <li><a href="#step3" data-toggle="tab">Step 3 </a></li>
                                        <li><a href="#step4" data-toggle="tab">Finished </a></li>
                                    </ul> */}

                    <TabPanel>
                      <div class={"tab-pane active"} id="step1">
                        <div class="row p-b-15  ">
                          <h4 class="info-text">
                            {" "}
                            Let's start with the basic information{" "}
                          </h4>
                          {/* <div class="col-sm-4 col-sm-offset-1">
                                                    <div class="picture-container">
                                                        <div class="picture">
                                                            <img src="assets/img/default-property.jpg" class="picture-src" id="wizardPicturePreview" title="" />
                                                            <input type="file" id="wizard-picture" />
                                                        </div>
                                                    </div>
                                                </div> */}
                          <div className="row looking-to-section">
                            <div className="add-post-heading">Looking to?</div>
                            <a
                              className={`badge badge-light btn looking-to ${newData.lookingFor == "sell" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, lookingFor: "sell" });
                              }}
                            >
                              Sell
                            </a>
                            <a
                              className={`badge badge-light btn looking-to ${newData.lookingFor == "rent" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, lookingFor: "rent" });
                              }}
                            >
                              Rent
                            </a>
                            <a
                              className={`badge badge-light btn looking-to ${newData.lookingFor == "pg" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, lookingFor: "pg" });
                              }}
                            >
                              PG
                            </a>
                          </div>
                          <div className="row looking-to-section">
                            <div className="add-post-heading">
                              Kind of property?
                            </div>
                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyKind == "residential" &&
                                "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyKind: "residential",
                                });
                              }}
                            >
                              Residential
                            </a>
                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyKind == "commercial" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyKind: "commercial",
                                });
                              }}
                            >
                              Commercial
                            </a>
                          </div>
                          <div className="row looking-to-section">
                            <div className="add-post-heading">Posted By?</div>
                            <a
                              className={`badge badge-light btn looking-to ${newData.postedBy == "owner" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, postedBy: "owner" });
                              }}
                            >
                              Owner
                            </a>
                            <a
                              class="badge badge-light btn looking-to"
                              className={`badge badge-light btn looking-to ${newData.postedBy == "builder" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, postedBy: "builder" });
                              }}
                            >
                              Builder
                            </a>
                            <a
                              class="badge badge-light btn looking-to"
                              className={`badge badge-light btn looking-to ${newData.postedBy == "dealer" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, postedBy: "dealer" });
                              }}
                            >
                              Dealer
                            </a>
                          </div>

                          <div className="row looking-to-section">
                            <div className="add-post-heading">
                              Property Type?
                            </div>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "apartment" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "apartment",
                                });
                              }}
                            >
                              Apartment
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "geust-house" &&
                                "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "geust-house",
                                });
                              }}
                            >
                              Guest House
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "shop-office-booth" &&
                                "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "shop-office-booth",
                                });
                              }}
                            >
                              Shop-Office-Booth
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "independent-house" &&
                                "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "independent-house",
                                });
                              }}
                            >
                              Independent House
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "farm-house" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "farm-house",
                                });
                              }}
                            >
                              Farm-House
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "builder-floor" &&
                                "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "builder-floor",
                                });
                              }}
                            >
                              Builder Floor
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == "pg" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({ ...newData, propertyType: "pg" });
                              }}
                            >
                              PG
                            </a>

                            <a
                              className={`badge badge-light btn looking-to ${newData.propertyType == ">plot-land" && "active"
                                }`}
                              onClick={(e) => {
                                e.preventDefault();
                                setnewData({
                                  ...newData,
                                  propertyType: "plot-land",
                                });
                              }}
                            >
                              Plot-Land
                            </a>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label className="add-post-heading">
                                City <small>(required)</small>
                              </label>
                              <input
                                name="city"
                                type="text"
                                class="form-control"
                                disabled={true}
                                value={newData.cityName}
                                placeholder="98 111 11111"
                                onChange={(e) => {
                                  handleTextChange("mobile", e.target.value);
                                }}
                              />
                              {/* <input name="city" type="text" class="form-control" value={newData.city} placeholder="City name" onChange={(e) => { handleTextChange('city', e.target.value); }} /> */}
                            </div>

                            <div class="form-group">
                              <label className="add-post-heading">
                                Locality <small>(required)</small>
                              </label>
                              {/* <input name="locality" type="text" class="form-control" value={newData.locality} placeholder="Locality"
                                                                onChange={(e) => { handleTextChange('locality', e.target.value); }} /> */}
                              <input
                                name="locality"
                                type="text"
                                class="form-control"
                                value={newData.locName}
                                disabled={true}
                                placeholder="98 111 11111"
                                onChange={(e) => {
                                  handleTextChange("mobile", e.target.value);
                                }}
                              />
                            </div>
                            <div class="form-group">
                              <label className="add-post-heading">
                                Phone <small>(required)</small>
                              </label>
                              <input
                                name="mobile"
                                type="text"
                                class="form-control"
                                value={newData.mobile}
                                placeholder="98 111 11111"
                                onChange={(e) => {
                                  handleTextChange("mobile", e.target.value);
                                }}
                              />
                            </div>
                            <div class="form-group">
                              <label className="add-post-heading">
                                Price <small>(required)</small>
                              </label>
                              <input
                                name="price"
                                type="text"
                                class="form-control"
                                value={newData.price}
                                placeholder="Price of property"
                                onChange={(e) => {
                                  handleTextChange("price", e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div class="tab-pane" id="step2">
                        <h4 class="info-text">Additional Details</h4>
                        {/* plot section  */}
                        {newData.propertyType == "plot-land" ? (
                          <div className="row">
                            <div className="col-md-3">
                              <label>Plot Area (Sq Yard)</label>
                              <input
                                name="plotArea"
                                value={newData.plotArea}
                                onChange={(e) => {
                                  handleTextChange("plotArea", e.target.value);
                                }}
                                type="text"
                                class="form-control"
                                placeholder="Plot-Land Area*"
                              />
                            </div>
                            <div className="col-md-3">
                              <label>Plot Length(Sq Ft)</label>
                              <input
                                name="plotLength"
                                type="text"
                                value={newData.plotLength}
                                onChange={(e) => {
                                  handleTextChange(
                                    "plotLength",
                                    e.target.value
                                  );
                                }}
                                class="form-control"
                                placeholder="Plot-Land Length"
                              />
                            </div>
                            <div className="col-md-3">
                              <label>Plot Width(Sq Ft)</label>
                              <input
                                name="plotWidth"
                                type="text"
                                value={newData.plotWidth}
                                onChange={(e) => {
                                  handleTextChange("plotWidth", e.target.value);
                                }}
                                class="form-control"
                                placeholder="Plot-Land Width"
                              />
                            </div>
                            <div className="col-md-3">
                              <label>Facing</label>
                              <input
                                name="facing"
                                value={newData.facing}
                                onChange={(e) => {
                                  handleTextChange("facing", e.target.value);
                                }}
                                type="text"
                                class="form-control"
                                placeholder="Plot Facing*"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-2">
                              <label>Builtp are (Sq Ft)*</label>
                              <input
                                name="builtUp"
                                type="text"
                                value={newData.builtUp}
                                onChange={(e) => {
                                  handleTextChange("builtUp", e.target.value);
                                }}
                                class="form-control"
                                placeholder="Builup area*"
                              />
                            </div>
                            <div className="col-md-2">
                              <label>'Carpet Area ( Sq ft)'</label>
                              <input
                                name="carpet"
                                type="text"
                                value={newData.carpet}
                                onChange={(e) => {
                                  handleTextChange("carpet", e.target.value);
                                }}
                                class="form-control"
                                placeholder="Carpet area"
                              />
                            </div>

                            <div className="col-md-2">
                              <label>Monthly Maintainance</label>
                              <input
                                name="maitanence"
                                type="text"
                                value={newData.maitanence}
                                onChange={(e) => {
                                  handleTextChange(
                                    "maitanence",
                                    e.target.value
                                  );
                                }}
                                class="form-control"
                                placeholder="Monthly maintainance"
                              />
                            </div>
                            <div className="col-md-2">
                              <label>Total Floors</label>
                              <input
                                name="floor"
                                type="text"
                                value={newData.floor}
                                onChange={(e) => {
                                  handleTextChange("floor", e.target.value);
                                }}
                                class="form-control"
                                placeholder="Total floors"
                              />
                            </div>
                            <div className="col-md-2">
                              <label>Floor Number</label>
                              <input
                                name="floorNo"
                                type="text"
                                value={newData.floorNo}
                                onChange={(e) => {
                                  handleTextChange("floorNo", e.target.value);
                                }}
                                class="form-control"
                                placeholder="Floor number"
                              />
                            </div>
                            <div className="col-md-2">
                              <label>Facing</label>
                              <input
                                name="facing"
                                value={newData.facing}
                                onChange={(e) => {
                                  handleTextChange("facing", e.target.value);
                                }}
                                type="text"
                                class="form-control"
                                placeholder="Plot Facing*"
                              />
                            </div>
                          </div>
                        )}
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Property Title :</label>
                              <input
                                name="title"
                                value={newData.title}
                                onChange={(e) => {
                                  handleTextChange("title", e.target.value);
                                }}
                                class="form-control"
                              ></input>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Property Description :</label>
                              <textarea
                                required
                                value={newData.desc}
                                onChange={(e) => {
                                  handleTextChange("desc", e.target.value);
                                }}
                                name="discrition"
                                class="form-control"
                              ></textarea>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-sm-12">
                              <div class="col-sm-3">
                                <div class="form-group">
                                  <label>Bedrooms</label>
                                  <select
                                    value={newData.bedrooms}
                                    onChange={(e) => {
                                      handleTextChange(
                                        "bedrooms",
                                        e.target.value
                                      );
                                    }}
                                    id="basic"
                                    class="selectpicker show-tick form-control"
                                  >
                                    <option selected>Select...</option>
                                    <option value={"1"}>1 </option>
                                    <option value={"2"}>2</option>
                                    <option value={"3"}>3</option>
                                    <option value={"4"}>4</option>
                                    <option value={"5"}>5</option>
                                    <option value={"5+"}>5+</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="form-group">
                                  <label>Bathrooms</label>
                                  <select
                                    value={newData.bathrooms}
                                    onChange={(e) => {
                                      handleTextChange(
                                        "bathrooms",
                                        e.target.value
                                      );
                                    }}
                                    id="basic"
                                    class="selectpicker show-tick form-control"
                                  >
                                    <option selected>Select...</option>
                                    <option value={"1"}>1 </option>
                                    <option value={"2"}>2</option>
                                    <option value={"3"}>3</option>
                                    <option value={"4"}>4</option>
                                    <option value={"5"}>5</option>
                                    <option value={"5+"}>5+</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="form-group">
                                  <label>Furnished</label>
                                  <select
                                    value={newData.furnishing}
                                    onChange={(e) => {
                                      handleTextChange(
                                        "furnishing",
                                        e.target.value
                                      );
                                    }}
                                    id="basic"
                                    class="selectpicker show-tick form-control"
                                  >
                                    <option selected>Select...</option>
                                    <option value={"furnished"}>
                                      Furnished{" "}
                                    </option>
                                    <option value={"semi-furnished"}>
                                      Semi-furnished
                                    </option>
                                    <option value={"unfurnished"}>
                                      Unfurnished
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="form-group">
                                  <label>Construction Status</label>
                                  <select
                                    value={newData.constructionStatus}
                                    onChange={(e) => {
                                      handleTextChange(
                                        "constructionStatus",
                                        e.target.value
                                      );
                                    }}
                                    id="basic"
                                    class="selectpicker show-tick form-control"
                                  >
                                    <option selected> -Status- </option>
                                    <option value={"ready-to-move"}>
                                      Ready to move{" "}
                                    </option>
                                    <option value={"under-construction"}>
                                      Under construction
                                    </option>
                                    <option value={"just-new-launch"}>
                                      Just new launch
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className="nearby-label-main">Nearby</label>
                          </div>
                          <div className="row nearby">
                            <div className="col-md-3">
                              <div class="col">
                                <div class="checkbox-inline">
                                  <input
                                    value={"bank"}
                                    checked={checkChecked("nearby", "bank")}
                                    onChange={(e) =>
                                      handleCheckBoxChange(
                                        "nearby",
                                        e.target.value
                                      )
                                    }
                                    className="checkbox-inline-style"
                                    type="checkbox"
                                  />
                                  <label className="checkbox-label">Bank</label>
                                </div>
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"mall"}
                                      checked={checkChecked("nearby", "mall")}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />{" "}
                                    Mall
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"hospital"}
                                      checked={checkChecked(
                                        "nearby",
                                        "hospital"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />{" "}
                                    Hospital
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"airport"}
                                      checked={checkChecked(
                                        "nearby",
                                        "airport"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Airport
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            {/* </div> */}
                            <div class="col-md-3 padding-bottom-15">
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"bus-station"}
                                      checked={checkChecked(
                                        "nearby",
                                        "bus-station"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />{" "}
                                    Bus Station
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"theatre"}
                                      checked={checkChecked(
                                        "nearby",
                                        "theatre"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Theatre
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"railway-station"}
                                      checked={checkChecked(
                                        "nearby",
                                        "railway-station"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Railway Station
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"school"}
                                      checked={checkChecked("nearby", "school")}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "nearby",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    School
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>

                            <br />
                          </div>

                          <div>
                            <label className="nearby-label-main">
                              Facilities
                            </label>
                          </div>
                          <div className="row nearby">
                            <div className="col-md-3">
                              <div class="col">
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"tv"}
                                      checked={checkChecked("facilities", "tv")}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    TV
                                  </label>
                                </div>
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"ac"}
                                      checked={checkChecked("facilities", "ac")}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Ac
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"refrigerator"}
                                      checked={checkChecked(
                                        "facilities",
                                        "refrigerator"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Refrigerator
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"wifi"}
                                      checked={checkChecked(
                                        "facilities",
                                        "wifi"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Wifi
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            {/* </div> */}
                            <div class="col-md-3 padding-bottom-15">
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"power-backup"}
                                      checked={checkChecked(
                                        "facilities",
                                        "power-backup"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Power Backup
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"almirah"}
                                      checked={checkChecked(
                                        "facilities",
                                        "almirah"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Almirah
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"bedsheet"}
                                      checked={checkChecked(
                                        "facilities",
                                        "bedsheet"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Bedsheet
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"cctv"}
                                      checked={checkChecked(
                                        "facilities",
                                        "cctv"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    CCTV
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            <div class="col-md-3 padding-bottom-15">
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"security"}
                                      checked={checkChecked(
                                        "facilities",
                                        "security"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Security
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"pillow"}
                                      checked={checkChecked(
                                        "facilities",
                                        "pillow"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Pillow
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"lift"}
                                      checked={checkChecked(
                                        "facilities",
                                        "lift"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Lift
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"gym"}
                                      checked={checkChecked(
                                        "facilities",
                                        "gym"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Gym
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                            <div class="col-md-3 padding-bottom-15">
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"club"}
                                      checked={checkChecked(
                                        "facilities",
                                        "club"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Club
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"playground"}
                                      checked={checkChecked(
                                        "facilities",
                                        "playground"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Playground
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"cycle-track"}
                                      checked={checkChecked(
                                        "facilities",
                                        "cycle-track"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Cycletrack
                                  </label>
                                </div>
                                {/* </div> */}
                              </div>
                              <div class="col">
                                {/* <div class="form-group"> */}
                                <div class="checkbox-inline">
                                  <label className="checkbox-label">
                                    <input
                                      value={"park"}
                                      checked={checkChecked(
                                        "facilities",
                                        "park"
                                      )}
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          "facilities",
                                          e.target.value
                                        )
                                      }
                                      className="checkbox-inline-style"
                                      type="checkbox"
                                    />
                                    Park
                                  </label>
                                </div>
                                <div class="col">
                                  {/* <div class="form-group"> */}
                                  <div class="checkbox-inline">
                                    <label className="checkbox-label">
                                      <input
                                        value={"parking"}
                                        checked={checkChecked(
                                          "facilities",
                                          "parking"
                                        )}
                                        onChange={(e) =>
                                          handleCheckBoxChange(
                                            "facilities",
                                            e.target.value
                                          )
                                        }
                                        className="checkbox-inline-style"
                                        type="checkbox"
                                      />
                                      Parking
                                    </label>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    {/* <TabPanel>
                      <div class="tab-pane" id="step3">
                        <h4 class="info-text">Give us somme images ? </h4>
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="property-images">
                                Chose Images :
                              </label>
                              <input
                                class="form-control"
                                type="file"
                                id="property-images"
                                onChange={(e) => trySelectFiles(e)}
                                multiple
                                accept={".jpg,.png,.jpeg"}
                              />
                              <p class="help-block">
                                Select multiple images for your property (Max 10
                                images)
                              </p>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="property-video">
                                Property YouTube Video Embeded Url :
                              </label>

                              <input
                                class="form-control"
                                value=""
                                placeholder="http://www.youtube.com, http://vimeo.com"
                                name="youTubeUrl"
                                type="text"
                                value={newData.youTubeUrl}
                                onChange={(e) => {
                                  handleTextChange(
                                    "youTubeUrl",
                                    e.target.value
                                  );
                                }}
                              />
                              <p class="help-block">
                                Video url must be embeded and permitted by
                                YouTube
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel> */}

                    <TabPanel>
                      <div class="tab-pane" id="step4">
                        <h4 class="info-text"> Finished and Update </h4>
                        <div class="row">
                          <div class="col-sm-12"></div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                  <div className="wizard-footer">
                    <div className="pull-right">
                      {tabIndex < 2 ? (
                        <a
                          href="#"
                          onClick={() => setTabIndex((index) => index + 1)}
                          className="btn btn-primary mr-2"
                          data-toggle="tab"
                        >
                          Next
                        </a>
                      ) : null}
                      {tabIndex == 2 ? (
                        <button
                          className="btn btn-finish btn-primary"
                          id="finish"
                          name="finish"
                          type="submit"
                        >
                          Finish
                        </button>
                      ) : null}
                    </div>
                    {tabIndex > 0 ? (
                      <div className="pull-left">
                        <a
                          href="#"
                          onClick={() => setTabIndex((index) => index - 1)}
                          className="btn btn-primary mr-2"
                          data-toggle="tab"
                        >
                          Previous
                        </a>
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditPost;
