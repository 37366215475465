import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "new-assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Testimonial
                </h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    I was able to identify the ideal office space for my business with the aid of Near2Property.com. The team gave good support throughout the procedure, and the listings were correct.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/img/ravinder.jpeg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Ravinder Singh</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Near2Property.com has been a crucial ally for me as a property developer. Their online platform helped us efficiently interact with prospective customers and reach a larger audience.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "assets/img/ubberhome.png"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Ubber Parkland</h5>
                      {/* <label>Selling Agents</label> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Precious ipsum dolor sit amet consectetur adipisicing elit,
                    sed dos mod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad min veniam, quis nostrud Precious ips um
                    dolor sit amet, consecte
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "new-assets/img/testimonial/3.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>Adam Joseph</h5>
                      <label>Selling Agents</label>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Precious ipsum dolor sit amet consectetur adipisicing elit,
                    sed dos mod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad min veniam, quis nostrud Precious ips um
                    dolor sit amet, consecte
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-img">
                      <img
                        src={publicUrl + "new-assets/img/testimonial/4.jpg"}
                        alt="#"
                      />
                    </div>
                    <div className="ltn__testimoni-name-designation">
                      <h5>James Carter</h5>
                      <label>Selling Agents</label>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonial;
