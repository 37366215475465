import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RegisterComponent from "../../new-components/register";

const Register = () => {
  // const [fname, setFname] = useState("");
  // const [phone, setPhone] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [signupScreen, setSignupScreen] = useState(true);
  // const [otp, setOtp] = useState("");
  // const [loader, setLoader] = useState(false);
  // const [otpLoader, setOtpLoader] = useState(false);

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const user = useSelector(state => state.auth.user);

  // function validateEmail() {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (re.test(String(email).toLowerCase())) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   if (email) {
  //     if (!validateEmail()) {
  //       toast({
  //         text1: "Invalid Email Address!",
  //         position: "bottom",
  //         type: "error",
  //       });
  //       return;
  //     }
  //   }
  //   try {
  //     setLoader(true);
  //     const res = await RegisterService({
  //       fullName: fname,
  //       phone,
  //       email,
  //       password,
  //     });

  //     if (res.data.token) {
  //       setLoader(false);
  //       setSignupScreen(false);
  //       onSendOtp();
  //       toast("Please enter otp");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setLoader(false);
  //   }
  // };

  // const onSendOtp = async () => {
  //   setLoader(true);
  //   try {
  //     const otpRes = await sendOtp({ phone: phone });

  //     if (otpRes.data.Status === "Success") {
  //       localStorage.setItem("otpSessionId", otpRes.data.Details);
  //     }
  //     setLoader(false);
  //   } catch (err) {
  //     setLoader(false);
  //     let msg = err.message;

  //     if (msg == "Network Error") {
  //       toast(
  //         "Network Error.",
  //         "error",
  //         "Kindly check your interent connection."
  //       );
  //     }
  //   }
  // };
  // const onSubmitOtp = async (e) => {
  //   e.preventDefault();
  //   setOtpLoader(true);
  //   try {
  //     let sessionId = localStorage.getItem("otpSessionId");
  //     const res = await verifyOtp({
  //       otp,
  //       phone,
  //       sessionId,
  //     });

  //     if (res.data.Status === "Success") {
  //       toast(res.data.Details);
  //       setOtpLoader(false);
  //       navigate("/login", { replace: true });
  //       // document.getElementById('loginLabel').click();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     setOtpLoader(false);
  //   }
  // };

  return (
    <RegisterComponent />

  );
};

export default Register;
