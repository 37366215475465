import React, { useEffect, useState } from "react";
import Navbar from "../../new-components/global-components/navbar-v3";
import BannerV5 from "../../new-components/section-components/banner-v5";
import ProductListing from "../../new-components/section-components/product-listing";
import Featuresv1 from "../../new-components/section-components/features-v1";
import ProSlider from "../../new-components/section-components/product-slider-v1";
import Apartment from "../../new-components/section-components/apartment-v1";
import VideoV1 from "../../new-components/section-components/video-v1";
import Category from "../../new-components/section-components/category-v1";
import Testimonial from "../../new-components/section-components/testimonial-v1";
import BlogSlider from "../../new-components/blog-components/blog-slider-v1";
import CallToActionV1 from "../../new-components/section-components/call-to-action-v1";
import Footer from "../../new-components/global-components/footer";
import { useDispatch, useSelector } from "react-redux";
import { getProperties, getFilteredProperties } from "../../redux/actions/post";

import "./style.css";

const Home = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    onGetAllProperties();
  }, []);

  const onGetAllProperties = () => {
    try {
      navigator.geolocation.getCurrentPosition(function (position) {
        if (position.coords) {
          let queryParams = `?showMyAds=${false}&&longitude=${position.coords.longitude
            }&&latitude=${position.coords.latitude}&page=${page}`;
          dispatch(getProperties(queryParams, (e) => { }));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <BannerV5 />
      <ProductListing />
      <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      {/* <ProSlider /> */}
      {/* <Apartment /> */}
      {/* <VideoV1 /> */}
      <Category />
      {/* <Testimonial /> */}
      {/* <BlogSlider customClass="section-subtitle-2" /> */}
      {/* <div customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" > */}
      {/* <img src="/assets/img/playstore.png" height={100} width={100} /> */}
      {/* </div> */}
      <CallToActionV1 />

      <Footer />
    </div>
  );
  // return (
  //   <div>
  //     <Header />

  //     <div>
  //       <NavBar location={"home"} />
  //       {/* <div class="item"> */}

  //       <img src="assets/img/slide1/slider-image-4.jpg" alt="Mirror Edge" />

  //       <div class="container slider-content">
  //         <div class="row">
  //           <div class="search-form wow pulse" data-wow-delay="0.8s">
  //             <form
  //               action=""
  //               onSubmit={(e) => onSearch(e)}
  //               class=" form-inline"
  //             >
  //               <div>
  //                 {/* <!-- Nav tabs --> */}
  //                 <ul class="nav nav-tabs" role="tablist">
  //                   <li role="presentation" class="active">
  //                     <a
  //                       href="#home"
  //                       aria-controls="home"
  //                       role="tab"
  //                       data-toggle="tab"
  //                       onClick={() => setData({ ...data, lookingFor: "buy" })}
  //                     >
  //                       Buy
  //                     </a>
  //                   </li>
  //                   <li role="presentation">
  //                     <a
  //                       href="#profile"
  //                       aria-controls="profile"
  //                       onClick={() => setData({ ...data, lookingFor: "rent" })}
  //                       role="tab"
  //                       data-toggle="tab"
  //                     >
  //                       Rent
  //                     </a>
  //                   </li>
  //                   <li role="presentation">
  //                     <a
  //                       href="#messages"
  //                       aria-controls="messages"
  //                       onClick={() => setData({ ...data, lookingFor: "pg" })}
  //                       role="tab"
  //                       data-toggle="tab"
  //                     >
  //                       PG
  //                     </a>
  //                   </li>
  //                   <li role="presentation">
  //                     <a
  //                       href="#settings"
  //                       onClick={() =>
  //                         setData({ ...data, lookingFor: "commercial" })
  //                       }
  //                       aria-controls="settings"
  //                       role="tab"
  //                       data-toggle="tab"
  //                     >
  //                       Commercial
  //                     </a>
  //                   </li>
  //                   <li role="presentation">
  //                     <a
  //                       href="#settings"
  //                       aria-controls="settings"
  //                       onClick={() =>
  //                         setData({ ...data, lookingFor: "residential" })
  //                       }
  //                       role="tab"
  //                       data-toggle="tab"
  //                     >
  //                       Residential
  //                     </a>
  //                   </li>
  //                 </ul>

  //                 {/* <!-- Tab panes --> */}
  //                 <div class="tab-content">
  //                   <div role="tabpanel" class="tab-pane active" id="home">
  //                     ...
  //                   </div>
  //                   <div role="tabpanel" class="tab-pane" id="profile">
  //                     ...
  //                   </div>
  //                   <div role="tabpanel" class="tab-pane" id="messages">
  //                     ...
  //                   </div>
  //                   <div role="tabpanel" class="tab-pane" id="settings">
  //                     ...
  //                   </div>
  //                 </div>
  //               </div>
  //               {/* <button class="btn  toggle-btn" type="button"><i class="fa fa-bars"></i></button> */}

  //               <div class="form-group">
  //                 <input
  //                   type="text"
  //                   class="form-control"
  //                   value={data.search}
  //                   onChange={(e) =>
  //                     setData({ ...data, search: e.target.value })
  //                   }
  //                   placeholder="Key word"
  //                 />
  //               </div>
  //               {/* <div class="form-group">
  //                                       <select id="lunchBegins" class="selectpicker show-tick form-control" data-live-search="true" data-live-search-style="begins" title="Select your city">

  //                                           <option>New york, CA</option>
  //                                           <option>Paris</option>
  //                                           <option>Casablanca</option>
  //                                           <option>Tokyo</option>
  //                                           <option>Marraekch</option>
  //                                           <option>kyoto , shibua</option>
  //                                       </select>
  //                                   </div> */}
  //               <div class="form-group">
  //                 <select
  //                   value={data.postedBy}
  //                   onChange={(e) =>
  //                     setData({ ...data, postedBy: e.target.value })
  //                   }
  //                   id="basic"
  //                   class="selectpicker show-tick form-control"
  //                 >
  //                   <option> -Posted By- </option>
  //                   <option value={"owner"}>Owner </option>
  //                   <option value={"builder"}>Builder</option>
  //                   <option value={"dealer"}>Dealer</option>
  //                 </select>
  //               </div>
  //               <div class="form-group">
  //                 <select
  //                   value={data.lookingTo}
  //                   onChange={(e) =>
  //                     setData({ ...data, lookingTo: e.target.value })
  //                   }
  //                   id="basic"
  //                   class="selectpicker show-tick form-control"
  //                 >
  //                   <option> -Looking To?- </option>
  //                   <option value={"apartment"}>Apartment </option>
  //                   <option value={"guest-house"}>Guest House</option>
  //                   <option value={"shop-office-booth"}>
  //                     Shop-Office-Booth
  //                   </option>
  //                   <option value={"independent-house"}>
  //                     Independent Hosue{" "}
  //                   </option>
  //                   <option value={"builder-floor"}>Builder Floor </option>
  //                   <option value={"farm-house"}>Farm Hosue</option>
  //                   <option value={"plot"}>Plot-Land</option>
  //                   <option value={"PG"}>PG</option>
  //                 </select>
  //               </div>
  //               <div class="form-group">
  //                 <select
  //                   value={range.index}
  //                   onChange={(e) => handleRange(e.target.value)}
  //                   id="basic"
  //                   class="selectpicker show-tick form-control"
  //                 >
  //                   <option> -Price Range- </option>
  //                   <option value="0">0-10L </option>
  //                   <option value="1">10L-25L </option>
  //                   <option value="2">26L-50L</option>
  //                   <option value="3">50-75L</option>
  //                   <option value="4">75L-1Cr</option>
  //                   <option value="5">1Cr+</option>
  //                 </select>
  //               </div>
  //               {/* <div class="form-group mar-r-20">
  //                                       <label for="price-range">Price range ($):</label>
  //                                       <input type="text" class="span2" value="" data-slider-min="0"
  //                                           data-slider-max="600" data-slider-step="5"
  //                                           data-slider-value="[0,450]" id="price-range" /><br />
  //                                       <b class="pull-left color">2000$</b>
  //                                       <b class="pull-right color">100000$</b>
  //                                   </div> */}
  //               <button class="btn search-btn" type="submit">
  //                 <i class="fa fa-search"></i>
  //               </button>

  //               <div style={{ display: "none" }} class="search-toggle">
  //                 <div class="search-row">
  //                   <div class="form-group mar-r-20">
  //                     <label for="price-range">Price range ($):</label>
  //                     <input
  //                       type="text"
  //                       class="span2"
  //                       value=""
  //                       data-slider-min="0"
  //                       data-slider-max="600"
  //                       data-slider-step="5"
  //                       data-slider-value="[0,450]"
  //                       id="price-range"
  //                     />
  //                     <br />
  //                     <b class="pull-left color">2000$</b>
  //                     <b class="pull-right color">100000$</b>
  //                   </div>

  //                   <div class="form-group mar-l-20">
  //                     <label for="property-geo">Property geo (m2) :</label>
  //                     <input
  //                       type="text"
  //                       class="span2"
  //                       value=""
  //                       data-slider-min="0"
  //                       data-slider-max="600"
  //                       data-slider-step="5"
  //                       data-slider-value="[50,450]"
  //                       id="property-geo"
  //                     />
  //                     <br />
  //                     <b class="pull-left color">40m</b>
  //                     <b class="pull-right color">12000m</b>
  //                   </div>
  //                 </div>

  //                 <div class="search-row">
  //                   <div class="form-group mar-r-20">
  //                     <label for="price-range">Min baths :</label>
  //                     <input
  //                       type="text"
  //                       class="span2"
  //                       value=""
  //                       data-slider-min="0"
  //                       data-slider-max="600"
  //                       data-slider-step="5"
  //                       data-slider-value="[250,450]"
  //                       id="min-baths"
  //                     />
  //                     <br />
  //                     <b class="pull-left color">1</b>
  //                     <b class="pull-right color">120</b>
  //                   </div>

  //                   <div class="form-group mar-l-20">
  //                     <label for="property-geo">Min bed :</label>
  //                     <input
  //                       type="text"
  //                       class="span2"
  //                       value=""
  //                       data-slider-min="0"
  //                       data-slider-max="600"
  //                       data-slider-step="5"
  //                       data-slider-value="[250,450]"
  //                       id="min-bed"
  //                     />
  //                     <br />
  //                     <b class="pull-left color">1</b>
  //                     <b class="pull-right color">120</b>
  //                   </div>
  //                 </div>
  //                 <br />
  //                 <div class="search-row">
  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Fire Place(3100)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Dual Sinks(500)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Hurricane Shutters(99)
  //                       </label>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div class="search-row">
  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Swimming Pool(1190)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> 2 Stories(4600)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Emergency Exit(200)
  //                       </label>
  //                     </div>
  //                   </div>
  //                 </div>

  //                 <div class="search-row">
  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Laundry Room(10073)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> Jog Path(1503)
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div class="form-group">
  //                     <div class="checkbox">
  //                       <label>
  //                         <input type="checkbox" /> 26' Ceilings(1200)
  //                       </label>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //       </div>
  //       {/* </div> */}
  //     </div>

  //     <div
  //       class="content-area recent-property"
  //       style={{ paddingBottom: 60, backgroundColor: "rgb(252, 252, 252)" }}
  //     >
  //       <div class="container">
  //         <div class="row">
  //           <div class="col-md-12 properties-page">
  //             {properties &&
  //               properties.length > 0 &&
  //               properties?.map((i) => {
  //                 return renderProperties(i);
  //               })}
  //             {properties && properties.length > 0 && (
  //               <div class="col-md-12">
  //                 <div class="pull-right">
  //                   <div class="pagination">
  //                     <ul>
  //                       {page > 1 ? (
  //                         <li>
  //                           <a onClick={() => setPage((page) => page - 1)}>
  //                             Prev
  //                           </a>
  //                         </li>
  //                       ) : null}
  //                       <li>
  //                         <a onClick={() => setPage(page)}>{page}</a>
  //                       </li>
  //                       {page * 12 < totalProperties ? (
  //                         <li>
  //                           <a onClick={() => setPage((page) => page + 1)}>
  //                             {page + 1}
  //                           </a>
  //                         </li>
  //                       ) : null}
  //                       {(page + 2) * 12 < totalProperties ? (
  //                         <li>
  //                           <a onClick={() => setPage((page) => page + 2)}>
  //                             {page + 2}
  //                           </a>
  //                         </li>
  //                       ) : null}
  //                       {(page + 3) * 12 < totalProperties ? (
  //                         <li>
  //                           <a onClick={() => setPage((page) => page + 3)}>
  //                             {page + 3}
  //                           </a>
  //                         </li>
  //                       ) : null}
  //                       {page * 12 < totalProperties ? (
  //                         <li>
  //                           <a onClick={() => setPage((page) => page + 1)}>
  //                             Next
  //                           </a>
  //                         </li>
  //                       ) : null}
  //                     </ul>
  //                   </div>
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <Footer />
  //   </div>
  // );
};

export default Home;
