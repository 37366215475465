import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { addNewPost } from "../../redux/actions/post";
import { useNavigate } from "react-router-dom";
// import { GoogleComponent } from "react-google-location"; 
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoaderSvg from "../../assets/img/loader.svg";
import { validateField, unValidateField } from "../../helpers/";
import Autocomplete from "react-google-autocomplete";
import { API_KEY } from '../../config/axiosConfig';
// const API_KEY = "AIzaSyA9bI8tKR9gOt8-QHx02ZdhELpCujPKXvg";

function AddListing() {
  const $ = window.$;

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    lookingFor: "",
    propertyKind: "",
    propertyType: "",
    postedBy: "",
    city: "",
    locality: "",
    mobile: "",
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
    price: "",
    builtUp: "",
    carpet: "",
    maitanence: "",
    floor: "",
    floorNo: "",
    facing: "",
    bathrooms: "",
    bedrooms: "",
    furnishing: "",
    constructionStatus: "",
    title: "",
    plotArea: "",
    desc: "",
    facilities: [],
    nearby: [],
    propertyImages: [],
    locLat: "",
    locLng: "",
    cityLng: "",
    cityLat: "",
    garage: "",
    sqyrd: "",
    sqmtr: ""
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const propertyTypeRef = useRef();
  const propertyKindRef = useRef();
  const postedByRef = useRef();
  const lookingForRef = useRef();
  const furnishingRef = useRef();
  const constructionStatusRef = useRef();
  const facingRef = useRef();
  const bedroomsRef = useRef();
  const bathroomsRef = useRef();
  const floorsRef = useRef();

  const handleCheckBoxChange = (name, val) => {
    let dataArr = [...data[name]];
    let indexOfItem = dataArr.indexOf(val);
    if (indexOfItem == -1) {
      dataArr.push(val);
    } else {
      dataArr.splice(indexOfItem, 1);
    }
    setData({ ...data, [name]: dataArr });
  };
  const trySelectFiles = (e) => {
    if (e.target.files.length < 2) {
      alert("Minimum 2 images are required!");
      setData({ ...data, propertyImages: [] });
      return;
    }
    if (e.target.files.length > 10) {
      alert("Only 10 files are allowed max.");
      setData({ ...data, propertyImages: [] });
      return;
    }
    setData({ ...data, propertyImages: e.target.files });
  };

  const handleTextChange = (name, val) => {
    setData({ ...data, [name]: val });
  };

  function getLat(e) {
    if (e) {
      return e.lat();
    }
    return false;
  }

  function getLng(e) {
    if (e) {
      return e.lng();
    }
    return false;
  }
  function checkChecked(name, val) {
    return data[name].indexOf(val) != -1;
  }
  const handleLocChange = (e) => {
    setData((data) => {
      return {
        ...data,
        locality: e.formatted_address,
        locLat: getLat(e.geometry.location),
        locLng: getLng(e.geometry.location),
      }
    })
  }
  const handleCityChange = (e) => {

    setData((data) => {
      return {
        ...data,
        city: e.formatted_address,
        cityLat: getLat(e.geometry.location),
        cityLng: getLng(e.geometry.location),
      }
    });
  }
  const handlePropertyTypeChange = () => {
    setData((data) => {
      return {
        ...data,
        propertyType: $(propertyTypeRef.current).val()
      }
    });
  }
  useEffect(() => {
    $(propertyTypeRef.current).niceSelect(); //initializing nice select
    $(propertyTypeRef.current).change(handlePropertyTypeChange)
  }, [])

  function validatePost(postDataSubmit) {
    let validate = {};

    for (let key in postDataSubmit) {
      if ((!postDataSubmit[key]) && (key == 'title' || key == 'mobile' || key == 'desc' || key == 'cityName' || key == 'locName' || key == 'price' || key == 'propertyType' || key == 'lookingFor' || key == 'propertyKind' || key == 'postedBy') || (key == 'propertyImages' && postDataSubmit['propertyImages'].length < 2)) {
        try {
          validateField(key)
          validate[key] = false;
        }
        catch (e) { }
      }
      else if ((postDataSubmit[key]) && (key == 'title' || key == 'mobile' || key == 'desc' || key == 'cityName' || key == 'locName' || key == 'price' || key == 'propertyType' || key == 'lookingFor' || key == 'propertyKind' || key == 'postedBy') || (key == 'propertyImages' && postDataSubmit['propertyImages'].length >= 2)) {

        try {
          unValidateField(key)
          validate[key] = true;
        }
        catch (e) { }

      }
    }
    return Object.values(validate).some((val, idx) => (val == false)) ? false : true;
  }
  const onSubmit = async (e) => {
    e.preventDefault();

    // if (String(data.title).trim() === "") {
    //   validateField("title", "Title field is required");
    //   validate = false;
    // }

    // if (String(data.mobile).trim() === "") {
    //   validateField("mobile", "Mobile No. field is required");
    //   validate = false;
    // }

    // if (String(data.desc).trim() === "") {
    //   validateField("desc", "Description field is required");
    //   validate = false;
    // }
    // if (String(data.city).trim() === "") {
    //   validateField("city", "City field is required");
    //   validate = false;
    // }

    // if (String(data.locality).trim() === "") {
    //   validateField("locality", "Locality field is required");
    //   validate = false;
    // }
    // if (String(data.price).trim() === "") {
    //   validateField("price", "Price field is required");
    //   validate = false;
    // }

    // if (!String($(propertyTypeRef.current).val())) {
    //   validateField("propertyType", "Property Type field is required");
    //   validate = false;
    // }
    // if (String(data.lookingFor).trim() === "") {
    //   validateField("lookingFor", "Looking For field is required");
    //   validate = false;
    // }
    // if (String(data.propertyKind).trim() === "") {
    //   validateField("propertyKind", "Property Kind field is required");
    //   validate = false;
    // }

    // if (String(data.postedBy).trim() === "") {
    //   validateField("postedBy", "Posted By field is required");
    //   validate = false;
    // }
    let postDataSubmit = {
      ...data,
      lookingFor: $(lookingForRef.current).val(),
      propertyKind: $(propertyKindRef.current).val(),
      propertyType: $(propertyTypeRef.current).val(),
      postedBy: $(postedByRef.current).val(),
      furnishing: $(furnishingRef.current).val(),
      constructionStatus: $(constructionStatusRef.current).val(),
      facing: $(facingRef.current).val(),
      bathrooms: $(bathroomsRef.current).val(),
      bedrooms: $(bedroomsRef.current).val(),
      floor: $(floorsRef.current).val(),
      // locality: JSON.stringify({
      //   type: "Point",
      //   coordinates: [parseFloat(data.locLng), parseFloat(data.locLat)],
      // }),
      city: JSON.stringify({
        type: "Point",
        coordinates: [parseFloat(data.cityLng), parseFloat(data.cityLat)],
      }),
      cityName: data.city,
      // locName: data.locality,
      propertyImages: [...data.propertyImages],
    };

    let validate = validatePost(postDataSubmit);
    if (!validate) {
      toast.error("Please fill required fields");
      return;
    }
    try {

      setLoader(true);

      const fd = new FormData();


      for (let field in postDataSubmit) {
        if (
          Array.isArray(postDataSubmit[field]) &&
          postDataSubmit[field].length > 0
        ) {
          postDataSubmit[field].forEach((i, k) => {
            fd.append(field, postDataSubmit[field][k]);
          });
        } else {
          fd.append(field, postDataSubmit[field]);
        }
      }
      dispatch(
        addNewPost(fd, (res) => {
          if (res.status) {
            toast("Property Submitted Successfully!");
            setLoader(false);
            navigate('/')
          }
          else {
            setLoader(false);
            toast.error("Please reload page and try again!");
          }
        })
      );
    } catch (err) {
      setLoader(false);

      console.log(err);
    }
  };

  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <form onSubmit={onSubmit}>
                <h2>1. Description</h2>
                <p>
                  <small>
                    These fields are mandatory: Title, Property Media
                  </small>
                </p>
                <h6>Property Description</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="title"
                        placeholder="*Property Title (mandatory)"
                        value={data.title}
                        onChange={(e) => {
                          handleTextChange("title", e.target.value);
                        }}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="mobile"
                        placeholder="*Mobile No. (mandatory)"
                        value={data.mobile}
                        onChange={(e) => {
                          handleTextChange("mobile", e.target.value);
                        }}
                      />
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="desc"
                        placeholder="Description"
                        value={data.desc}
                        onChange={(e) => {
                          handleTextChange("desc", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h6>Select Categories</h6>
                <div className="row">
                  <div className="col-lg-3 col-md-6 ">
                    <div className="input-item">
                      <select
                        className="nice-select"
                        name="propertyType"
                        ref={propertyTypeRef}
                        id="propertyType"
                      >
                        <option value="">Select Property Type...</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Condos">Condos</option>
                        <option value="Cabin">Cabin</option>
                        <option value="Duplex">Duplex</option>
                        <option value="Farm-House">Farm House</option>
                        <option value="House">House</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Plot-Land">Plot/Land</option>
                        <option value="Mall">Mall</option>
                        <option value="Office">Office</option>
                        <option value="School">School</option>
                        <option value="Villa">Villa</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="input-item">
                      <select
                        className="nice-select lookingFor"
                        // id="lookingFor"
                        ref={lookingForRef}
                        name="lookingFor"
                      >
                        <option value="">Select Looking For...</option>
                        <option value="sell">Sell</option>
                        <option value="rent">Rent</option>
                        <option value="pg">PG</option>
                        <option value="lease">Lease</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="input-item">
                      <select className="nice-select" name="propertyKind" ref={propertyKindRef}>
                        <option value="">Select Property Kind...</option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Industrial">Industrial</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="input-item">
                      <select className="nice-select" ref={postedByRef} name="postedBy">
                        <option value="">Select Posted By...</option>
                        <option value="Builder">Builder</option>
                        <option value="Owner">Owner</option>
                        <option value="Dealer">Dealer</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name">
                      <input
                        type="text"
                        name="price"
                        placeholder="Price in Rs.* (monthly for PG/Rent)"
                        value={data.price}
                        onChange={(e) => {
                          handleTextChange("price", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Before Price Label (ex: from)"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Yearly Tax Rate"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Homeowners Association Fee(monthly)"
                      />
                    </div>
                  </div> */}
                </div>

                <h2>2. Media</h2>
                <h6>Listing Media</h6>
                <div className="input-item">
                  <input
                    type="file"
                    name="propertyImages"
                    className="btn theme-btn-3 mb-10"
                    id="property-images"
                    onChange={(e) => trySelectFiles(e)}
                    multiple
                    accept={".jpg,.png,.jpeg"}
                  />
                </div>

                <p>
                  <small>
                    * At least 2 images is required for a valid
                    submission. Minimum size is 500/500px.
                  </small>
                  {/* <br />
                  <small>* PDF files upload supported as well.</small>
                  <br /> */}
                  <small>* Images might take longer to be processed.</small>
                </p>
                <h6>Video (Optional)</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item">
                      <input
                        type="text"
                        placeholder="http://www.youtube.com"
                        name="youTubeUrl"
                        value={data.youTubeUrl}
                        onChange={(e) => {
                          handleTextChange("youTubeUrl", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Embed Video ID"
                      />
                    </div>
                  </div> */}
                </div>
                {/* <h6>Virtual Tour</h6>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="ltn__message"
                    placeholder="Virtual Tour:"
                    value={""}
                  />
                </div> */}
                <h2>3. Location</h2>
                <h6>Listing Location</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {/* <GoogleComponent
                        apiKey={API_KEY}
                        language={"en"}
                        country={"country:in"}
                        placeholder="Locality"
                        coordinates={true}
                        onChange={(e) => {
                          setData({
                            ...data,
                            locality: e.place,
                            locLat: getLat(e.coordinates),
                            locLng: getLng(e.coordinates),
                          });
                        }}
                      /> */}
                      <Autocomplete
                        apiKey={API_KEY}
                        language='en'
                        type="text"
                        name='cityName'
                        placeholder="City"
                        options={{
                          componentRestrictions: { country: "in" },
                        }}
                        onPlaceSelected={handleCityChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Country"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="County / State"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                     
                      <Autocomplete
                        apiKey={API_KEY}
                        language='en'
                        placeholder="Locality"
                        type="text"
                        name='locName'
                        options={{
                          componentRestrictions: { country: "in" },
                          types: ['(regions)']
                        }}
                        onPlaceSelected={handleLocChange}
                      />
                    </div> */}
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="locality"
                        placeholder="Locality"
                        autoComplete="new-password"
                        onChange={(e) => {
                          handleTextChange("locality", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Neighborhood"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input type="text" name="ltn__name" placeholder="Zip" />
                    </div>
                  </div> */}
                  {/* <div className="col-lg-12">
                    <div className="property-details-google-map mb-60">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd"
                        width="100%"
                        height="100%"
                        frameBorder={0}
                        allowFullScreen
                        aria-hidden="false"
                        tabIndex={0}
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Latitude (for Google Maps)"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Longitude (for Google Maps)"
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <label className="checkbox-item">
                      Enable Google Street View
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Google Street View - Camera Angle (value from 0 to 360)"
                      />
                    </div>
                  </div> */}
                </div>


                <h2>4. Details</h2>
                <h6>Listing Details</h6>

                <div className="row">


                  <div className={`col-md-6 ${data.propertyType !== "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Area in Sq Yard"
                        value={data.sqyrd}
                        onChange={(e) => {
                          handleTextChange("sqyrd", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType !== "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Area in Sq Meters"
                        value={data.sqmtr}
                        onChange={(e) => {
                          handleTextChange("sqmtr", e.target.value);
                        }}
                      />
                    </div>
                  </div>


                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Carpet Area in ft (*only numbers)"
                        value={data.carpet}
                        onChange={(e) => {
                          handleTextChange("carpet", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Built Up Area in ft2 (*only numbers)"
                        value={data.builtUp}
                        onChange={(e) => {
                          handleTextChange("builtUp", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <select className="nice-select" ref={bedroomsRef}>
                        <option value="">Select Bedrooms...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="5+">5+</option>
                      </select>

                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <select className="nice-select" ref={bathroomsRef}>
                        <option value="">Select Bedrooms...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="5+">5+</option>
                      </select>

                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <div className="input-item">
                        <select className="nice-select" ref={facingRef}>
                          <option value="">Select Facing...</option>
                          <option value="East">East</option>
                          <option value="West">West</option>
                          <option value="North">North</option>
                          <option value="South">South</option>
                          <option value="Dealer">North East</option>
                          <option value="North-East">North West</option>
                          <option value="South-East">South East</option>
                          <option value="South-West">South West</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <select className="nice-select" ref={floorsRef}>
                        <option value="">Select Floors...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="10+">10+</option>
                      </select>


                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="floorNo"
                        placeholder="Floor No."
                        value={data.floorNo}
                        onChange={(e) => {
                          handleTextChange("floorNo", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="maitanence"
                        placeholder="Monthly Maitanence"
                        value={data.maitanence}
                        onChange={(e) => {
                          handleTextChange("maitanence", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name">
                      <select
                        className="nice-select"
                        name="furnishing"
                        placeholder="Furnished"
                        ref={furnishingRef}
                      >
                        <option value="">Select Furnishing Status...</option>
                        <option value="Furnished">Furnished</option>
                        <option value="Semi-Furnished">Semi-Furnished</option>
                        <option value="Unfurnished">Unfurnished</option>
                      </select>
                    </div>
                  </div>
                  <div className={`col-md-6 ${data.propertyType == "Plot-Land" && 'd-none'}`}>
                    <div className="input-item input-item-name">
                      <select
                        className="nice-select"
                        ref={constructionStatusRef}
                      >
                        <option value="">Select Status...</option>
                        <option value={"ready-to-move"}>Ready to move</option>
                        <option value={"under-construction"}>
                          Under construction
                        </option>
                        <option value={"just-new-launch"}>
                          Just new launch
                        </option>
                        <option value={"resale"}>Resale</option>
                      </select>
                    </div>
                  </div>


                </div>


                {/* <h6>Select Energy Class</h6> */}
                {/* <div className="row"> */}
                {/* <div className="col-md-6">
                    <div className="input-item">
                      <select className="nice-select">
                        <option>Select Energy Class (EU regulation)</option>
                        <option>A+</option>
                        <option>A</option>
                        <option>B</option>
                        <option>C</option>
                        <option>D</option>
                        <option>E</option>
                      </select>
                    </div>
                  </div> */}
                {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Energy Index in kWh/m2a"
                      />
                    </div>
                  </div> */}
                {/* </div> */}
                <h2>5. Nearby</h2>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Bank
                      <input
                        type="checkbox"
                        value={"bank"}
                        checked={checkChecked("nearby", "bank")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Mall
                      <input
                        type="checkbox"
                        value={"mall"}
                        checked={checkChecked("nearby", "mall")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Hospital{" "}
                      <input
                        type="checkbox"
                        value={"hospital"}
                        checked={checkChecked("nearby", "hospital")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Airport
                      <input
                        type="checkbox"
                        value={"airport"}
                        checked={checkChecked("nearby", "airport")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Bus Station
                      <input
                        type="checkbox"
                        value={"bus-station"}
                        checked={checkChecked("nearby", "bus-station")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Theatre
                      <input
                        type="checkbox"
                        value={"theatre"}
                        checked={checkChecked("nearby", "theatre")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Railway Station{" "}
                      <input
                        type="checkbox"
                        value={"railway-station"}
                        checked={checkChecked("nearby", "railway-station")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      School
                      <input
                        type="checkbox"
                        value={"school"}
                        checked={checkChecked("nearby", "school")}
                        onChange={(e) =>
                          handleCheckBoxChange("nearby", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>

                <h2>7. Amenities</h2>
                <h6>Amenities and Features</h6>
                {/* <h6>Interior Details</h6> */}
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Equipped Kitchen
                      <input
                        type="checkbox"
                        value={"kitchen"}
                        checked={checkChecked("facilities", "kitchen")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Gym
                      <input
                        type="checkbox"
                        value={"gym"}
                        checked={checkChecked("facilities", "gym")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Laundry
                      <input
                        type="checkbox"
                        value={"laundry"}
                        checked={checkChecked("facilities", "laundry")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Media Room
                      <input
                        type="checkbox"
                        value={"media-room"}
                        checked={checkChecked("facilities", "media-room")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <h6 className="mt-20">Outdoor Details</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Back yard
                      <input
                        type="checkbox"
                        value={"backYard"}
                        checked={checkChecked("facilities", "backYard")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Front yard
                      <input
                        type="checkbox"
                        value={"frontYard"}
                        checked={checkChecked("facilities", "frontYard")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Garage Attached
                      <input
                        type="checkbox"
                        value={"garage"}
                        checked={checkChecked("facilities", "garage")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Hot Bath
                      <input
                        type="checkbox"
                        value={"hot-bath"}
                        checked={checkChecked("facilities", "hot-bath")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Pool
                      <input
                        type="checkbox"
                        value={"pool"}
                        checked={checkChecked("facilities", "pool")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <h6 className="mt-20">Utilities</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Central Air
                      <input
                        type="checkbox"
                        value={"centeral-air"}
                        checked={checkChecked("facilities", "centeral-air")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Electricity
                      <input
                        type="checkbox"
                        value={"electricity"}
                        checked={checkChecked("facilities", "electricity")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Heating
                      <input
                        type="checkbox"
                        value={"heating"}
                        checked={checkChecked("facilities", "heating")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Natural Gas
                      <input
                        type="checkbox"
                        value={"gas"}
                        checked={checkChecked("facilities", "gas")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Ventilation
                      <input
                        type="checkbox"
                        value={"ventilation"}
                        checked={checkChecked("facilities", "ventilation")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Water
                      <input
                        type="checkbox"
                        value={"water"}
                        checked={checkChecked("facilities", "water")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <h6 className="mt-20">Other Features</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      CCTV
                      <input
                        type="checkbox"
                        value={"cctv"}
                        checked={checkChecked("facilities", "cctv")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Elevator
                      <input
                        type="checkbox"
                        value={"elevator"}
                        checked={checkChecked("facilities", "elevator")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Fireplace
                      <input
                        type="checkbox"
                        value={"fire"}
                        checked={checkChecked("facilities", "fire")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Smoke detectors
                      <input
                        type="checkbox"
                        value={"smoke-detector"}
                        checked={checkChecked("facilities", "smoke-detector")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  {/* <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      Washer and dryer
                      <input
                        type="checkbox"
                        value={"washer"}
                        checked={checkChecked("facilities", "washer")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div> */}
                  <div className="col-lg-4 col-md-6">
                    <label className="checkbox-item">
                      WiFi
                      <input
                        type="checkbox"
                        value={"wifi"}
                        checked={checkChecked("facilities", "wifi")}
                        onChange={(e) =>
                          handleCheckBoxChange("facilities", e.target.value)
                        }
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
                <div className="alert alert-warning d-none" role="alert">
                  Please note that the date and time you requested may not be
                  available. We will contact you to confirm your actual
                  appointment details.
                </div>
                <div className="btn-wrapper text-center mt-30">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? (
                      <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                    ) : (
                      "Submit Property"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddListing;
