import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { RegisterService, sendOtp, verifyOtp } from "../../config/user";
import { toast } from "react-toastify";
import LoaderSvg from "../../assets/img/loader.svg";
import { validateField } from "../../../src/helpers";

function Register() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupScreen, setSignupScreen] = useState(true);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const user = useSelector(state => state.auth.user);

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let alphaNumericRegex = /(?:[A-Za-z].*?\d|\d.*?[A-Za-z])/;

    if (fname.trim() === "") {
      validateField("fname", "Full Name field is required");
      validate = false;
    }
    if (phone.trim() === "") {
      validateField("phone", "Phone field is required");
      validate = false;
    }

    if (phone.trim().length != 10) {
      validateField("phone", "Phone Number should be 10 numbers only");
      validate = false;
    }
    if (email.trim() === "") {
      validateField("email", "Email field is required");
      validate = false;
    }

    if (email.trim() && !emailRegex.test(email.trim())) {
      validateField("email", "Invalid Email Address!");
      validate = false;
    }

    if (password.trim() === "") {
      validateField("password", "Password field is required");
      validate = false;
    }

    if (password.trim().length < 8) {
      validateField("password", "Password should be minimum 8 characters");
      validate = false;
    }
    if (!alphaNumericRegex.test(password.trim())) {
      validateField("password", "Password must contain a number and alphabet!");
      validate = false;
    }
    if (confirmPassword.trim() === "") {
      validateField("confirmPassword", "Confirm Password field is required");
      validate = false;
    }

    if (password.trim() !== confirmPassword.trim()) {
      validateField(
        "confirmPassword",
        "Confirm password does'nt match with password!"
      );
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }
    try {
      setLoader(true);
      const res = await RegisterService({
        fullName: fname,
        phone,
        email,
        password,
      });

      if (res.data.token) {
        setLoader(false);
        setSignupScreen(false);
        onSendOtp();
        toast("Please enter otp");
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const onSendOtp = async () => {
    setLoader(true);
    try {
      const otpRes = await sendOtp({ phone: phone });

      if (otpRes.data.Status === "Success") {
        localStorage.setItem("otpSessionId", otpRes.data.Details);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      let msg = err.message;

      if (msg == "Network Error") {
        toast(
          "Network Error.",
          "error",
          "Kindly check your interent connection."
        );
      }
    }
  };
  const onSubmitOtp = async (e) => {
    e.preventDefault();
    setOtpLoader(true);
    try {
      let sessionId = localStorage.getItem("otpSessionId");
      const res = await verifyOtp({
        otp,
        phone,
        sessionId,
      });
      if (res.data.Status === "Success") {
        toast(res.data.Details);
        setOtpLoader(false);
        navigate("/login", { replace: true });
        // document.getElementById('loginLabel').click();
      }
    } catch (err) {
      console.log(err);
      setOtpLoader(false);
    }
  };

  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center mt-5">
              <h5 className="section-title">Register</h5>
            </div>
          </div>
        </div>
        <div className="row">
          {signupScreen ? (
            <div className="col-lg-6 offset-lg-3">
              <div className="account-login-inner">
                <form
                  className="ltn__form-box contact-form-box"
                  method="post"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <div class="form-group">
                    <input
                      type="text"
                      name="fname"
                      placeholder="Full Name"
                      id="fname"
                      className="form-control form-control-lg"
                      onChange={(e) => {
                        setFname(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="phone"
                      placeholder="Phone no."
                      id="phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email*"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      name="password"
                      placeholder="Password*"
                      id="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password*"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="btn-wrapper">
                    <button
                      className="theme-btn-1 btn reverse-color btn-block"
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </form>

                <div className="by-agree text-center">
                  <p>By creating an account, you agree to our:</p>
                  <p>
                    <Link to="/term-of-use">TERMS OF CONDITIONS</Link> &nbsp;
                    &nbsp; | &nbsp; &nbsp;{" "}
                    <Link to="/privacy-policy">PRIVACY POLICY</Link>
                  </p>
                  <div className="go-to-btn mt-50 go-top">
                    <Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="col-lg-6 offset-lg-3">
                <h2>OTP</h2>
                <h5 class="title-head">
                  Enter OTP Sent on <span>{phone} </span>
                </h5>
                <div className="account-login-inner">
                  <form
                    action="#"
                    className="ltn__form-box contact-form-box"
                    method="post"
                    onSubmit={(e) => onSubmitOtp(e)}
                  >
                    <input
                      type="text"
                      name="otp"
                      placeholder="Enter Otp"
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                    <div className="btn-wrapper">
                      <button
                        className="btn theme-btn-1 btn reverse-color btn-block"
                        type="submit"
                        nname="submit"
                        disabled={otpLoader}
                      >
                        {otpLoader ? (
                          <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                        ) : (
                          "Verify"
                        )}
                      </button>
                    </div>
                  </form>
                  <div className="by-agree text-center">
                    <p>By creating an account, you agree to our:</p>
                    <p>
                      <Link to="/term-of-use">
                        TERMS OF CONDITIONS &nbsp; &nbsp;
                      </Link>
                      |
                      <Link to="/privacy-policy">
                        &nbsp; &nbsp; PRIVACY POLICY
                      </Link>
                    </p>
                    <div className="go-to-btn mt-50 go-top">
                      <Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Register;
