import React from "react";
import AboutPage from "../../new-components/about";

const About = () => {
    return (
        <>
            <AboutPage />
        </>
    );
};

export default About;
