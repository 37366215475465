import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV6 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
            <div className="container" style={{ marginTop: '75px' }}>
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-img-wrap about-img-left">

                            <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                                <div className="ltn__animation-pulse1">
                                    <img src={"assets/img/about-v6.jpg"} alt="near2-property-about" />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Identity</h6>
                                <h1 className="section-title">Your Real Estate Partner You Can Trust<span>.</span></h1>
                                <p>
                                    One of the top real estate websites, Near2Property.com, brings together end consumers, dealers, builders, and developers in one simple spot. Our staff is made up of passionate professionals with in-depth knowledge of the real estate sector who are committed to giving you the most accurate and recent information. We prioritise openness, morality, and professionalism to make sure that working with us is nothing less than spectacular.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-info-wrap">

                            <div className="ltn__callout bg-overlay-theme-05  mt-30">
                                <h2>What We Deliver</h2>
                                <h6>An Extensive Range of Features</h6>
                                <p>"In order to satisfy the diverse needs of our users, we at Near2Property.com take pride in offering a large selection of properties. No matter what you're looking for—a pleasant residential home, a practical commercial space, or a huge industrial site—our platform has it. We collaborate with reliable developers and sellers to ensure that every property represented satisfies the highest standards of quality."
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="about-us-img-wrap about-img-left">
                            <img src={"assets/img/about-v7.jpg"} alt="About Us Image" />
                            {/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
								<div className="ltn__animation-pulse1">
									<img src={"assets/img/property-1/property1.jpg"} alt="near2-property-about" />

								</div>
							</div> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }
}

export default AboutV6