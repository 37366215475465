import React from "react";
import { Link } from "react-router-dom";
import LogoBlue from "../assets/img/logo-blue.png";
import { useSelector } from "react-redux";

const Footer = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div class="footer-area">
      <div class=" footer">
        <div class="container">
          <div class="row">
            <div class="col-md-3 col-sm-6 wow fadeInRight animated">
              <div class="single-footer">
                <h4>About us </h4>
                <div class="footer-title-line"></div>
                <img src={LogoBlue} />
                <img
                  src="assets/img/dsdsds33-Copy.png"
                  alt=""
                  class="wow pulse"
                  data-wow-delay="1s"
                />

                <ul class="footer-adress">
                  {/* <li><i class="pe-7s-map-marker strong"> </i> 9089 your adress her</li> */}
                  <li>
                    <i class="pe-7s-mail strong"> </i> near2property@gmail.com
                  </li>
                  <li>
                    <i class="pe-7s-call strong"> </i> +91 908 967 5906
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 wow fadeInRight animated">
              <div class="single-footer">
                <h4>Quick links </h4>
                <div class="footer-title-line"></div>
                <ul class="footer-menu">
                  <li>
                    <Link to="/properties-list">Properties</Link>
                  </li>
                  {/* <li>
                    <a href="#">Services</a>{" "}
                  </li> */}

                  <li>
                    <Link to={user && user !== null ? "/add-post" : "/login"}>
                      Submit property{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 wow fadeInRight animated">
              <div class="single-footer">
                <div class="single-footer">
                  <h4>Quick links </h4>
                  <div class="footer-title-line"></div>
                  <ul class="footer-menu">
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    {/* <li>
                      <a href="faq.html">fqa</a>
                    </li> */}
                    <li>
                      <Link to="/term-of-use">Terms </Link>
                    </li>
                    <li>
                      <Link to="/privacy">Privacy </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6 wow fadeInRight animated">
              <div class="single-footer news-letter">
                <h4>Stay in touch</h4>
                <div class="footer-title-line"></div>
                {/* <p>Lorem ipsum dolor sit amet, nulla  suscipit similique quisquam molestias. Vel unde, blanditiis.</p> */}

                {/* <form>
                                    <div class="input-group">
                                        <input class="form-control" type="text" placeholder="E-mail ... " />
                                        <span class="input-group-btn">
                                            <button class="btn btn-primary subscribe" type="button"><i class="pe-7s-paper-plane pe-2x"></i></button>
                                        </span>
                                    </div>

                                </form> */}

                <div class="social" style={{ marginLeft: 30 }}>
                  <ul>
                    <li>
                      <a class="wow fadeInUp animated" href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="wow fadeInUp animated"
                        href="https://www.facebook.com/kimarotec"
                        data-wow-delay="0.2s"
                      >
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        class="wow fadeInUp animated"
                        href="#"
                        data-wow-delay="0.4s"
                      >
                        <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-copy text-center">
        <div class="container">
          <div class="row">
            <div class="pull-left">
              <span>
                {" "}
                (C) <a href="https://www.near2peoprty.com.com">
                  Near2Property
                </a>{" "}
                , All rights reserved 2016{" "}
              </span>
            </div>
            <div class="bottom-menu pull-right">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="properties-list">Properties</Link>
                </li>
                {/* <li>
                  <a
                    class="wow fadeInUp animated"
                    href="#"
                    data-wow-delay="0.4s"
                  >
                    Faq
                  </a>
                </li> */}
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
