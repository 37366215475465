import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderSvg from "../../assets/img/loader.svg";
import { validateField, unValidateField } from "../../helpers/";
import { addNewBusinessService } from "../../config/business";
import { API_KEY } from "../../config/axiosConfig";
import MapSection from '../googlemap/Map'
// import { GoogleComponent } from "react-google-location";
import { categories } from "../../helpers/json/business-categories.js";
import Autocomplete from "react-google-autocomplete";
// const API_KEY = "AIzaSyA9bI8tKR9gOt8-QHx02ZdhELpCujPKXvg"; 

function AddBusiness() {
  // add business state
  const $ = window.$;
  const [business, setBusiness] = useState({
    title: "",
    email: "",
    phone: "",
    whatsapp: "",
    website: "",
    // workingHours: {},
    category: "",
    images: "",
    address: "",
    city: "",
    desc: "",
    locality: "",
    landmark: "",
    images: [],
    locLng: 0,
    locLat: 0,
  });
  const [postLoader, setPostLoader] = useState(false);
  const [location, setLocation] = useState({
    lat: 0,
    lng: 0
  })
  const categoryRef = useRef();
  function getLat(e) {
    if (e) {
      return e.lat();
    }
    return false;
  }

  function getLng(e) {
    if (e) {
      return e.lng();
    }
    return false;
  }
  const handleTextChange = (name, val) => {
    setBusiness({ ...business, [name]: val });
  };

  const trySelectFiles = (e) => {
    if (e.target.files.length < 2) {
      alert("Minimum 2 images are required!");
      setBusiness({ ...business, images: [] });
      return;
    }
    if (e.target.files.length > 5) {
      alert("Only 5 files are allowed max.");
      setBusiness({ ...business, images: [] });
      return;
    }

    setBusiness({ ...business, images: e.target.files });
  };
  const handleCategoryChange = () => {
    setBusiness((data) => {
      return {
        ...data,
        propertyType: $(categoryRef.current).val()
      }
    });
  }
  useEffect(() => {
    $(categoryRef.current).niceSelect(); //initializing nice select 
    $(categoryRef.current).change(handleCategoryChange)
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
    });
  }, [])

  function validatePost(postDataSubmit) {
    let validate = {};


    for (let key in postDataSubmit) {
      if ((postDataSubmit[key].length == 0) && (key == 'title' || key == 'email' || key == 'phone' || key == 'locLat' || key == 'category' || key == 'address' || key == 'city' || key == 'images' || key == 'desc') || (key == 'images' && postDataSubmit['images'].length < 2)) {
        try {
          validateField(key)
          validate[key] = false;

        }
        catch (e) {
          console.log(e)
        }
      } else if ((postDataSubmit[key]) && (key == 'title' || key == 'email' || key == 'phone' || key == 'locLat' || key == 'category' || key == 'address' || key == 'city' || key == 'images' || key == 'desc') || (key == 'images' && postDataSubmit['images'].length >= 2)) {
        try {
          unValidateField(key)
          validate[key] = true;
        }
        catch (e) {
          console.log(e)
        }

      }
    }
    return Object.values(validate).some((val, idx) => (val == false)) ? false : true;
  }

  const onSubmitBusiness = async (e) => {
    e.preventDefault();
    let postDataSubmit = {
      ...business,
      category: $(categoryRef.current).val(),
      images: [...business.images],
    };
    let validate = validatePost(postDataSubmit)
    if (!validate) {
      toast.error("Please fill required fields");
      return;
    }
    postDataSubmit = {
      ...postDataSubmit,
      description: postDataSubmit.desc,
      locality: JSON.stringify({
        type: "Point",
        coordinates: [
          parseFloat(business.locLng),
          parseFloat(business.locLat),
        ],
      }),
    }
    try {
      setPostLoader(true);

      const fd = new FormData();


      for (let field in postDataSubmit) {
        if (
          Array.isArray(postDataSubmit[field]) &&
          postDataSubmit[field].length > 0
        ) {
          postDataSubmit[field].forEach((i, k) => {
            fd.append(field, postDataSubmit[field][k]);
          });
        } else {
          fd.append(field, postDataSubmit[field]);
        }
      }

      let res = await addNewBusinessService(fd);
      if (res.data.status) {
        toast("Your Business Submitted Successfully!");
        setBusiness({
          title: "",
          email: "",
          phone: "",
          whatsapp: "",
          website: "",
          // workingHours: {},
          category: "",
          images: "",
          address: "",
          city: "",
          desc: "",
          locality: "",
          images: [],
          locLng: 0,
          locLat: 0,
        })
        $(categoryRef.current).val("")
        setPostLoader(false);
      }
    } catch (err) {
      setPostLoader(false);
      console.log(err);
    }
  };

  const handleLocChange = (e) => {
    setBusiness((business) => {
      return {
        ...business,
        locality: e.formatted_address,
        locLat: getLat(e.geometry.location),
        locLng: getLng(e.geometry.location),
      }
    })
  }
  const handleCityChange = (e) => {
    setBusiness((business) => {
      return {
        ...business,
        city: e.formatted_address,
      }
    })
  }


  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <form onSubmit={onSubmitBusiness}>
                <h2>1. Description</h2>
                <p>
                  <small>
                    These fields are mandatory: Title, Phone, Email, Media, Category and Description
                  </small>
                </p>
                <h6>Business Description</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="title"
                        placeholder="*Business Title (mandatory)"
                        value={business.title}
                        onChange={(e) => {
                          handleTextChange("title", e.target.value);
                        }}

                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        placeholder="*Phone No. (mandatory)"
                        value={business.phone}
                        onChange={(e) => {
                          handleTextChange("phone", e.target.value);
                        }}
                      />
                    </div>
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="email"
                        placeholder="*Email (mandatory)"
                        value={business.email}
                        onChange={(e) => {
                          handleTextChange("email", e.target.value);
                        }}
                      />
                    </div>

                    <div className="input-item input-item-name">
                      <input
                        type="text"
                        name="whatsapp"
                        placeholder="Whatsapp No."
                        value={business.whatsapp}
                        onChange={(e) => {
                          handleTextChange("whatsapp", e.target.value);
                        }}
                      />
                    </div>
                    <div className="input-item input-item-name">
                      <input
                        type="text"
                        name="website"
                        placeholder="Business website"
                        value={business.website}
                        onChange={(e) => {
                          handleTextChange("website", e.target.value);
                        }}
                      />
                    </div>

                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="desc"
                        placeholder="Description"
                        value={business.desc}
                        onChange={(e) => {
                          handleTextChange("desc", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h6>Select Categories</h6>
                <div className="row">
                  <div className="col-lg-12 col-md-6 ">
                    <div className="input-item">
                      <select
                        className="nice-select"
                        name="category"
                        ref={categoryRef}
                      >
                        <option value="">Select Business Category...</option>
                        {categories.slice(1, categories.length).map((item) => {
                          return (
                            <option key={item.id} value={item.value}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <h2>2. Media</h2>
                  <h6>Listing Media</h6>
                  <div className="input-item">
                    <input
                      type="file"
                      name="images"
                      className="btn theme-btn-3 mb-10"
                      onChange={(e) => trySelectFiles(e)}
                      multiple
                      accept={".jpg,.png,.jpeg"}
                    />
                  </div>
                  <p>
                    <small style={{ color: 'red' }}>
                      * At least 2 images is required for a valid
                      submission.
                    </small>

                    <small>* Images might take longer to be processed.</small>
                  </p>


                  <h2>3. Location</h2>
                  <h6>Business Location</h6>
                  <div className="row">


                    <div className="input-item input-item-name">
                      <input
                        type="text"
                        name="address"
                        placeholder="Business Address"
                        value={business.address}
                        onChange={(e) => {
                          handleTextChange("address", e.target.value);
                        }}
                      />
                    </div>



                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">

                        <Autocomplete
                          apiKey={API_KEY}
                          language='en'
                          type="text"
                          name='city'
                          placeholder="City"
                          options={{
                            componentRestrictions: { country: "in" },
                          }}
                          onPlaceSelected={handleCityChange}
                        />
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">

                        <Autocomplete
                          apiKey={API_KEY}
                          language='en'
                          placeholder="Locality"
                          type="text"
                          name='locality'
                          options={{
                            componentRestrictions: { country: "in" },
                            types: ['(regions)']
                          }}
                          onPlaceSelected={handleLocChange}
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="input-item input-item-name ltn__custom-icon">
                        <input
                          type="text"
                          name="landmark"
                          placeholder="Nearby landmark"
                          value={business.landmark}
                          onChange={(e) => {
                            handleTextChange("landmark", e.target.value);
                          }}
                        />
                      </div>
                    </div>

                  </div>




                </div>
                {location.lat && location.lng && (
                  <MapSection location={location} zoomLevel={14} business={business} setBusiness={setBusiness} />
                )}


                <div className="btn-wrapper text-center mt-30">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    disabled={postLoader}
                  >
                    {postLoader ? (
                      <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                    ) : (
                      "Submit Business"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="ltn__appointment-area pb-120">
    //   {" "}
    //   <div className="ltn__appointment-inner">
    //     <form
    //       className="ltn__form-box contact-form-box"
    //       onSubmit={onSubmitBusiness}
    //     >
    //       <h6 className="mb-30">Add Business</h6>
    //       <div className="row">
    //         <div className="col-md-12">
    //           <div class="input-item input-item-name ltn__custom-icon">


    //             <input
    //               placeholder="Business Title"
    //               value={business.title}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   title: e.target.value,
    //                 })
    //               }
    //               name="title"
    //             />
    //           </div>

    //           <div class="form-group">
    //             <label>Email:</label>
    //             <input
    //               className="form-control"
    //               value={business.email}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   email: e.target.value,
    //                 })
    //               }
    //               name="email"
    //             />
    //           </div>
    //           <div class="form-group">
    //             <label>Phone No:</label>
    //             <input
    //               className="form-control"
    //               value={business.phone}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   phone: e.target.value,
    //                 })
    //               }
    //               name="phone"
    //             />
    //           </div>
    //           <div class="form-group">
    //             <label>Whatsapp:</label>
    //             <input
    //               className="form-control"
    //               value={business.whatsapp}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   whatsapp: e.target.value,
    //                 })
    //               }
    //               name="whatsapp"
    //             />
    //           </div>
    //           <div class="form-group">
    //             <label>Website:</label>
    //             <input
    //               className="form-control"
    //               value={business.website}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   website: e.target.value,
    //                 })
    //               }
    //               name="website"
    //               type={"url"}
    //             />
    //           </div>
    //           <label>Category:</label>

    //           <div className="input-item">
    //             <select
    //               className="nice-select bs-select"
    //               name="category"
    //               ref={categoryRef}
    //             >
    //               <option value="">Select Category...</option>
    //               {categories.slice(1, categories.length).map((item) => {
    //                 return (
    //                   <option key={item.id} value={item.value}>
    //                     {item.title}
    //                   </option>
    //                 );
    //               })}
    //             </select>
    //           </div>
    //           <div class="form-group">
    //             <label>Address:</label>
    //             <input
    //               className="form-control"
    //               value={business.address}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   address: e.target.value,
    //                 })
    //               }
    //               name="address"
    //             />
    //           </div>
    //           <div class="form-group">
    //             <label>City:</label>
    //             <Autocomplete
    //               apiKey={API_KEY}
    //               className='form-control'
    //               name='city'
    //               language='en'
    //               placeholder="Enter City"
    //               options={{
    //                 componentRestrictions: { country: "in" },
    //               }}
    //               // type="text"
    //               onPlaceSelected={handleCityChange}
    //             />
    //             {/* <input
    //         className="form-control"
    //         value={business.city}
    //         onChange={(e) =>
    //           setBusiness({
    //             ...business,
    //             city: e.target.value,
    //           })
    //         }
    //         name="City"
    //       /> */}
    //           </div>
    //           <div className="form-group">
    //             <label>Location:</label>
    //             <Autocomplete
    //               apiKey={API_KEY}
    //               className='form-control'
    //               placeholder="Enter Locality"
    //               name='locality'
    //               language='en'
    //               options={{
    //                 componentRestrictions: { country: "in" },
    //                 types: ['(regions)']
    //               }}
    //               // type="text"
    //               onPlaceSelected={handleLocChange}
    //             />
    //           </div>
    //           <div className="form-group">
    //             <label>Business Media:</label>
    //             <input
    //               type="file"
    //               name="images"
    //               className="btn theme-btn-3 mb-10 form-control"
    //               onChange={(e) => trySelectFiles(e)}
    //               multiple
    //               accept={".jpg,.png,.jpeg"}
    //             />
    //             <br />
    //             <p>
    //               <small style={{ color: 'red' }}>
    //                 * At least 2 images is required for a valid submission.</small><br /><small>Minimum size
    //                   is 500/500px.
    //               </small>
    //               {/* <br />
    //               <small>* PDF files upload supported as well.</small>
    //               <br /> */}
    //               <small>* Images might take longer to be processed.</small>
    //             </p>
    //           </div>
    //           <div class="form-group">
    //             <label>Description:</label>
    //             <textarea
    //               // className="form-control"
    //               value={business.description}
    //               onChange={(e) =>
    //                 setBusiness({
    //                   ...business,
    //                   description: e.target.value,
    //                 })
    //               }
    //               name="description"
    //             >

    //             </textarea>
    //           </div>
    //           <div className="btn-wrapper mt-0">
    //             <div className="btn-wrapper">
    //               <button
    //                 type="submit"
    //                 className="btn theme-btn-1 btn-effect-1 text-uppercase"
    //                 disabled={postLoader}
    //               >
    //                 {postLoader ? (
    //                   <img src={LoaderSvg} style={{ padding: "0 7px" }} />
    //                 ) : (
    //                   "Submit"
    //                 )}
    //               </button>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>
  );
}

export default AddBusiness;
