import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV7 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'

        return <div className="ltn__about-us-area pt-120--- mt--30 go-top">
            <div className="container" style={{ paddingTop: '50px' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 align-self-center">
                        <div className="about-us-img-wrap">
                            <h2 className='text-center pb-10'>What Makes Near2Property.com the Best</h2>
                            <div className="about-us-img-info-v7 about-us-img-info-2 about-us-img-info-3">
                                <div className="ltn__animation-pulse1">
                                    <img src={"assets/img/about-v8.jpg"} alt="near2-property-about" />

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=" ltn__section-title-2--- mb-20 pt-20">
                                <h3>1. Extensive Listings: One Way Near2Property.com Differs
                                    <p className='p-about-v7'>
                                        Because of the large number of properties listed on our platform, you have access to a wide range of possibilities. If you're a buyer, seller, or tenant, you'll discover something that fits your needs and price range.</p>
                                </h3>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h3>2. Interface that is user-friendly
                                    <p className='p-about-v7'>

                                        We realise it should be simple to go through real estate listings. With the help of our simple interface, you can easily filter searches, view high-quality photographs, and gather crucial data.
                                    </p>
                                </h3>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h3>3. Specialist Advice

                                    <p className='p-about-v7'>

                                        Being a first-time buyer or seller, navigating the real estate market can be challenging. Our staff of professionals at Near2Property.com is always available to offer insightful counsel and direction while you travel.
                                    </p>
                                </h3>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h3>4. Ongoing customer support

                                    <p className='p-about-v7'>
                                        Because we are committed to offering superior customer service, we stand out. We are available at all times to answer your inquiries, address any problems, and guarantee that utilising our platform is a straightforward process for you.

                                    </p>
                                </h3>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h3>Conclusion

                                    <p className='p-about-v7'>

                                        Near2Property.com is more than a simple real estate website; it is a dependable partner in providing efficient and satisfying real estate services. You can search the real estate market easily thanks to our comprehensive listings, user-friendly design, professional assistance, and committed customer care. Near2Property.com is here to help you every step of the way, whether you're searching for a place to call home, an excellent office space, or a potential investment opportunity.
                                    </p>
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    }
}

export default AboutV7