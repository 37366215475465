import Navbar from "../../new-components/global-components/navbar";
import PageHeader from "../../new-components/global-components/page-header";
import ProductSlider from "../../new-components/shop-components/product-slider-v1";
import ProductDetails from "../../new-components/shop-components/shop-details";
import CallToActionV1 from "../../new-components/section-components/call-to-action-v1";
import Footer from "../../new-components/global-components/footer";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";
const PropertyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkAndRedirect = () => {
    let token = localStorage.getItem("token");
    if (token == null || token == undefined) {
      navigate(`/login?redirect=${location.pathname}`, {
        state: {
          id: location.state.id,
        },
        replace: true,
      });
    }
  };

  React.useEffect(() => {
    checkAndRedirect();
  }, []);
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Product Details" customclass="mb-0" /> */}
      {/* <ProductSlider /> */}
      <ProductDetails />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default PropertyDetails;
