import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../new-components/global-components/footer";
// import logo3 from '../../assets/logo3.PNG'
// import Header from "../../components/Header";
import NavBar from "../../new-components/global-components/navbar";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleInput = this.handleInput.bind(this);
    this.navigation = this.navigation.bind(this);
    // this.onSubmit = this.onSubmit.bind(this)
  }

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // logout = (e) => {

  //     logOutUser()

  //     this.props.history.push('/admin')

  // }

  navigation = (e, route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <>
        {/* <Header /> */}
        <NavBar />
        <div className="container-fluid bg-light">
          <div className="container">
            <div>
              <div className="d-flex justify-content-center">
                {/* <img src={logo3} height="400" width="400" /> */}
              </div>
              <div className="d-flex justify-content-center">
                <h2>Near2Property</h2>
              </div>

              <div className="d-flex justify-content-center">
                <h2>Privacy Policy</h2>
              </div>
              <br></br>

              <div class="row">
                {/* <div className="col-9"> */}
                {/* <ul className="nav flex-column"> */}
                <p>
                  When you visit the Platform, we may collect certain
                  non-personal information such as your Internet Protocol (“IP”)
                  address, operating system, browser type, and Internet service
                  provider. This type of information does not identify you
                  personally.
                </p>
                <br></br>
                <p className="nav-link">
                  {" "}
                  When you register with the Platform, we may also collect
                  personal information that you provide such as your name,
                  mailing address, email address, phone/mobile number, home
                  country, zip code, and certain other information in event you
                  log in via Facebook depending on your privacy settings
                  including but not limited to your name, email address, profile
                  picture, list of friends, education etc.
                </p>
                <br></br>
                <p className="nav-link">
                  {" "}
                  ("Registration Information"). You have the option of also
                  providing to us your fax numbers, gender, age, and/or company
                  name if you choose to register with Near2Property App. If you
                  provide your phone number, it may be displayed in your
                  posting. Further,
                </p>
                <br></br>
                <p className="nav-link">
                  <p>
                    if you have provided your phone number and posted an
                    advertisement using your account, you agree to receive
                    communications on the provided phone number from our side
                    which may include but not be limited to automated calls or p
                    messages. The communication may require you to confirm and
                    verify that your account has been used to post the relevant
                    advertisement on the Platform.
                  </p>
                  <br></br>
                  <p>
                    We may also collect additional information that our users
                    provide, such as new or deleted postings, new or deleted
                    comments, keyword searches, scam reports and new contact
                    sellers. We use a third-party payment gateway for purchases,
                    and other third-party companies to monitor site traffic,
                    which may, in some instances, store your information (see
                    Section xxi(D) below).
                  </p>
                  <br></br>
                  <p>
                    By using this Platform or the Service, you consent to the
                    collection, storage, and use of the personal information you
                    provide for any of the services that we offer, and you
                    consent to our collection of any changes or updates that you
                    may provide to any information you provide that is collected
                    by Near2Property App By using this Platform, you consent to
                    share your geolocation data in order to publish
                    information/advertisements of advertisements offered by
                    users close to your location and vice-versa.
                  </p>
                  <br></br>
                  <h3>Cookies :</h3>
                  <br></br>
                  <br></br>
                  <p>
                    We may use cookies to manage our users’ sessions and to
                    store preferences, tracking information, and language
                    selection. Cookies may be used whether you register with us
                    or not. "Cookies" are small p files transferred by a web
                    server to your hard drive and thereafter stored on your
                    computer. The types of information a cookie collects include
                    the date and time you visited, your browsing history, your
                    preferences, and your username. In some instances, our
                    third-party service providers may use cookies on the
                    Platform. We cannot control or access cookies used by
                    third-party service providers. This Privacy Policy covers
                    only Cookies used by us, and not any cookies used by third
                    parties. You have the ability to either accept or decline
                    the use of cookies on your computer, whether you are
                    registered with us or not. Typically, you can configure your
                    browser to not accept cookies. However, declining the use of
                    cookies may limit your access to certain features of the
                    Platform. For example, you may have difficulty logging in or
                    using certain interactive features of the Platform, such as
                    the Near2Property App Forum or Comments feature.
                  </p>
                  <br></br>
                  <p>
                    We use third-party service providers to assist us in
                    measuring purchases and sales on our Platform and generally
                    improving our Platform and to monitor our users’ interests
                    and activities. You hereby authorise Near2Property App
                    and/or third party service providers engaged by
                    Near2Property App to collect, use, store, analyse,
                    reproduce, publish, and adapt (either on its own or through
                    third party service provider) the information in relation to
                    your use of the Platform for the purpose of data analysis
                    and for improving your experience on the Platform.
                  </p>
                  <br></br>
                  <p>
                    {" "}
                    In addition, the Platform may occasionally contain links to
                    Third-Party Sites or provide you with information in
                    relation to services that you may avail from any third
                    parties. Information about services that you may choose to
                    avail from these third party service providers may be
                    actively provided to you by Near2Property App in any manner
                    including through its various marketing and communication
                    channels. You acknowledge that this is solely undertaken by
                    Near2Property App to improve your experience in relation to
                    the use of the Platform and the provision of such services
                    shall be subject to such additional terms and conditions of
                    Near2Property App and/or third-party service providers.{" "}
                  </p>
                  <br></br>
                  <p>
                    Near2Property App may also offer for free or for a fee,
                    deliverables produced by third-party service providers in
                    furtherance of any services that you may have availed from
                    these third party service providers in connection with the
                    advertisement posted on the Platform, without any obligation
                    (monetary or otherwise) towards you. Near2Property App shall
                    not be responsible for any service availed by you from such
                    third parties or any payment made by you to such third
                    parties in connection with the services. Any concern or
                    claims in relation to such services should be directed by
                    you to such third parties. If you click on the links to
                    Third-Party Websites, you leave the Platform. We are not
                    responsible for the content of these Third- Party Websites
                    or for the security of your personal information when you
                    use the Third Party Websites. These third-party service
                    providers and Third-Party Sites may have their own privacy
                    policies governing the storage and retention of your
                    personal information that you may be subject to. They may
                    collect information such as your IP address, browser
                    specification, or operating system. This Privacy Policy does
                    not govern personal information provided to, stored on, or
                    used by these third-party providers and Third-Party Sites.
                    We recommend that when you enter a Third-Party Site, you
                    review the Third Party Site’s privacy policy as it relates
                    to safeguarding your personal information. We use
                    third-party advertising companies to serve ads when you
                    visit the Platform. These companies may use information (not
                    including your name, address, email address, or telephone
                    number) about your visits to the Platform and Third-Party
                    Websites in order to provide advertisements about goods and
                    services of interest to you. On the Platform, Google, as a
                    third-party vendor, uses Cookies to serve ads. Google’s use
                    of the DoubleClick DART Cookie enables it to serve ads to
                    the Platform’s users based on their visit to the Platform
                    and Third-Party Websites. For more information on the DART
                    Cookie see:
                    <Link to="http://www.google.com/privacy_ads.html">
                      <p>http://www.google.com/privacy_ads.html</p>
                      <br></br>
                    </Link>
                    . You may opt out of the use of the DART Cookie by Google by
                    visiting the Google Ad and Content Network Privacy Policy
                    at:
                    <Link to="http://www.google.com/privacy_ads.html">
                      http://www.google.com/privacy_ads.html
                    </Link>
                    . You may opt out of targeting advertising for all NAI
                    member ad networks by visiting:
                    <Link to="http://www.networkadvertising.org/">
                      <p>http://www.networkadvertising.org/</p>
                      <br></br>
                    </Link>
                    . By publishing an ad on the Platform, you acknowledge and
                    agree that the Content is public and accessible by any third
                    party and that they may appear in search engine results
                    (such as Yahoo!, MSN, Google, Altavista, and other search
                    engines) and in the cache of those search engines, in feeds
                    and Third-Party Websites pursuant to co-branding agreements,
                    and that it is the sole responsibility of each of those
                    search engines, Third-Party Websites or RSS web feed
                    resources to update and/or to remove Content from their
                    indexes and their cache. You agree and acknowledge that
                    Near2Property App is not liable for the information
                    published in search results or by any Third-Party Website
                    that carries Near2Property App postings.
                  </p>
                  <br></br>
                  <p>
                    We may use information provided by you to: (i) enforce our
                    Terms of Use, monitor user activity, such as keyword
                    searches or new postings, and more effectively manage
                    traffic on the Platform; (ii) provide customer services,
                    create and manage user accounts; and (iii) assist you with
                    technical difficulties. Also, we may share with third- party
                    service providers certain information, such as your browser
                    capabilities or operating system, that we have collected in
                    order to better understand which advertisements and services
                    may interest you. We may block users located in certain
                    countries from using the Platform. We may retain such
                    information for as long as is required to fulfill our
                    business objective, even after your account is terminated.
                  </p>
                  <br></br>
                  <p>
                    You are a valued customer, and we recognize that protecting
                    your privacy is important to you. For this reason, we are
                    committed to protecting the personal information you provide
                    in a variety of ways. We do not directly process any
                    payments and do not store your credit card information.
                    Secured socket layer SSL technology is used for processing
                    payment transactions with third-party service provider
                    partners, such as PayU, PayPal, and DineroMail,RazorPay etc.
                    Your Registration Information may be protected by a unique
                    customer password and user ID. You should not disclose your
                    password information to anyone and you should always
                    remember to log off if using a shared computer. Lastly, you
                    may utilize the Platform as an anonymous user by not
                    registering. We have taken certain security protections in
                    safeguarding your personal information. However, as with
                    most electronic transactions, no method is 100% safe. While
                    we strive to use a commercially acceptable means to protect
                    the personal information you provide, we cannot guarantee
                    its security. Therefore, you acknowledge and agree that we
                    assume no liability regarding the theft, loss, alteration or
                    misuse of personal or other information or Content,
                    including, without limitation, such information that has
                    been provided to third parties or other users, or with
                    regards to the failure of a third party to abide by the
                    agreement between us and such third party. You may
                    participate in our Forum or utilize our Comments feature.
                    For postings to the Forum, your username will be posted. For
                    postings using the Comments feature, your name and email
                    will only be posted if you provide it in the comment. We
                    strongly discourage posting any information on these
                    features or in any Content posted by you that you do not
                    want others to see. You agree that you are responsible for
                    maintaining the confidentiality of your username and
                    password, and all uses of your account, whether or not you
                    have authorized such use.
                  </p>
                  <br></br>
                  <p>
                    You may access, remove, review, and/or make changes to
                    personal information that you have provided to us. You need
                    not to register in order to post or respond to
                    advertisements on the Platform. If you register or respond
                    to advertisements or posts on the Platform or post any
                    content on the Platform, we may send you certain
                    notifications, advertisements, promotions, surveys, p
                    messages, specials and phone calls in relation to the
                    Services.. We may also send you any legally required
                    notifications and certain notifications, including but not
                    limited to, service-related notices or notices regarding a
                    change to any of our policies. For example, we may send you
                    a notice regarding server problems or scheduled maintenance
                    to the Platform. In order to opt-out of receiving these
                    notices, you may need to deactivate your account. You can
                    opt out of certain e-mail communications from us, including
                    our newsletters, advice on buying and selling on the
                    Platform, notifications that a user has commented on your
                    posting, and notifications that you can refresh your
                    posting. We will not change your preferences without your
                    consent. You cannot opt out of receiving transactional
                    communications from Near2Property App related to your
                    account.
                  </p>
                  <br></br>
                  <p>
                    On rare occasions, we may be required to disclose your
                    personal information due to legal or regulatory
                    requirements. In such instances, we reserve the right to
                    disclose your personal information as required in order to
                    comply with our legal obligations, including but not limited
                    to complying with court orders, warrants, subpoenas, service
                    of process requirements, or discovery requests. We may also
                    disclose information about our users to law enforcement
                    officers or others, in the good faith belief that such
                    disclosure is reasonably necessary to enforce these Terms
                    and/or Privacy Policy; respond to claims that any Content
                    violates the rights of third-parties; or protect the rights,
                    property, or personal safety of Near2Property App, our users
                    or the general public; or we are in notice of any criminal
                    activity being conducted via the Platform. You agree and
                    acknowledge that we may not inform you prior to or after
                    disclosures made according to this section. If substantially
                    all of our assets are sold or merged into another company,
                    the acquiring company shall be given access to your personal
                    information without your consent. If we sell only a part of
                    our business, the acquiring entity shall have access to your
                    personal information without your consent and we may send
                    you a notice regarding this sale.We reserve the right to
                    change these Terms from time to time, and at our sole
                    discretion. We may send you a notice regarding material
                    changes to these Terms, including the Privacy Policy or the
                    Platform. You should make note of the "last updated date" at
                    the beginning of the Terms. With this information, you
                    should be able to identify if the Terms have been updated
                    recently.
                  </p>
                  <br></br>
                  <p></p>Any disputes regarding your privacy are subject to the
                  Terms, including but not limited to any provisions related to
                  indemnification, limitations on damages, and choice of law and
                  forum
                </p>
                <br></br>
                {/* </ul> */}
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Privacy;
