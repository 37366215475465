import {
  SET_BUSINESS_DETAILS,
  SET_MY_BUSINESS,
  SET_BUSINESSES,
  SET_SEARCH_BUSINESS,
  SET_FILTERED_BUSINESS,
  SET_ALL_BUSINESS,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  myBusiness: null,
  token: null,
  tokenExpired: false,
  businessDetails: null,
  businesses: null,
  searchBusinesses: null,
  totalBusinesses: 0,
  allBusinesses: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MY_BUSINESS:
      return {
        ...state,
        myBusiness: action.payload,
      };
    case SET_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: action.payload.post,
        // ratingByUser: action.payload.rate
      };
    case SET_BUSINESSES: {
      let { data, total } = action.payload;
      return {
        ...state,
        businesses: data,
        totalBusinesses: total.length > 0 ? total[0]?.total : 0,
      };
    }
    case SET_SEARCH_BUSINESS:
      return {
        ...state,
        searchBusinesses: action.payload,
      };
    case SET_ALL_BUSINESS:
      let { businesses, total } = action.payload;
      return {
        ...state,
        allBusinesses: businesses,
        totalBusinesses: total.length > 0 ? total[0]?.total : 0,
      };
    default:
      return {
        ...state,
      };
  }
}
