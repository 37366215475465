import {
  SET_AD_DETAILS,
  SET_MY_PROPERTIES,
  SET_PROPERTIES,
  SET_SEARCH_PROPERTIES,
  SET_FILTERED_PROPERTIES,
  SET_ALL_PROPERTIES,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  myProperties: null,
  token: null,
  tokenExpired: false,
  propertyDetails: null,
  properties: null,
  searchedProperties: null,
  ratingByUser: null,
  totalProperties: 0,
  allProperties: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MY_PROPERTIES:
      return {
        ...state,
        myProperties: action.payload,
      };
    case SET_AD_DETAILS:
      return {
        ...state,
        propertyDetails: action.payload.post,
        // ratingByUser: action.payload.rate
      };
    case SET_PROPERTIES: {
      let { data, total } = action.payload;
      return {
        ...state,
        properties: data,
        totalProperties: total.length > 0 ? total[0]?.total : 0,
      };
    }
    case SET_SEARCH_PROPERTIES:
      return {
        ...state,
        searchedProperties: action.payload,
      };
    case SET_ALL_PROPERTIES:
      let { posts, total } = action.payload;
      return {
        ...state,
        allProperties: posts,
        totalProperties: total.length > 0 ? total[0]?.total : 0,
      };
    default:
      return {
        ...state,
      };
  }
}
