import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addPost = (data) =>
  privateReq.post("/posts", data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
export const getPosts = (data) => privateReq.get("/posts" + data);
export const getPost = (id) => privateReq.get("/post/" + id);
export const searchPosts = (str) => privateReq.get("/posts/search" + str);
export const addRatingService = (data) => privateReq.post("/post/rating", data);

export const filterPosts = (data) => privateReq.get("/posts/filter/" + data);
export const markAsBooked = (data) =>
  privateReq.post("/post/mark-booked/", data);
//ADmin
export const getAllPosts = (data) => privateReq.get("/get-all-posts" + data);

export const apiArrovePost = (data) =>
  privateReq.post("/post/approve-post", data);



export const apiShowInterest = (data) =>
  privateReq.post("/post/interest", data);
export const getMyPosts = (data) => privateReq.get("/my-posts" + data);
export const editPostService = (data) => privateReq.post("/update-post", data, {
  headers: {
    "Content-type": "multipart/form-data",
  },
});
export const apiDeletePost = (id) => privateReq.delete("/post/" + id);