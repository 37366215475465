import React from 'react'
import GoogleMapReact from 'google-map-react'
import LocationPin from './LocationPin'
import './map.css'
import { API_KEY } from "../../config/axiosConfig";
let marker
const Map = ({ location, zoomLevel, setBusiness, business }) => {
    const loadMap = (map, maps) => {
        marker = new maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            draggable: true,
        });
        marker.addListener("dragend", () => {
            let lat = marker.getPosition().lat();
            let lng = marker.getPosition().lng();
            setBusiness({
                ...business,
                locLat: lat,
                locLng: lng
            })
        });

    };
    return (
        <div className="map">
            <h2 className="map-h2">Pin your business Location</h2>

            <div className="google-map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: API_KEY }}
                    defaultCenter={location}
                    defaultZoom={zoomLevel}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                >
                </GoogleMapReact>
            </div>
        </div>
    )
}

export default Map;