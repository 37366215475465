import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

export default function N2PModal({ children, state, stateFunc, title }) {
    return (
        <Modal styles={{
            root: {
                zIndex: 999999999
            }
        }} open={state} onClose={() => { stateFunc(false) }}>
            <div className="home next-header container-fluid p-0 wow fadeIn" style={{ visibility: 'visible', animationName: 'fadeIn' }}>
                <h1 className="h1-class news-h1 text-left font-weight-black linear-wipe mb-1">{title}</h1>
            </div>

            {children}
        </Modal>
    );
}
