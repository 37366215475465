import * as React from "react";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";

import { Provider } from "react-redux";
import store from "../src/redux/store";
import RootStackScreen from "./pages/RootStackScree";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Provider store={store}>
          <RootStackScreen />
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
