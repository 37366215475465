import React, { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAllUsers } from "../../redux/actions/auth";
import { Link } from "react-router-dom";

const AllUsers = () => {
  const allUsers = useSelector((state) => state.auth.allUsers);
  const totalUsers = useSelector((state) => state.auth.totalUsers);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll({
      top: 0,
    });
    onGetAllUsers();
  }, [page]);

  const onGetAllUsers = () => {
    let queryParams = "?page=" + page;
    dispatch(setAllUsers(queryParams));
  };

  function renderUsers({ item }) {
    return (
      <>
        <tr>
          <td>
            <div className="ltn__my-properties-info">{item.fullName}</div>
          </td>
          <td>
            <div className="ltn__my-properties-info">{item.phone}</div>
          </td>
          <td>
            <div className="ltn__my-properties-info">{item.email}</div>
          </td>
          {/* <td>
            <Switch
              className="pull-right"
              //   onChange={(checked) =>
              //     handleApprove(checked, item.status, item._id)
              //   }
              checked={item.status}
            />
          </td> */}
        </tr>
      </>
    );
  }
  function areEqual(prevProps, nextProps) {
    return prevProps.item == nextProps.item;
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
  }
  const Component = memo(renderUsers, areEqual);
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>

            <th scope="col">Phone</th>
            <th scope="col">Email</th>

            {/* <th scope="col">Actions</th> */}
            {/* <th scope="col">Delete</th> */}
          </tr>
        </thead>
        <tbody>
          {/* <div
                                    class="col-md-10 padding-top-40
                                  properties-page user-properties"
                                  > */}

          {allUsers &&
            allUsers.length > 0 &&
            allUsers?.map((i) => {
              return <Component item={i} />;
            })}

          {/* </div> */}
        </tbody>
      </table>
      <div className="ltn__pagination-area text-center">
        <div className="ltn__pagination">
          <ul>
            {page > 1 ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page - 1)}>
                  <i className="fas fa-angle-double-left" />
                </Link>
              </li>
            ) : null}
            {page * 20 < totalUsers ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page + 1)}>
                  {page + 1}
                </Link>
              </li>
            ) : null}
            {(page + 2) * 20 < totalUsers ? (
              <li className="active">
                <Link to="#" onClick={() => setPage((page) => page + 2)}>
                  {page + 2}
                </Link>
              </li>
            ) : null}
            {(page + 3) * 20 < totalUsers ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page + 3)}>
                  {page + 3}
                </Link>
              </li>
            ) : null}
            {(page + 4) * 20 < totalUsers ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page + 4)}>
                  {page + 4}
                </Link>
              </li>
            ) : null}
            {(page + 5) * 20 < totalUsers ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page + 5)}>
                  {page + 5}
                </Link>
              </li>
            ) : null}
            {page * 20 < totalUsers ? (
              <li>
                <Link to="#" onClick={() => setPage((page) => page + 1)}>
                  <i className="fas fa-angle-double-right" />
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
