import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_USER } from "../redux/actions/types";
import Logo from "../assets/img/logo.png";
const Header = ({ checkLoggedInUser, location }) => {
  let token = localStorage.getItem("token");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    token = null;
    dispatch({
      type: SET_CURRENT_USER,
      payload: null,
    });
    checkLoggedInUser();
  };

  return (
    <div class="header-connect">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-8  col-xs-12">
            <div class="header-half header-call">
              <span className="img-logo">
                <img src={Logo} height={100} width={150} />
              </span>
              <p>
                <span>
                  <i class="pe-7s-call"></i> +1 234 567 7890
                </span>
                <span>
                  <i class="pe-7s-mail"></i>
                  near2property@gmail.com
                </span>
              </p>
            </div>
          </div>
          <div class="col-md-2 col-md-offset-5  col-sm-3 col-sm-offset-1  col-xs-12">
            <div class="header-half header-social">
              <ul class="list-inline">
                <li>
                  <a href="#">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
