import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const validateUser = (data) => publicReq.post("/validate-user", data);

export const sendOtp = (data) => publicReq.get(`/send-otp?phone=${data.phone}`);
export const verifyOtp = (data) =>
  publicReq.get(`/verify-otp?otp=${data.otp}&sessionId=${data.sessionId}`);

export const getProfile = (data) => privateReq.get("/get-profile", data);
export const RegisterService = (data) => publicReq.post("/register", data);
export const LoginService = (data) => publicReq.post("/login", data);
export const updateUser = (data) => privateReq.post("/update-user", data);
export const updateUserImage = (data) =>
  imageUpload.post("/update-user-image", data);
export const ChangePasswordService = (data) =>
  privateReq.post("/change-password", data);
export const forgotPasswordService = (data) =>
  publicReq.post("/forgot-password", data);
export const ResendOtpService = (data) =>
  privateReq.post("/user/resend-otp", data);
export const authPhoneVerifyService = (data) =>
  publicReq.post("/phone-verify", data);

export const RegisterOtpService = (data) =>
  privateReq.post("/user/register-otp", data);
export const getAllUsers = (data) => privateReq.get("/get-all-users" + data);
export const RefreshTokenService = (data) =>
  privateReq.get("/auth/token" + data);
// export const SettingService = (data) => axios.post(config.API_URL + "/user/settings", data)
// export const getUser = (data) => axios.post(config.API_URL + '/user/get', data)
// export const getUserAds = (data) => axios.post(config.API_URL + '/user/get-my-ads', data)

// export const updateProfileService = (data) => axios.post(config.API_URL + '/user/update', data)

// export const AppUserDeleteService = (data) => axios.post(config.API_URL + "/user/delete", data)

// export const ForgotPasswordService = (data) => axios.post(config.API_URL + "/user/forgot-password", data)

// export const NewPasswordService = (data) => axios.post(config.API_URL + "/user/new-password", data)

// export const ProfileImageService = (data) => axios.post(config.API_URL + "/admin/profie-image/profile-image", data)

// export const UploadProfileImageService = (data) => axios.post(config.API_URL + "/admin/profie-image/profile-image", data)
// export const UpdateProfileImageService = (data) => axios.post(config.API_URL + "/admin/profie-image/update-profile-image", data)

// export const DeleteAdService = (data) => axios.post(config.API_URL + "/user/ad-delete", data)

// export const AdViewsService = (data) => axios.post(config.API_URL + "/admin/all-ad/ad-views", data)
// export const AdSoldService = (data) => axios.post(config.API_URL + "/admin/all-ad/ad-sold", data)
