import React, { useState } from 'react';
import {
    validateUser,
    sendOtp,
    verifyOtp,
    forgotPasswordService,
} from "../../config/user";
import { useNavigate, } from "react-router-dom";
import { toast } from "react-toastify";
import { validateField } from "../../../src/helpers";
import Footer from '../global-components/footer';
import Navbar from '../global-components/navbar';
import LoaderSvg from "../../assets/img/loader.svg";

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [phone, setphone] = useState("")
    const [loaderForgot, setLoaderForgot] = useState(false);
    const [checkOtpForm, setCheckOtpForm] = useState(false);
    const [showPassInputs, setShowPassInputs] = useState(false);
    const [loaderForgotOtp, setLoaderForgotOtp] = useState(false)
    const [loaderChangePass, setLoaderChangePass] = useState(false);
    ;
    const [pass, setPass] = useState({
        new: "",
        confirm: "",
    });
    const [otp, setOtp] = useState("");

    const onForgotPass = async (e) => {
        e.preventDefault();

        if (!phone) {
            validateField('phone')
            return;
        }
        setLoaderForgot(true)
        try {
            let validateRes = await validateUser({
                phone,
            });
            if (validateRes.data.success) {
                await onSendOtp();
                setCheckOtpForm(true);
                setLoaderForgot(false)
            } else {
                toast("Invalid Phone");
            }
        } catch (e) {
            console.log(e);
        }
    };
    const onSendOtp = async () => {

        setLoaderForgot(true);
        try {
            const otpRes = await sendOtp({ phone });

            if (otpRes.data.Status === "Success") {
                localStorage.setItem("otpSessionId", otpRes.data.Details);
            }
            setLoaderForgot(false);
        } catch (err) {
            setLoaderForgot(false);
            let msg = err.message;

            if (msg == "Network Error") {
                toast(
                    "Network Error.",
                    "error",
                    "Kindly check your interent connection."
                );
            }
        }
    };
    const onSubmitOtp = async (e) => {
        e.preventDefault();
        if (!otp) {
            validateField('otp')
            return;
        }
        setLoaderForgotOtp(true);
        try {
            let sessionId = localStorage.getItem("otpSessionId");
            const res = await verifyOtp({
                otp,
                phone,
                sessionId,
            });

            if (res.data.Status === "Success") {
                toast(res.data.Details);

                setShowPassInputs(true);
                setLoaderForgotOtp(false);
                // document.getElementById('loginLabel').click();
            }
        } catch (err) {
            setLoaderForgot(false);
            console.log(err);
        }
    };
    const onChangePassword = async (e) => {
        e.preventDefault();
        if (!pass.new) {
            validateField('password')
            return
        }
        if (!pass.confirm) {
            validateField('confirmPassword')
            return
        }
        if (pass.new != pass.confirm) {
            validateField('confirmPassword', "Confirm Password doesn't match");
            return;
        }
        setLoaderChangePass(true);
        try {
            const res = await forgotPasswordService({
                phone,
                password: pass.new,
            });

            if (res.data.status) {
                toast(res.data.message);
                setCheckOtpForm(false);
                setShowPassInputs(false);
                setLoaderChangePass(false);
                navigate('/login', { replace: true })
            }
        } catch (e) {
            setLoaderForgot(false);
            console.log(e);
        }
    };
    return (
        <div>
            <Navbar />
            <div className="ltn__login-area pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title-area text-center mt-5">
                                <h5 className="section-title">Forgot Password</h5>
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        {showPassInputs ? (
                            <div className="col-lg-6 offset-lg-3">
                                <div className="account-login-inner">
                                    <form action="#" className="ltn__form-box contact-form-box">
                                        <div className='form-group'>
                                            <input
                                                type="password"
                                                onChange={(e) => setPass({ ...pass, new: e.target.value })}
                                                name="password"
                                                className="form-control form-control-lg"
                                                placeholder="New Password"
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                onChange={(e) => setPass({ ...pass, confirm: e.target.value })}
                                                className="form-control form-control-lg"
                                                placeholder="Confirm New Password"
                                            />
                                        </div>
                                        <div className="btn-wrapper mt-0">
                                            <button
                                                className="theme-btn-1 btn btn-full-width-2"
                                                type="button"
                                                onClick={onChangePassword}
                                                disabled={loaderChangePass}
                                            >
                                                {loaderForgot ? (
                                                    <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                                                ) : (
                                                    "Submit"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-6 offset-lg-3">
                                {checkOtpForm ? (
                                    <form action="#" className="ltn__form-box">
                                        <input
                                            type="text"
                                            name="otp"
                                            className='ltn__form-box contact-form-box'
                                            onChange={(e) => setOtp(e.target.value)}
                                            placeholder="Enter OTP"
                                        />
                                        <div className="btn-wrapper mt-0">
                                            <button
                                                className="theme-btn-1 btn btn-full-width-2"
                                                type="button"
                                                onClick={onSubmitOtp}
                                                disabled={loaderForgotOtp}
                                            >
                                                {loaderForgotOtp ? (
                                                    <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                                                ) : (
                                                    "Verify"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                ) : (
                                    <>
                                        <div className="account-login-inner">
                                            <form action="#" className="ltn__form-box">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className='ltn__form-box contact-form-box'
                                                    onChange={(e) => setphone(e.target.value)}
                                                    placeholder="Enter you register phone no."
                                                />
                                                <div className="btn-wrapper mt-0">
                                                    <button
                                                        className="theme-btn-1 btn btn-full-width-2"
                                                        type="button"
                                                        onClick={onForgotPass}
                                                        disabled={loaderForgot}
                                                    >
                                                        {loaderForgot ? (
                                                            <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                )
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}
export default ForgotPassword;