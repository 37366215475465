import {
  ADD_POST,
  SET_AD_DETAILS,
  SET_MY_PROPERTIES,
  SET_PROPERTIES,
  SET_SEARCH_PROPERTIES,
  REMOVE_CURRENT_USER,
  SET_ALL_POSTS,
  SET_ALL_PROPERTIES,
} from "./types";
import {
  addPost,
  getPosts,
  getPost,
  searchPosts,
  filterPosts,
  editPostService,
  getAllPosts,
  getMyPosts,
} from "../../config/post";

// import { setRefreshToken } from '../../helpers/token'

export const addNewPost = (data, cb) => async (dispatch) => {
  try {
    const res = await addPost(data);
    dispatch({
      type: ADD_POST,
      payload: res.data,
    });
    // console.log(res.data)
    cb(res.data);
  } catch (e) {
    cb(e);

    console.log(e);
  }
};

export const updatePost = (data, cb) => async (dispatch) => {
  try {
    const res = await editPostService(data);
    dispatch({
      type: ADD_POST,
      payload: res.data,
    });
    // console.log(res.data)
    cb(res.data.status);
  } catch (e) {
    cb(e);

    console.log(e);
  }
};
export const getMyProperties = (data, cb) => async (dispatch) => {
  try {
    const res = await getMyPosts(data);
    dispatch({
      type: SET_MY_PROPERTIES,
      payload: res.data,
    });
    cb(res.data);
  } catch (e) {
    cb(e);
    console.log(e);
  }
};
export const getProperties = (data) => async (dispatch) => {
  try {
    const res = await getPosts(data);
    dispatch({
      type: SET_PROPERTIES,
      payload: res.data[0],
    });
    // cb(res.data)
  } catch (e) {
    // cb(e)
    console.log(e);
  }
};
export const getFilteredProperties = (data, cb) => async (dispatch) => {
  try {
    const res = await filterPosts(data);

    dispatch({
      type: SET_PROPERTIES,
      payload: res.data[0],
    });
    cb(res.data);
  } catch (e) {
    cb(e);
    console.log(e);
  }
};
export const getSearchedProperties = (data, cb) => async (dispatch) => {
  try {
    const res = await searchPosts(data);

    dispatch({
      type: SET_SEARCH_PROPERTIES,
      payload: res.data,
    });
    cb(res.data);
  } catch (e) {
    cb(e);
    console.tron.log(e);
  }
};
export const getPropertyDetails = (data) => async (dispatch) => {
  try {
    const res = await getPost(data);

    dispatch({
      type: SET_AD_DETAILS,
      payload: res.data,
    });
    // cb(res.data.post)
  } catch (e) {
    // cb(e)
    console.tron.log(e);
  }
};

export const getAllProperties = (data, cb) => async (dispatch) => {
  try {
    const res = await getAllPosts(data);

    dispatch({
      type: SET_ALL_PROPERTIES,
      payload: { posts: res.data.posts, total: res.data.total },
    });
  } catch (e) {
    console.log(e);
  }
};

// export const getUserProfile = (cb) => async (dispatch) => {

// 	try {
// 		const res = await apiGetProfile();

// 		if (res.data.data) {

// 			dispatch({
// 				type: SET_PROFILE,
// 				payload: res.data.data

// 			})
// 		}

// 		cb(res.data)

// 	} catch (e) {
// 		console.tron.log(e)
// 		cb(e.response.data)
// 	}

// }

// export const editUserProfile = (data, cb) => async (dispatch) => {

// 	try {
// 		const res = await apiUpdateProfile(data);

// 		if (res.data.data) {

// 			dispatch({
// 				type: SET_PROFILE,
// 				payload: res.data.data

// 			})
// 		}

// 		cb(res.data)

// 	} catch (e) {
// 		console.tron.log(e)
// 		cb(e.response.data)
// 	}

// }

// export const onChangePassword = (data, cb) => async (dispatch) => {

// 	try {
// 		const res = await apiChangePassword(data);

// 		if (res.data.data) {

// 			dispatch({
// 				type: SET_NEW_PASSWORD,
// 				payload: { profile: res.data.data }

// 			})
// 		}

// 		cb(res.data)

// 	} catch (e) {
// 		console.tron.log(e)
// 		cb(e.response.data)
// 	}

// }

export const logOutUser = () => async (dispatch) => {
  localStorage.removeItem("currentUser");

  dispatch({
    type: REMOVE_CURRENT_USER,
    payload: null,
  });
};

// export const setTokenExpired = (data) => async (dispatch) => {

// 	dispatch({
// 		type: SET_TOKEN_EXPIRED,
// 		payload: data
// 	})

// }
