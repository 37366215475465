import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { categories } from "../../helpers/json/business-categories";
import Autocomplete from "react-google-autocomplete";
import { API_KEY } from "../../config/axiosConfig";

const BannerV5 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    propertyKind: "",
    lookingFor: "",
    lookingTo: "",
    bedrooms: 0,
    search: "",
    postedBy: "",
    propertyType: "",
    location: "",
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [city, setCity] = useState("");
  const $ = window.$;
  const propertyTypeRef = useRef();
  const propertyKindRef = useRef();
  const postedByRef = useRef();
  const bedroomsRef = useRef();
  const lookingForRef = useRef();
  const categoryRef = useRef();
  const handleData = (name, val) => {
    setData({ ...data, [name]: val });
  };

  useEffect(() => {
    handleData("lookingFor", "rent");
    $(propertyTypeRef.current).niceSelect(); //initializing nice select
    $(propertyKindRef.current).niceSelect();
    $(postedByRef.current).niceSelect();
    $(bedroomsRef.current).niceSelect();
    $(lookingForRef.current).niceSelect();
    $(categoryRef.current).niceSelect();
  }, []);
  const onSearch = (e) => {
    e.preventDefault();
    let dataObj = {};
    if (tabIndex == 0) {
      let range = document.getElementById("rangeSlider");
      let splittedRange = String(range.value).split(" - ");
      let from = splittedRange[0].substring(1, splittedRange[0].length);
      let to = splittedRange[1].substring(1, splittedRange[1].length);
      dataObj = {
        ...data,
        propertyType: $(propertyTypeRef.current).val(),
        propertyKind: $(propertyKindRef.current).val(),
        postedBy: $(postedByRef.current).val(),
        bedrooms: $(bedroomsRef.current).val(),
        lookingFor: $(lookingForRef.current).val(),
        from,
        to,
      };
    } else {
      dataObj = {
        city,
        category: $(categoryRef.current).val(),
      };
    }

    let query = `?page=1`;
    for (const [key, value] of Object.entries(dataObj)) {
      if (value) {
        query += `&${key}=${value}`;
      }
    }
    let type = tabIndex == 0 ? "property" : "business";
    query += `&type=${type}`;
    navigate(`/properties-list${query}`, {
      state: {
        query,
        data: dataObj,
      },
    });
  };
  return (
    <div className="ltn__slider-area ltn__slider-4">
      <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
        {/* ltn__slide-item */}
        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60"
          data-bs-bg={publicUrl + "new-assets/img/slider/banner-11.png"}
          style={{
            backgroundImage: `url(${
              publicUrl + "new-assets/img/slider/banner-11.png"
            })`,
          }}
        >
          <div className="ltn__slide-item-inner text-left">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="slide-item-car-dealer-form">
                    <div className="ltn__car-dealer-form-tab">
                      <div className="ltn__tab-menu  text-uppercase">
                        <div className="nav">
                          <a
                            className="active show"
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_1"
                            onClick={() => setTabIndex(0)}
                          >
                            <i className="fas fa-home" />
                            Properties
                          </a>
                          <a
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_2"
                            className
                            onClick={() => setTabIndex(1)}
                          >
                            <i className="fas fa-shop" />
                            Business
                          </a>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="ltn__form_tab_1_1"
                        >
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                <select
                                  ref={lookingForRef}
                                  // onChange={(e) =>
                                  //   handleData("propertyType", e.target.value)
                                  // }
                                  className="nice-select"
                                >
                                  <option value={""}>Looking For</option>
                                  <option value="rent">Rent</option>
                                  <option value="sell">Buy</option>
                                  <option value="lease">Lease</option>
                                  <option value="pg">PG</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                <select
                                  ref={propertyTypeRef}
                                  // onChange={(e) =>
                                  //   handleData("propertyType", e.target.value)
                                  // }
                                  className="nice-select"
                                >
                                  <option value={""}>Property Type</option>
                                  <option value="Apartment">
                                    Apartment/Flat
                                  </option>
                                  <option value="Condos">Condos</option>
                                  <option value="Duplex">Duplex</option>
                                  <option value="House">House</option>
                                  <option value="Farm-House">Farm House</option>
                                  <option value="Industrial">Industrial</option>
                                  <option value="Land">Land</option>
                                  <option value="Plot">Plot</option>
                                  <option value="Office">Office</option>
                                  <option value="Cabin">Cabin</option>
                                  <option value="Villa">Villa</option>
                                  <option value="Mall">Mall</option>
                                  <option value="Hotel">Hotel</option>
                                  <option value="School">School</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
                                <select
                                  className="nice-select"
                                  ref={propertyKindRef}
                                >
                                  <option value="">Property Kind</option>
                                  <option value="Residential">
                                    Residential
                                  </option>
                                  <option value="Commercial">Commercial</option>
                                  <option value="Industrial">Industrial</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                <select
                                  ref={postedByRef}
                                  className="nice-select"
                                >
                                  <option value="">Posted By</option>
                                  <option value="Builder">Builder</option>
                                  <option value="Owner">Owner</option>
                                  <option value="Dealer">Dealer</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
                                <select
                                  ref={bedroomsRef}
                                  className="nice-select"
                                >
                                  <option value={""}>Bedrooms</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>4+</option>
                                </select>
                              </div>

                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  {/* <input
                                    type="text"
                                    name="location"
                                    placeholder="Enter Location"
                                    onChange={(e) =>
                                      handleData("location", e.target.value)
                                    }
                                  /> */}
                                  <Autocomplete
                                    apiKey={API_KEY}
                                    language="en"
                                    type="text"
                                    name="location"
                                    placeholder="Enter City"
                                    options={{
                                      componentRestrictions: { country: "in" },
                                    }}
                                    onPlaceSelected={(e) => {
                                      handleData(
                                        "location",
                                        e.formatted_address
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Max size (in sqft)"
                                  />
                                </div>
                              </div> */}
                              <div className="car-price-filter-range col-lg-12">
                                <div className="price_filter">
                                  <div className="price_slider_amount">
                                    {/* <input
                                      type="submit"
                                      defaultValue="Your range:"
                                    /> */}
                                    <input
                                      type="text"
                                      className="amount"
                                      name="price"
                                      id="rangeSlider"
                                      placeholder="Add Your Price"
                                    />
                                  </div>
                                  <div className="slider-range" />
                                </div>
                                <div className="btn-wrapper text-center go-top">
                                  {/* <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                                  <Link
                                    to=""
                                    onClick={onSearch}
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                  >
                                    Search Properties
                                  </Link>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_2">
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                <select
                                  ref={categoryRef}
                                  className="nice-select"
                                >
                                  <option value="">Category</option>
                                  {categories.map((item) => (
                                    <option key={item.id} value={item.value}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
                                {/* <input
                                  type="text"
                                  placeholder="City"
                                  onChange={(e) => }
                                /> */}
                                <Autocomplete
                                  apiKey={API_KEY}
                                  language="en"
                                  type="text"
                                  name="location"
                                  placeholder="City"
                                  options={{
                                    componentRestrictions: { country: "in" },
                                  }}
                                  onPlaceSelected={(e) => {
                                    setCity(e.formatted_address);
                                  }}
                                />
                              </div>

                              <div className="btn-wrapper text-center go-top">
                                <Link
                                  to=""
                                  onClick={onSearch}
                                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                >
                                  Search Business
                                </Link>
                              </div>
                            </form>
                          </div>
                        </div>
                        {/*<div className="tab-pane fade" id="ltn__form_tab_1_3">
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Property Type</option>
                                  <option>Apartment</option>
                                  <option>Co-op</option>
                                  <option>Condo</option>
                                  <option>Single Family Home</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Location</option>
                                  <option>chicago</option>
                                  <option>London</option>
                                  <option>Los Angeles</option>
                                  <option>New York</option>
                                  <option>New Jersey</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Sub Location</option>
                                  <option>Bayonne</option>
                                  <option>Greenville</option>
                                  <option>Manhattan</option>
                                  <option>Queens</option>
                                  <option>The Heights</option>
                                  <option>Upper East Side</option>
                                  <option>West Side</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Bedrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Min size (in sqft)"
                                  />
                                </div>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Max size (in sqft)"
                                  />
                                </div>
                              </div>
                              <div className="car-price-filter-range col-lg-12">
                                <div className="price_filter">
                                  <div className="price_slider_amount">
                                    <input
                                      type="submit"
                                      defaultValue="Your range:"
                                    />
                                    <input
                                      type="text"
                                      className="amount"
                                      name="price"
                                      placeholder="Add Your Price"
                                    />
                                  </div>
                                  <div className="slider-range" />
                                </div>
                                <div className="btn-wrapper text-center go-top">
                                 <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button>
                                  <Link
                                    to="/shop-right-sidebar"
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                  >
                                    Search Inventory
                                  </Link>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_4">
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Property Type</option>
                                  <option>Apartment</option>
                                  <option>Co-op</option>
                                  <option>Condo</option>
                                  <option>Single Family Home</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Location</option>
                                  <option>chicago</option>
                                  <option>London</option>
                                  <option>Los Angeles</option>
                                  <option>New York</option>
                                  <option>New Jersey</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-meter col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Sub Location</option>
                                  <option>Bayonne</option>
                                  <option>Greenville</option>
                                  <option>Manhattan</option>
                                  <option>Queens</option>
                                  <option>The Heights</option>
                                  <option>Upper East Side</option>
                                  <option>West Side</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-lg-4 col-md-6">
                                <select className="nice-select">
                                  <option>Bedrooms</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                </select>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Min size (in sqft)"
                                  />
                                </div>
                              </div>
                              <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-cog col-lg-4 col-md-6">
                                <div className="input-item input-item-name ltn__custom-icon">
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="Max size (in sqft)"
                                  />
                                </div>
                              </div>
                              <div className="car-price-filter-range col-lg-12">
                                <div className="price_filter">
                                  <div className="price_slider_amount">
                                    <input
                                      type="submit"
                                      defaultValue="Your range:"
                                    />
                                    <input
                                      type="text"
                                      className="amount"
                                      name="price"
                                      placeholder="Add Your Price"
                                    />
                                  </div>
                                  <div className="slider-range" />
                                </div>
                                <div className="btn-wrapper text-center go-top">
                                   <button type="submit" class="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> 
                                  <Link
                                    to="/shop-right-sidebar"
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                  >
                                    Search Inventory
                                  </Link>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerV5;
