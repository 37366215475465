// auth flow
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const ADD_POST = "ADD_POST";
export const SET_MY_PROPERTIES = "SET_MY_PROPERTIES";
export const SET_PROPERTIES = "SET_PROPERTIES";
export const SET_SEARCH_PROPERTIES = "SET_SEARCH_PROPERTIES";
export const SET_AD_DETAILS = "SET_AD_DETAILS";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_ALL_POSTS = "SET_ALL_POSTS";
//Admin

export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_ALL_PROPERTIES = "SET_ALL_PROPERTIES";
export const SET_ALL_BOOKINGS = "SET_ALL_BOOKINGS";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";

// businesss
export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";
export const SET_MY_BUSINESS = "SET_MY_BUSINESS";
export const SET_BUSINESSES = "SET_BUSINESSES";
export const SET_SEARCH_BUSINESS = "SET_SEARCH_BUSINESS";
export const SET_FILTERED_BUSINESS = "SET_FILTERED_BUSINESS";
export const SET_ALL_BUSINESS = "SET_ALL_BUSINESS";
