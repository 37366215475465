import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./shop-sidebar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProperties, getFilteredProperties } from "../../redux/actions/post";
import { getFilteredBusiness } from "../../redux/actions/business";
import { categories } from "../../helpers/json/business-categories";

function ShopGridV1() {
  const properties = useSelector((state) => state.post.properties);
  const totalProperties = useSelector((state) => state.post.totalProperties);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState("property");
  const [page, setPage] = useState(1);
  const location = useLocation();
  const $ = window.$;
  const pageLimitRef = useRef();
  const propertyTypeRef = useRef();
  const bedroomsRef = useRef();
  const categoryRef = useRef();
  const bsCategoryRef = useRef();
  const [pageLimit, setPageLimit] = useState(12);
  const [data, setData] = useState({
    propertyKind: "",
    lookingFor: "",
    lookingTo: "",
    bedrooms: 0,
    search: "",
    postedBy: "",
    propertyType: "",
    from: "",
    to: "",
  });
  const [range, setRange] = useState({
    from: 0,
    to: 0,
    index: "",
  });
  useEffect(() => {
    $(pageLimitRef.current).niceSelect(); //initializing nice select
    window.scroll({
      top: 0,
    });
    if (location.search) {
      onHomePageRedirect();
    } else {
      onGetAllProperties();
    }
  }, [page]);

  const onGetAllProperties = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      let queryParams = `?showMyAds=${false}&longitude=${
        position.coords.longitude
      }&latitude=${position.coords.latitude}&page=${page}&limit=${pageLimit}`;
      dispatch(getProperties(queryParams, (e) => {}));
    });
  };

  const onHomePageRedirect = () => {
    let query = location.search;

    const urlParams = new URLSearchParams(location.search); //set values on page from url params
    let type;
    urlParams.forEach((value, key) => {
      if (key == "propertyType") {
        $(propertyTypeRef.current).val(value);
      }
      if (key == "lookingFor") {
        $(categoryRef.current).val(value);
      }
      if (key == "bedrooms") {
        $(bedroomsRef.current).val(value);
      }
      if (key == "type") {
        type = value;
      }
    });
    setSearchType(type);

    query += `${page > 1 ? page : ""}&limit=${pageLimit}`;
    if (type == "property") {
      dispatch(getFilteredProperties(query, (e) => {}));
    } else if ((type = "business")) {
      dispatch(getFilteredBusiness(query, (e) => {}));
    }
    setData(location.state?.data);
  };

  const onSearch = (e) => {
    e.preventDefault();
    // let dataObj = {};
    // if (searchType == "property") {
    //   let range = document.getElementById("rangeSlider");
    //   let splittedRange = String(range.value).split(" - ");
    //   let from = splittedRange[0].substring(1, splittedRange[0].length);
    //   let to = splittedRange[1].substring(1, splittedRange[1].length);

    //   dataObj = {
    //     ...data,
    //     lookingFor: $(categoryRef.current).val(),
    //     propertyType: $(propertyTypeRef.current).val(),
    //     bedrooms: $(bedroomsRef.current).val(),
    //     from,
    //     to,
    //     type: searchType,
    //   };
    // } else if (searchType == "business") {
    //   dataObj = {
    //     query: data?.search || "",
    //     category:
    //       $(bsCategoryRef.current).val() == "all"
    //         ? ""
    //         : $(bsCategoryRef.current).val(),
    //     type: searchType,
    //   };
    // }
    let pageLimit = parseInt($(pageLimitRef.current).val());
    setPageLimit(pageLimit);

    let query = `?page=${page}&search=${data?.search}`;

    // for (const [key, value] of Object.entries(dataObj)) {
    //   if (value) {
    //     query += `&${key}=${value}`;
    //   }
    // }

    navigate(`/properties-list${query}`);
    query += `&limit=${pageLimit}`;
    if (searchType == "property") {
      dispatch(getFilteredProperties(query, (e) => {}));
    } else if (searchType == "business") {
      dispatch(getFilteredBusiness(query, (e) => {}));
    }
  };

  const renderProperties = (item, layout) => {
    return (
      <>
        {layout == "grid" ? (
          <div className="col-xl-4 col-sm-6 col-12">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
              <div className="product-img go-top">
                <Link
                  to={
                    searchType == "property"
                      ? `/property-details/${item.title}`
                      : `/business-details/${item.title}`
                  }
                  state={{ id: item._id }}
                >
                  <img
                    src={
                      item.image ? item.image : "assets/img/demo/property-3.jpg"
                    }
                    width={"100%"}
                    height={"50%"}
                  />
                </Link>
              </div>
              <div className="product-info">
                <div
                  className={`product-badge ${
                    searchType == "business" && "d-none"
                  } `}
                >
                  <ul>
                    <li className="sale-badg">
                      For {item.lookingFor == "sell" ? "sale" : item.lookingFor}
                    </li>
                  </ul>
                </div>
                <h2 className="product-title go-top">
                  <Link
                    to={
                      searchType == "property"
                        ? `/property-details/${item.title}`
                        : `/business-details/${item.title}`
                    }
                    state={{ id: item._id }}
                    alt="#"
                  >
                    {item.title}
                  </Link>
                </h2>
                {searchType == "business" && (
                  <div className="product-badge">
                    {" "}
                    <ul>
                      <li className="sale-badg">
                        {
                          categories.filter(
                            (obj) => obj.value == item?.category
                          )[0]?.title
                        }
                      </li>
                    </ul>
                  </div>
                )}
                <div className="product-img-location go-top">
                  <ul>
                    <li>
                      <Link
                        to={
                          searchType == "property"
                            ? `/property-details/${item.title}`
                            : `/business-details/${item.title}`
                        }
                        state={{ id: item._id }}
                      >
                        <i className="flaticon-pin" />
                        {item.city}
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul
                  className={`ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief ${
                    searchType == "business" && "d-none"
                  } `}
                >
                  <li>
                    <span>{item.bedrooms} </span>
                    Bed
                  </li>
                  <li>
                    <span>{item.bathrooms} </span>
                    Bath
                  </li>
                  <li>
                    <span>{item.carpet} </span>
                    Sq. Ft.
                  </li>
                </ul>
                {/* <div className="product-hover-action">
                  <ul>
                    <li onClick={() => setSelectedProperty(item)}>
                      <a
                        href="#"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#quick_view_modal"
                      >
                        <i className="flaticon-expand" />
                      </a>
                    </li>
                     <li>
                      <a
                        href="#"
                        title="Wishlist"
                        data-bs-toggle="modal"
                        data-bs-target="#liton_wishlist_modal"
                      >
                        <i className="flaticon-heart-1" />
                      </a>
                    </li> 
                    <li className="go-top">
                      <Link to="/property-details" title="Product Details">
                        <i className="flaticon-add" />
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className={`product-info-bottom`}>
                <div
                  className={`product-price  ${
                    searchType == "business" && "d-none"
                  }`}
                >
                  <span>
                    Rs.{" "}
                    {item.price
                      ?.toString()
                      .replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")}
                    {item.lookingFor && item.lookingFor == "rent" && (
                      <label>/Month</label>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-lg-6">
            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
              <div className="product-img go-top">
                <Link
                  to={
                    searchType == "property"
                      ? `/property-details/${item.title}`
                      : `/business-details/${item.title}`
                  }
                  state={{ id: item._id }}
                >
                  <img
                    src={
                      item.image ? item.image : "new-assets/img/product-3/1.jpg"
                    }
                    alt="#"
                  />
                </Link>
              </div>
              <div className="product-info">
                {searchType == "business" && (
                  <div className="product-badge">
                    {" "}
                    <ul>
                      <li className="sale-badg">
                        {
                          categories.filter(
                            (obj) => obj.value == item?.category
                          )[0]?.title
                        }
                      </li>
                    </ul>
                  </div>
                )}
                <div
                  className={`product-badge-price ${
                    searchType == "business" && "d-none"
                  } `}
                >
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badg">
                        For{" "}
                        {item.lookingFor == "sell" ? "sale" : item.lookingFor}
                      </li>
                    </ul>
                  </div>
                  <div className="product-price">
                    <span>
                      Rs. {item.price}
                      {item.lookingFor == "rent" && <label>/Month</label>}
                    </span>
                  </div>
                </div>
                <h2 className="product-title go-top">
                  <Link
                    to={
                      searchType == "property"
                        ? `/property-details/${item.title}`
                        : `/business-details/${item.title}`
                    }
                    state={{ id: item._id }}
                  >
                    {item.title}
                  </Link>
                </h2>
                <div className="product-img-location go-top">
                  <ul>
                    <li>
                      <Link
                        to={
                          searchType == "property"
                            ? `/property-details/${item.title}`
                            : `/business-details/${item.title}`
                        }
                        state={{ id: item._id }}
                      >
                        <i className="flaticon-pin" /> {item.city}
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul
                  className={`ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief ${
                    searchType == "business" && "d-none"
                  } `}
                >
                  <li>
                    <span>{item.bedrooms} </span>
                    Bed
                  </li>
                  <li>
                    <span>{item.bathrooms} </span>
                    Bath
                  </li>
                  <li>
                    <span>{item.carpet} </span>
                    Sq. Ft.
                  </li>
                </ul>
              </div>
              <div className="product-info-bottom">
                {/* <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link  to={
                    searchType == "property"
                      ? `/property-details/${item.title}`
                      : `/business-details/${item.title}`
                  }
                state={{ id: item._id }}>
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                              <div className="agent-brief">
                                <h6>
                                  <Link to="/team-details">William Seklo</Link>
                                </h6>
                                <small>Estate Agents</small>
                              </div>
                            </div> */}
                {/* <div className="product-hover-action">
                  <ul>
                    <li onClick={() => setSelectedProperty(item)}>
                      <a
                        href="#"
                        title="Quick View"
                        data-bs-toggle="modal"
                        data-bs-target="#quick_view_modal"
                      >
                        <i className="flaticon-expand" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        title="Wishlist"
                        data-bs-toggle="modal"
                        data-bs-target="#liton_wishlist_modal"
                      >
                        <i className="flaticon-heart-1" />
                      </a>
                    </li>
                    <li className="go-top">
                      <Link
                         to={
                    searchType == "property"
                      ? `/property-details/${item.title}`
                      : `/business-details/${item.title}`
                  }
                        state={{ id: item._id }}
                        title="Product Details"
                      >
                        <i className="flaticon-add" />
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  mb-100">
              <div className="ltn__shop-options">
                <ul className="justify-content-start">
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                        >
                          <i className="fas fa-th-large" />
                        </a>
                        <a data-bs-toggle="tab" href="#liton_product_list">
                          <i className="fas fa-list" />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="showing-product-number text-right">
                      <span>Showing 1–12 of 18 results</span>
                    </div>
                  </li>
                  {/* <li>
                    <div className="short-by text-center">
                      <select className="nice-select">
                        <option>Default Sorting</option>
                        <option>Sort by popularity</option>
                        <option>Sort by new arrivals</option>
                        <option>Sort by price: low to high</option>
                        <option>Sort by price: high to low</option>
                      </select>
                    </div>
                  </li> */}
                  <li>
                    <div className="short-by text-center">
                      <select ref={pageLimitRef} className="nice-select">
                        <option value={"12"}>Per Page: 12</option>
                        <option value={"20"}>Per Page: 20</option>
                        <option value={"30"}>Per Page: 30</option>
                        <option value={"50"}>Per Page: 50</option>
                        <option value={"100"}>Per Page: 100</option>
                      </select>
                    </div>
                  </li>
                  <li>
                    <div className="text-center go-top">
                      <a
                        href=""
                        onClick={onSearch}
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                      >
                        Update results
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        <div className="ltn__search-widget mb-30">
                          <form action="#" onSubmit={onSearch}>
                            <input
                              type="text"
                              name="search"
                              onChange={(e) =>
                                setData({ ...data, search: e.target.value })
                              }
                              placeholder="Search your keyword..."
                            />
                            <button type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </form>
                        </div>
                      </div>
                      {/* ltn__product-item */}
                      {properties &&
                        properties?.length > 0 &&
                        properties?.map((i) => {
                          return renderProperties(i, "grid");
                        })}

                      {/* ltn__product-item */}
                      {/* <div className="col-xl-6 col-sm-6 col-12">
                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                          <div className="product-img go-top">
                            <Link to="/property-details">
                              <img
                                src={publicUrl + "assets/img/product-3/2.jpg"}
                                alt="#"
                              />
                            </Link>
                            <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link to="/shop">
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badg">For Sale</li>
                              </ul>
                            </div>
                            <h2 className="product-title go-top">
                              <Link to="/property-details">
                                New Apartment Nice View
                              </Link>
                            </h2>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" /> Belmont
                                    Gardens, Chicago
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                              <li>
                                <span>3 </span>
                                Bed
                              </li>
                              <li>
                                <span>2 </span>
                                Bath
                              </li>
                              <li>
                                <span>3450 </span>
                                Square Ft
                              </li>
                            </ul>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="flaticon-expand" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="flaticon-heart-1" />
                                  </a>
                                </li>
                                <li className="go-top">
                                  <Link
                                    to="/property-details"
                                    title="Product Details"
                                  >
                                    <i className="flaticon-add" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info-bottom">
                            <div className="product-price">
                              <span>
                                $34,900<label>/Month</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* ltn__product-item */}
                      {/* <div className="col-xl-6 col-sm-6 col-12">
                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                          <div className="product-img go-top">
                            <Link to="/property-details">
                              <img
                                src={publicUrl + "assets/img/product-3/3.jpg"}
                                alt="#"
                              />
                            </Link>
                            <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link to="/shop">
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badg">For Rent</li>
                              </ul>
                            </div>
                            <h2 className="product-title go-top">
                              <Link to="/property-details">
                                New Apartment Nice View
                              </Link>
                            </h2>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" /> Belmont
                                    Gardens, Chicago
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                              <li>
                                <span>3 </span>
                                Bed
                              </li>
                              <li>
                                <span>2 </span>
                                Bath
                              </li>
                              <li>
                                <span>3450 </span>
                                Square Ft
                              </li>
                            </ul>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="flaticon-expand" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="flaticon-heart-1" />
                                  </a>
                                </li>
                                <li className="go-top">
                                  <Link
                                    to="/property-details"
                                    title="Product Details"
                                  >
                                    <i className="flaticon-add" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info-bottom">
                            <div className="product-price">
                              <span>
                                $34,900<label>/Month</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* ltn__product-item */}
                      {/* <div className="col-xl-6 col-sm-6 col-12">
                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                          <div className="product-img go-top">
                            <Link to="/property-details">
                              <img
                                src={publicUrl + "assets/img/product-3/4.jpg"}
                                alt="#"
                              />
                            </Link>
                            <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link to="/shop">
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badg">For Rent</li>
                              </ul>
                            </div>
                            <h2 className="product-title go-top">
                              <Link to="/property-details">
                                New Apartment Nice View
                              </Link>
                            </h2>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" /> Belmont
                                    Gardens, Chicago
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                              <li>
                                <span>3 </span>
                                Bed
                              </li>
                              <li>
                                <span>2 </span>
                                Bath
                              </li>
                              <li>
                                <span>3450 </span>
                                Square Ft
                              </li>
                            </ul>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="flaticon-expand" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="flaticon-heart-1" />
                                  </a>
                                </li>
                                <li className="go-top">
                                  <Link
                                    to="/property-details"
                                    title="Product Details"
                                  >
                                    <i className="flaticon-add" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info-bottom">
                            <div className="product-price">
                              <span>
                                $34,900<label>/Month</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* ltn__product-item */}
                      {/* <div className="col-xl-6 col-sm-6 col-12">
                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                          <div className="product-img go-top">
                            <Link to="/property-details">
                              <img
                                src={publicUrl + "assets/img/product-3/5.jpg"}
                                alt="#"
                              />
                            </Link>
                            <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link to="/shop">
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badg">For Rent</li>
                              </ul>
                            </div>
                            <h2 className="product-title go-top">
                              <Link to="/property-details">
                                New Apartment Nice View
                              </Link>
                            </h2>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" /> Belmont
                                    Gardens, Chicago
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                              <li>
                                <span>3 </span>
                                Bed
                              </li>
                              <li>
                                <span>2 </span>
                                Bath
                              </li>
                              <li>
                                <span>3450 </span>
                                Square Ft
                              </li>
                            </ul>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="flaticon-expand" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="flaticon-heart-1" />
                                  </a>
                                </li>
                                <li className="go-top">
                                  <Link
                                    to="/property-details"
                                    title="Product Details"
                                  >
                                    <i className="flaticon-add" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info-bottom">
                            <div className="product-price">
                              <span>
                                $34,900<label>/Month</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* ltn__product-item */}
                      {/* <div className="col-xl-6 col-sm-6 col-12">
                        <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                          <div className="product-img go-top">
                            <Link to="/property-details">
                              <img
                                src={publicUrl + "assets/img/product-3/6.jpg"}
                                alt="#"
                              />
                            </Link>
                            <div className="real-estate-agent">
                              <div className="agent-img">
                                <Link to="/shop">
                                  <img
                                    src={
                                      publicUrl + "assets/img/blog/author.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badg">For Rent</li>
                              </ul>
                            </div>
                            <h2 className="product-title go-top">
                              <Link to="/property-details">
                                New Apartment Nice View
                              </Link>
                            </h2>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <Link to="/contact">
                                    <i className="flaticon-pin" /> Belmont
                                    Gardens, Chicago
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                              <li>
                                <span>3 </span>
                                Bed
                              </li>
                              <li>
                                <span>2 </span>
                                Bath
                              </li>
                              <li>
                                <span>3450 </span>
                                Square Ft
                              </li>
                            </ul>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="flaticon-expand" />
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="flaticon-heart-1" />
                                  </a>
                                </li>
                                <li className="go-top">
                                  <Link
                                    to="/property-details"
                                    title="Product Details"
                                  >
                                    <i className="flaticon-add" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info-bottom">
                            <div className="product-price">
                              <span>
                                $34,900<label>/Month</label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/*  */}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        <div className="ltn__search-widget mb-30">
                          <form action="#">
                            <input
                              type="text"
                              name="search"
                              placeholder="Search your keyword..."
                            />
                            <button type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </form>
                        </div>
                      </div>
                      {/* ltn__product-item */}
                      {properties &&
                        properties?.length > 0 &&
                        properties?.map((i) => {
                          return renderProperties(i, "list");
                        })}

                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ltn__pagination-area text-center">
                <div className="ltn__pagination">
                  <ul>
                    {page > 1 ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page - 1)}
                        >
                          <i className="fas fa-angle-double-left" />
                        </Link>
                      </li>
                    ) : null}
                    {page * +pageLimit < totalProperties ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 1)}
                        >
                          {page + 1}
                        </Link>
                      </li>
                    ) : null}
                    {(page + 2) * +pageLimit < totalProperties ? (
                      <li className="active">
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 2)}
                        >
                          {page + 2}
                        </Link>
                      </li>
                    ) : null}
                    {(page + 3) * +pageLimit < totalProperties ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 3)}
                        >
                          {page + 3}
                        </Link>
                      </li>
                    ) : null}
                    {(page + 4) * +pageLimit < totalProperties ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 4)}
                        >
                          {page + 4}
                        </Link>
                      </li>
                    ) : null}
                    {(page + 5) * +pageLimit < totalProperties ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 5)}
                        >
                          {page + 5}
                        </Link>
                      </li>
                    ) : null}
                    {page * +pageLimit < totalProperties ? (
                      <li>
                        <Link
                          to="#"
                          onClick={() => setPage((page) => page + 1)}
                        >
                          <i className="fas fa-angle-double-right" />
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
            {/* <Sidebar
              totalProperties={totalProperties}
              data={data}
              setData={setData}
              propertyTypeRef={propertyTypeRef}
              bedroomsRef={bedroomsRef}
              categoryRef={categoryRef}
              location={location}
              searchType={searchType}
              setSearchType={setSearchType}
              bsCategoryRef={bsCategoryRef}
            /> */}
          </div>
        </div>
      </div>

      {/* <div className="ltn__modal-area ltn__add-to-cart-modal-area">
        <div className="modal fade" id="liton_wishlist_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-img">
                          <img
                            src={publicUrl + "assets/img/product/7.png"}
                            alt="#"
                          />
                        </div>
                        <div className="modal-product-info go-top">
                          <h5>
                            <Link to="/property-details">
                              Brake Conversion Kit
                            </Link>
                          </h5>
                          <p className="added-cart">
                            <i className="fa fa-check-circle" /> Successfully
                            added to your Wishlist
                          </p>
                          <div className="btn-wrapper">
                            <Link
                              to="/wishlist"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              View Wishlist
                            </Link>
                          </div>
                        </div>
                        {/* additional-info 
                        <div className="additional-info d-none">
                          <p>
                            We want to give you <b>10% discount</b> for your
                            first order, <br /> Use discount code at checkout
                          </p>
                          <div className="payment-method">
                            <img
                              src={publicUrl + "assets/img/icons/payment.png"}
                              alt="#"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                  {/* <i class="fas fa-times"></i>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-img">
                          <img src={selectedProperty?.image} alt="#" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="modal-product-info">
                          {/* <div className="product-ratting">
                            <ul>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fas fa-star-half-alt" />
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="far fa-star" />
                                </a>
                              </li>
                              <li className="review-total">
                                {" "}
                                <a href="#"> ( 95 Reviews )</a>
                              </li>
                            </ul>
                          </div> */}
      {/* <h3>{selectedProperty?.title}</h3>
                          <div className="product-price">
                            <span>
                              Rs. {selectedProperty?.price}{" "}
                              {selectedProperty?.lookingFor == "rent" &&
                                "/ Month"}
                            </span> */}
      {/* <del>$165.00</del> 
                          </div>
                          <div className="modal-product-meta ltn__property-details-menu-1">
                            <ul>
                              <li>
                                <strong>Bedrooms</strong>
                                <p>{selectedProperty?.bedrooms}</p>
                              </li>
                            </ul>
                          </div>
                          <div className="ltn__property-details-menu-2">
                            <ul>
                              <li>
                                <div className="cart-plus-minus">
                                  <input
                                    type="text"
                                    defaultValue="02"
                                    name="qtybutton"
                                    className="cart-plus-minus-box"
                                  />
                                </div>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  className="theme-btn-1 btn btn-effect-1"
                                  title="Add to Cart"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_to_cart_modal"
                                >
                                  <i className="fas fa-shopping-cart" />
                                  <span>ADD TO CART</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <hr />
                          <div className="ltn__social-media">
                            <ul>
                              <li>Share:</li>
                              <li>
                                <a href="#" title="Facebook">
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Twitter">
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Linkedin">
                                  <i className="fab fa-linkedin" />
                                </a>
                              </li>
                              <li>
                                <a href="#" title="Instagram">
                                  <i className="fab fa-instagram" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__modal-area ltn__add-to-cart-modal-area">
        <div className="modal fade" id="add_to_cart_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-img">
                          <img
                            src={publicUrl + "assets/img/product/1.png"}
                            alt="#"
                          />
                        </div>
                        <div className="modal-product-info go-top">
                          <h5 className="go-top">
                            <Link to="/property-details">
                              Brake Conversion Kit
                            </Link>
                          </h5>
                          <p className="added-cart">
                            <i className="fa fa-check-circle" />
                            Successfully added to your Cart
                          </p>
                          <div className="btn-wrapper">
                            <Link
                              to="/cart"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              View Cart
                            </Link>
                            <Link
                              to="/checkout"
                              className="theme-btn-2 btn btn-effect-2"
                            >
                              Checkout
                            </Link>
                          </div>
                        </div>
                        {/* additional-info 
                        <div className="additional-info d-none">
                          <p>
                            We want to give you <b>10% discount</b> for your
                            first order, <br /> Use discount code at checkout
                          </p>
                          <div className="payment-method">
                            <img
                              src={publicUrl + "assets/img/icons/payment.png"}
                              alt="#"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ShopGridV1;
