import React from 'react';
import Navbar from '../../new-components/global-components/navbar';
import PageHeader from '../../new-components/global-components/page-header';

import Footer from '../../new-components/global-components/footer';
import BlogDetailsComponent from '../../new-components/blog-components/blog-details';

const BlogDetails = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Blog Detail" />
        <BlogDetailsComponent />
        <Footer />
    </div>
}

export default BlogDetails

