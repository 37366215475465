import React from 'react';
import FaqV1 from '../../new-components/section-components/faq-v1';
import Footer_v1 from '../../new-components/global-components/footer';
import Navbar from '../../new-components/global-components/navbar';

const FAQ = () => {

    return (
        <>
            <Navbar />
            <FaqV1 />
            <Footer_v1 />
        </>
    )
}

export default FAQ;