import * as React from "react";
import Home from "./home";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";

import Login from "./login";
import Register from "./register";
// import ForgotPassword from './forgotpassword';
import AddPost from "./addpost";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/actions/auth";
import PropertiesList from "./properties";
import PropertyDetails from "./propertydetails";
import BusinessDetails from "./businessdetails";

import ProfileDashboard from "./profile";
import AdminDashboard from "./adminprofile";
import Contact from "../new-components/contact";
import EditPost from "./editpost";
import TermOfUse from "./termofuse";
import Privacy from "./privacy";
import MyAccount from "./myaccount";
import ForgotPassword from '../new-components/section-components/forgot-password';
import ErrorPage from '../new-components/404';
import About from "./about";
import BlogList from "./bloglist";
import BlogDetails from "./blogdetail";
import FAQ from "./faq";
import Disclaimer from "./disclaimer";
import AddBlog from "./admin/blog/Add";
import BlogsListAdmin from "./admin/blog";

const RootStackScreen = () => {
  // const [user, setUser] = React.useState();
  const checkLoggedInUser = () => {
    // setUser(JSON.parse(localStorage.getItem("user")));
  };
  React.useEffect(() => {
    checkLoggedInUser();
    getUser();
  }, []);

  const userRedux = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const getUser = () => {
    let token = localStorage.getItem("token");
    if (token !== null && userRedux == null) {
      dispatch(setCurrentUser((e) => { }));
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<Home checkLoggedInUser={checkLoggedInUser} />}
      ></Route>
      <Route path="/signup" element={<Register />}></Route>
      <Route
        path="/login"
        element={
          <Login checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/forget-password"
        element={
          <ForgotPassword />
        }
      ></Route>

      <Route
        path="/properties-list"
        element={
          <PropertiesList
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>

      <Route
        path="/faq"
        element={
          <FAQ
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>
      <Route
        path="/disclaimer"
        element={
          <Disclaimer
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>
      {/* <Route path="/forgot-password" element={<ForgotPassword />}>
            </Route> */}
      {/* {userRedux && (
        <> */}
      <Route
        path="/property-details/:id"
        element={
          <PropertyDetails
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>
      <Route
        path="/business-details/:id"
        element={
          <BusinessDetails
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>

      <Route
        path="/add-post"
        element={<AddPost checkLoggedInUser={checkLoggedInUser} />}
      ></Route>
      <Route
        path="/property-details"
        element={
          <PropertyDetails
            checkLoggedInUser={checkLoggedInUser}
            getUser={getUser}
          />
        }
      ></Route>
      {/* </>
      )} */}
      <Route
        path="/contact"
        element={
          <Contact checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/about"
        element={
          <About checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/blogs"
        element={
          <BlogList checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/blog"
        element={
          <BlogDetails checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/admin/add-blog"
        element={
          <AddBlog checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/admin/blogs"
        element={
          <BlogsListAdmin checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      <Route
        path="/term-of-use"
        element={<TermOfUse getUser={getUser} />}
      ></Route>
      <Route path="/privacy" element={<Privacy getUser={getUser} />}></Route>
      <Route path="/edit-post" element={<EditPost />}></Route>

      {userRedux && userRedux?.role == "admin" && token !== null ? (
        <Route path="/admin" element={<AdminDashboard />} />
      ) : (
        <>
          <Route path="/my-account" element={<MyAccount />}></Route>
        </>
      )}
      <Route path="/*" element={<ErrorPage />}></Route>

    </Routes>
  );
};
export default RootStackScreen;
