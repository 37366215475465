import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoaderSvg from "../../assets/img/loader.svg";
import { updateUser, getProfile } from "../../config/user";
import { toast } from "react-toastify";
import { updateCurrentUser } from "../../redux/actions/auth";
function UpdateProfile() {
  const user = useSelector((state) => state.auth.user);
  const [loaderPofile, setLoaderProfile] = useState(false);

  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [profession, setProfession] = useState();
  const [bio, setBio] = useState();
  const [phone, setPhone] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setUser();
  }, [user]);

  const setUser = () => {
    setFullName(user?.fullName);
    setEmail(user?.email);
    setBio(user?.bio);
    setProfession(user?.profession);
    setPhone(user?.phone);
  };
  const onUpdateProfile = () => {
    try {
      setLoaderProfile(true);
      dispatch(
        updateCurrentUser({ fullName, email, bio, profession }, (e) => {
          //   setUser();
        })
      );
      toast("Profile Updated Successfully");
      setLoaderProfile(false);
    } catch (e) {
      setLoaderProfile(false);
      console.log(e);
    }
  };
  return (
    <div>
      <div className="ltn__myaccount-tab-content-inner">
        {/* <p>
                              The following addresses will be used on the
                              checkout page by default.
                            </p> */}
        <div className="ltn__form-box">
          <form>
            <div className="row mb-50">
              <div className="col-md-6">
                <label>Name:</label>
                <input
                  type="text"
                  name="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label>Phone:</label>
                <input type="text" name="phone" value={phone} disabled />
              </div>

              <div className="col-md-6">
                <label>Email:</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@example.com"
                />
              </div>
              <div className="col-md-6">
                <label>Profession:</label>
                <input
                  type="text"
                  name="profession"
                  placeholder="Profession"
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <label>Bio:</label>
                <textarea
                  type="text"
                  name="bio"
                  value={bio}
                  placeholder="Write something about yourself"
                  onChange={(e) => setBio(e.target.value)}
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                onClick={onUpdateProfile}
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                disabled={loaderPofile}
              >
                {loaderPofile ? (
                  <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
