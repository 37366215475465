import React, { useEffect, useState } from "react";
import {
  LoginService,
  validateUser,
  sendOtp,
  verifyOtp,
  forgotPasswordService,
} from "../../config/user";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCurrentUser } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import LoaderSvg from "../../assets/img/loader.svg";
import LoginComponent from "../../new-components/login";
const Login = ({ checkLoggedInUser, getUser }) => {
  const [phone, setphone] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderForgot, setLoaderForgot] = useState(false);
  const [checkOtpForm, setCheckOtpForm] = useState(false);
  const [showPassInputs, setShowPassInputs] = useState(false);
  const [loaderForgotOtp, setLoaderForgotOtp] = useState(false);
  const [pass, setPass] = useState({
    new: "",
    confirm: "",
  });
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const res = await LoginService({
        phone,
        password,
      });

      if (res.data.token) {
        toast("Login Succesfully");
        let user = {
          role: res.data.role,
          admin: res.data.admin,
        };
        setLoader(false);
        localStorage.setItem("token", res.data.token);
        dispatch(setCurrentUser(res.data.user));
        checkLoggedInUser();
        getUser();
        navigate("/", { replace: true });
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const onForgotPass = async () => {
    if (!phone) {
      toast("Enter phone");
      return;
    }
    try {
      let validateRes = await validateUser({
        phone,
      });
      if (validateRes.data.success) {
        await onSendOtp();
        setCheckOtpForm(true);
      } else {
        toast("Invalid Phone");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSendOtp = async () => {
    setLoaderForgot(true);
    try {
      const otpRes = await sendOtp({ phone });

      if (otpRes.data.Status === "Success") {
        localStorage.setItem("otpSessionId", otpRes.data.Details);
      }
      setLoaderForgot(false);
    } catch (err) {
      setLoaderForgot(false);
      let msg = err.message;

      if (msg == "Network Error") {
        toast(
          "Network Error.",
          "error",
          "Kindly check your interent connection."
        );
      }
    }
  };
  const onSubmitOtp = async (e) => {
    e.preventDefault();
    setLoaderForgot(true);
    try {
      let sessionId = localStorage.getItem("otpSessionId");
      const res = await verifyOtp({
        otp,
        phone,
        sessionId,
      });

      if (res.data.Status === "Success") {
        toast(res.data.Details);

        setShowPassInputs(true);
        setLoaderForgot(false);
        navigate("/login", { replace: true });
        // document.getElementById('loginLabel').click();
      }
    } catch (err) {
      setLoaderForgot(false);
      console.log(err);
    }
  };

  const onChangePassword = async (e) => {
    e.preventDefault();

    if (pass.new != pass.confirm) {
      toast("Confirm Password doesn't match");
      return;
    }
    setLoaderForgot(true);
    try {
      const res = await forgotPasswordService({
        phone,
        password: pass.new,
      });

      if (res.data.status) {
        toast(res.data.message);
        setCheckOtpForm(false);
        setShowPassInputs(false);
        setLoaderForgot(false);
      }
    } catch (e) {
      setLoaderForgot(false);
      console.log(e);
    }
  };
  return (
    <LoginComponent checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
  );
};

export default Login;
