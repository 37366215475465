import React from "react";
import Navbar from "../../new-components/global-components/navbar";
import PageHeader from "../../new-components/global-components/page-header";
import ShopRightSidebar from "../../new-components/shop-components/shop-right-sidebar";
import CallToActionV1 from "../../new-components/section-components/call-to-action-v1";
import Footer from "../../new-components/global-components/footer";

const PropertiesList = () => {
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Shop Right Sidebar" /> */}
      <ShopRightSidebar />
      <CallToActionV1 />
      <Footer />
    </div>
  );
};

export default PropertiesList;
