export const categories = [
  {
    id: 0,
    title: "All",
    value: "all",
  },
  {
    id: 1,
    title: "Architects & Interior Designers",
    value: "architects-and-interior-designers",
  },

  {
    id: 2,
    title: "Basic Amenities",
    value: "basic-amenities",
  },
  {
    id: 3,
    title: "Banquet Hall And Lounge",
    value: "banquet-hall-and-lounge",
  },
  {
    id: 4,
    title: "Building Material Suppliers",
    value: "building-material-suppliers",
  },
  {
    id: 5,
    title: "Construction Contractors",
    value: "construction-contractors",
  },

  {
    id: 6,
    title: "Documentation Consultants(regarding Property)",
    value: "documentation-consultants",
  },
  {
    id: 7,
    title: "Electicals Contractors",
    value: "electicals-contractors",
  },
  {
    id: 8,
    title: "Event Management",
    value: "event-management",
  },
  {
    id: 9,
    title: "Gardening  & Landscaping Contractors",
    value: "gardening-and-landscaping-contractors",
  },

  {
    id: 10,
    title: "Home & Construction Loan Provider Consultants",
    value: "home-and-construction-loan-provider-consultants",
  },
  {
    id: 11,
    title: "Hotel/Resturants/Banquet",
    value: "hotel-resturants-banquet",
  },
  {
    id: 12,
    title: "Plumbing Contractors",
    value: "plumbing-contractors",
  },
  {
    id: 13,
    title: "VASTU Astrologer for Property",
    value: "vastu-astrologer-for-property",
  },
];
