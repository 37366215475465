import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const addNewBusinessService = (data) =>
  imageUpload.post("/business", data);

export const getBusinesssesService = (data) =>
  privateReq.get("/businesses" + data);
export const getBusinessDetailService = (id) =>
  privateReq.get("/business/" + id);
// export const searchBusiness = (str) => privateReq.get("/posts/search" + str);
export const addRatingService = (data) => privateReq.post("/post/rating", data);
export const editPost = (data) => privateReq.post("/update-post", data);
export const filterBusinessService = (data) =>
  privateReq.get("/business/filter" + data);
export const markAsBooked = (data) =>
  privateReq.post("/post/mark-booked/", data);
//ADmin
export const getAllBusinesses = (data) =>
  privateReq.get("/find-all-business" + data);

export const apiArroveBusiness = (data) =>
  privateReq.post("/business/approve-business", data);

export const apiDeleteBusiness = (id) => privateReq.delete("/business/" + id);

export const apiShowInterest = (data) =>
  privateReq.post("/business/interest", data);
export const getMyBusinessService = (data) =>
  privateReq.get("/my-businesses" + data);
