import React from "react";

import Footer_v1 from '../../new-components/global-components/footer';
import Navbar from '../../new-components/global-components/navbar';
const Disclaimer = () => {

    return (
        <>
            <Navbar />
            <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
                <div className="container" style={{ paddingTop: '50px' }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 align-self-center">
                            <div className="about-us-img-wrap about-img-left">
                                <h1 className='text-center pb-10 pt-50'>Disclaimer</h1>

                            </div>
                            <div className="ltn__section-title-2--- mb-20 pt-20">

                                <p className="p-disclaimer">
                                    Welcome to the "near2property.com" website ("Website"). This Disclaimer page outlines the terms and conditions governing your use of our platform. You accept these terms by using or accessing the Website. If you do not agree with any part of this Disclaimer, we kindly request that you refrain from using the Website.</p>

                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>General Information:

                                    <p className="p-disclaimer">
                                        The "near2property.com" website serves as a real estate listing platform that facilitates the rental, sale, and purchase of various types of properties, including residential, commercial, and industrial properties, in your region. Our services connect potential buyers, sellers, developers, builders, dealers, and end-users in the real estate market. However, it is essential to understand that "near2property.com" does not directly engage in real estate transactions. We solely act as an intermediary platform for information exchange.

                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>Property Listings:


                                    <p className="p-disclaimer">

                                        The property listings featured on "near2property.com" are provided by various third-party sources, including developers, builders, dealers, and end-users. We strive to present accurate and up-to-date information, but we cannot guarantee the authenticity, completeness, or reliability of the listings. Users are advised to verify the details of properties independently and exercise their due diligence before making any financial or legal decisions.
                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>Accuracy of Information:


                                    <p className="p-disclaimer">

                                        While we make reasonable efforts to ensure the accuracy of the content on our platform, we cannot warrant or guarantee the correctness of the information provided by third parties. Prices, availability, property details, and other relevant data may change without notice. "near2property.com" shall not be liable for any loss or inconvenience arising from reliance on the information displayed on the Website.

                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>4. Property Transactions:


                                    <p className="p-disclaimer">

                                        The Website serves as a medium for property listings and advertisements, and any transactions made through it are solely between the involved parties (e.g., buyers and sellers). "near2property.com" is not a party to these transactions and shall not be held responsible for any disputes, misunderstandings, financial losses, or damages arising from such transactions.
                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>5. Third-Party Websites:


                                    <p className="p-disclaimer">

                                        Our platform may contain links or references to third-party websites, services, or advertisements. These external links are provided for your convenience, but "near2property.com" does not endorse or guarantee the content, accuracy, or safety of these third-party websites. Users are advised to review the terms and policies of any linked websites before using their services.

                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>Indemnity:


                                    <p className="p-disclaimer">

                                        By using "near2property.com," you agree to indemnify and hold harmless the Website, its employees, agents, and affiliates from any claims, liabilities, losses, or expenses (including legal fees) arising out of the platform's use or any violations of this Disclaimer.


                                    </p>
                                </h6>
                            </div>
                            <div className=" ltn__section-title-2--- mb-20">
                                <h6>Changes to Disclaimer:



                                    <p className="p-disclaimer">

                                        "near2property.com" reserves the right to modify or update this Disclaimer at any time without prior notice. Any modifications will take effect as soon as they are posted on the website. Users are responsible for regularly reviewing this page to stay informed about the latest terms and conditions.

                                        By using "near2property.com," you acknowledge that you have read and understood this Disclaimer and agree to its terms. If you do not agree with any part of this Disclaimer, we recommend discontinuing your use of the Website.

                                        For any questions or concerns about this Disclaimer, please contact us at near2property@gmail.com .

                                    </p>
                                </h6>
                            </div>
                            <div>
                                <h5>
                                    Last updated: 06-08-2023</h5>
                                <br />
                                <h5> Thank you for choosing "near2property.com" for your real estate needs. Happy searching and investing!</h5>


                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <Footer_v1 />
        </>
    )
}

export default Disclaimer;