import {
  SET_MY_BUSINESS,
  SET_ALL_BUSINESS,
  SET_AD_DETAILS,
  SET_PROPERTIES,
} from "./types";
import {
  getBusinessDetailService,
  getAllBusinesses,
  getBusinesssesService,
  filterBusinessService,
  getMyBusinessService,
} from "../../config/business";

export const getMyBusiness = (data, cb) => async (dispatch) => {
  try {
    const res = await getMyBusinessService(data);
    dispatch({
      type: SET_MY_BUSINESS,
      payload: res.data,
    });
    cb(res.data);
  } catch (e) {
    cb(e);
    console.log(e);
  }
};

export const getBusiness = (data) => async (dispatch) => {
  try {
    const res = await getBusinesssesService(data);
    dispatch({
      type: SET_PROPERTIES,
      payload: res.data[0],
    });
    // cb(res.data)
  } catch (e) {
    // cb(e)
    console.log(e);
  }
};
export const getFilteredBusiness = (data, cb) => async (dispatch) => {
  try {
    const res = await filterBusinessService(data);

    dispatch({
      type: SET_PROPERTIES,
      payload: res.data[0],
    });
    cb(res.data);
  } catch (e) {
    cb(e);
    console.log(e);
  }
};
export const getBusinessDetails = (data) => async (dispatch) => {
  try {
    const res = await getBusinessDetailService(data);

    dispatch({
      type: SET_AD_DETAILS,
      payload: res.data,
    });
    // cb(res.data.post)
  } catch (e) {
    // cb(e)
    console.tron.log(e);
  }
};

export const onGetAllBusiness = (data, cb) => async (dispatch) => {
  try {
    const res = await getAllBusinesses(data);

    dispatch({
      type: SET_ALL_BUSINESS,
      payload: { businesses: res.data.businesses, total: res.data.total },
    });
  } catch (e) {
    console.log(e);
  }
};
