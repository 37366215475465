import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__contact-address-area mb-90">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "new-assets/img/icons/10.png"} alt="Icon Image" />
							</div>
							<h3>Email Address</h3>
							<p><a href="mailto:near2property@gmail.com">near2property@gmail.com</a><br /></p>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "new-assets/img/icons/11.png"} alt="Icon Image" />
							</div>
							<h3>Phone Number</h3>
							<p><a href="tel:+919888756062">+91-98887-56062</a><br /> </p>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
							<div className="ltn__contact-address-icon">
								<img src={publicUrl + "new-assets/img/icons/12.png"} alt="Icon Image" />
							</div>
							<h3>Office Address</h3>
							<a href='https://www.google.com/maps/place/VRITIKA+HOMES/@30.7640274,76.6547399,18z/data=!4m6!3m5!1s0x390ff1c4f63018d3:0x1da69b29c125a637!8m2!3d30.7641334!4d76.6555778!16s%2Fg%2F11srrly1z3?entry=ttu' target='_blank' style={{ cursor: 'pointer' }}>
								<p>Sec-124, Mohali <br /> </p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}

export default ContactInfo